import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import { Link, useNavigate } from "react-router-dom";
// import { inputTypes } from "../../Constant";
import { options } from "../../Constant";
import Swal from 'sweetalert2';

import qs from "qs";

import {
  fetchtableList,
  handlePositionInputChange,
  handleCheckboxChange,
  fetchGroupList,
  filterPositionFromGroup,
  crudFieldCheckbox,
  handleDelete,
  handleCheckboxesDynamicData,
  handleChainedDynamicData,
  handleDeleteValidation,
  fetchCrudTableList,
  handleGroupChange,
  fetchGroupIdByChildId,
  validationInputFieldValueCheck,
  validateInputFieldValue,
  validateInputFieldValueForCrud,
  checkCrudGroupError,

} from "../../utitls/crud/CrudFunctions"; // Adjust the path if needed

import {
  checkLabelAndStatus,
  crudFieldVisible,
  handleAddMore,
  handleCustomCheckboxChange,
  handleCustomOptionChange,
  handleCustomSelectChange,
  handleCustomSelectMultipleChange,
  handleRangeChange,
  handleRangeChangeForCrud,
  insertOption,
  insertPatternInCrudField,
} from "../../utitls/crudField/crudFieldFunction";

import {
  fetchCrudTableData,
  fetchFieldsForDynamicSelect,
  fetchSidebarData,
  fetchTableFields,
  fetchTableFieldsOnly,
  submitCrud,
} from "../../customhook/FetchApi";

import BreadCrumb from "./BreadCrumb";

import useStore from '../../zustand/ReactStore'


const CrudForm = () => {
  const [selectedData, setSelectedData] = useState([]);

  const [tableName, setTableName] = useState([]);
  const [selectedTableName, setSelectedTableName] = useState("");
  const [tableFields, setTableFields] = useState();
  const [positionData, setPositionData] = useState([])
  const [validationInputError, setValidationInputError] = useState([])

  const [isTableVisible, setIsTableVisible] = useState(false); // New state for table visibility

  const [selectedValues, setSelectedValues] = useState({});
  const [crudFieldData, setCrudFieldData] = useState([]);

  const [selectedValidationOption, setSelectedValidationOption] = useState("");
  const [validationOptions, setValidationOptions] = useState({});
  const [isGroupsLoading, setIsGroupsLoading] = useState(true); // State to manage loading indicator for groups
  const [selectCrudFieldPosition, setSelectCrudFieldPosition] = useState({});
  const [groups, setGroups] = useState([]);
  const [selectedInputType, setSelectedInputType] = useState("");
  const [tableNameForFields, setTableNameForFields] = useState("");
  const [tableNameAndFields, setTableNameAndFields] = useState({})


  const [tableFieldForCheckbox, setTableFieldForCheckbox] = useState([]);
  const [tableFieldForChained, setTableFieldForChained] = useState([]);
  const [crudTableName, setCrudTableName] = useState([]);
  const [selectedSuperParent, setSelectedSuperParent] = useState("");


  const updateSidebarDatafromZustand = useStore((state) => state.updateSidebarDatafromZustand);

  const updateCrudfromZustand = useStore((state) => state.updateCrudfromZustand)



  const [crudTableData, setCrudTableData] = useState({
    table_name: "",
    title: "",
    group_id: "",
    position: "",
    add_field: 0,
    edit_field: 0,
    delete_field: 0,
    status: "",
    slug: "",
    is_public: ""
  });

  const navigate = useNavigate()


  // Fetch groups when component mounts
  useEffect(() => {
    const fetchGroupData = async () => {
      setIsGroupsLoading(true);
      await fetchGroupList(setGroups);
      setIsGroupsLoading(false);
    };

    fetchGroupData();
  }, []); // Only fetch groups once when component mounts



  useEffect(() => {
    if (crudTableData.parent && crudTableData.position) {
      filterPositionFromGroup(setSelectedData, crudTableData);
    }
  }, [crudTableData]);

  const handleCrudTable = (e) => {
    const { name, value } = e.target;
    setCrudTableData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    if (name === "table_name") {
      setSelectedTableName(value);
    }
  };

  // fetch crudTable field
  useEffect(() => {
    if (!selectedTableName) return;

    const fetchData = async () => {
      const { crudFieldData, tableFields, isTableVisible } =
        await fetchTableFields(selectedTableName);

      setCrudFieldData(crudFieldData);
      setTableFields(tableFields);
      setIsTableVisible(isTableVisible);
    };

    fetchData();
  }, [selectedTableName]);


  useEffect(() => {


  }, [tableFields])


  const [tableNameForDynamicSelect, setTableNameForDynamicSelect] = useState("");
  const [dynamicSelectFields, setDynamicSelectFields] = useState([]);

  // fetchFieldsForDynamicSelect
  useEffect(() => {
    if (!tableNameForDynamicSelect) return;
    const fetchData = async () => {
      const { tableFields } = await fetchFieldsForDynamicSelect(
        tableNameForDynamicSelect
      );

      setDynamicSelectFields(tableFields);
      // setTableFields(tableFields);
    };

    fetchData();
  }, [tableNameForDynamicSelect]);


  //fetch table name for crud
  useEffect(() => {
    fetchCrudTableList(setCrudTableName);
  }, []);


  //fetch table name for dynamic table
  useEffect(() => {
    fetchtableList(setTableName);
  }, []);



  const isOptionDisabled = (value) => {
    return Object.values(selectCrudFieldPosition).includes(value);
  };


  const [labelError, setLabelError] = useState([])


  // submit crud and crudField and crud table
  const handleCrudSubmit = async (e) => {
    e.preventDefault();

    if (!crudTableData.title.trim()) {
      Swal.fire({
        icon: "warning",
        title: "Validation Error",
        text: "Title is required!",
      });
      return; // Stop further execution
    }


    const crudGroupError = checkCrudGroupError(crudTableData)

    if (crudGroupError) {
      Swal.fire({
        icon: "warning",
        title: "Group not Selected",
        text: "Group is required!",
      });
      return;
    }

    // Iterate over each field and check conditions for empty label and active status also atleast one status or visible should checked
    const { labelErrorData, statusError, visibilityError } = checkLabelAndStatus(crudFieldData);

    // If there are label errors, show them and prevent submission
    if (labelErrorData.length !== 0) {

      setLabelError(labelErrorData); // Update state to show errors

      Swal.fire({
        icon: "warning",
        title: "Label Error",
        text: "Label is required!",
      });

      return; // Stop further execution if there are label errors
    }


    if (statusError != "") {
      Swal.fire({
        icon: "warning",
        title: "Status Error",
        text: statusError
      });

      return; // Stop further execution if there are label errors
    }

    if (visibilityError != "") {

      Swal.fire({
        icon: "warning",
        title: "Status Error",
        text: visibilityError
      });

      return; // Stop further execution if atleast visibility not checked
    }


    // validation input field empty or not checking
    const validationInputBoxError = validateInputFieldValueForCrud(crudFieldData, selectedValues);
    // if got error set in state and return to not submit form
    if (Array.isArray(validationInputBoxError) && validationInputBoxError != 0) {

      setValidationInputError(validationInputBoxError)
      Swal.fire({
        icon: "warning",
        title: "Validation Input Field Error",
        text: "Enter value in Validation Input Box"
      })

      return;
    } else {
      setValidationInputError([])

    }


    const newObj = {
      ...crudTableData,
      key: "12321",
    };


    try {
      const response = await fetch(
        "https://admissioninnepal.in/api/add-edit-crud",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: qs.stringify(newObj),
        }
      );

      if (response.ok) {
        let data = await response.json();
        data = data.data;

        const crudId = data.id;
        setCrudFieldData(crudId);

        // update crud_id and position = maxLenth whose position number not selected
        const updatedCrudFieldData = crudFieldData.map((crudField) => ({
          ...crudField,
          crud_id: crudId,
          position: (crudField.position == 0 || crudField.position == "") ? tableFields.length : crudField.position
        }));

        const crudFieldWithPattern = insertPatternInCrudField(
          updatedCrudFieldData,
          selectedValues,

        );

        setCrudFieldData(crudFieldWithPattern);

        try {
          const response1 = await fetch(
            "https://admissioninnepal.in/api/add-edit-crudfield",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
              },
              body: JSON.stringify(crudFieldWithPattern),
            }
          );

          if (response1.ok) {
            // fetch updated sidebardata and update in zustand store
            const sidebardata = await fetchSidebarData();
            await updateSidebarDatafromZustand(sidebardata)


            // update crud table data in zustand store

            const crudtable = await fetchCrudTableData();
            await updateCrudfromZustand(crudtable)

            // update crud tabld data 

            Swal.fire({
              icon: 'success',
              title: 'Success!',
              text: `Api -- http://localhost:3000/global-form/${data.slug}`,
            }).then(() => {
              navigate(`/edit-crud/${crudId}`);
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Failed to submit data!',
            });
          }
        } catch (error) {
          console.error(error);
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'An error occurred while submitting the data!',
          });
        }
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Failed to add/edit data!',
        });
      }
    } catch (error) {
      console.error(error);
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'An error occurred during the request!',
      });
    }
  };

  useEffect(() => {
    console.log("SelectedValues")
    console.log(selectedValues)
  }, [selectedValues])





  // colSize selection
  const handleCrudFieldColSizeChange = (e, fieldId) => {
    const value = e.target.value;
    setCrudFieldData((prevData) =>
      prevData.map((field) =>
        field.id === fieldId ? { ...field, col_size: value } : field
      )
    );
  };

  const handleCrudFieldPosition = (e, id) => {
    const value = e.target.value;

    setCrudFieldData((prevData) =>
      prevData.map((field) =>
        field.id === id ? { ...field, position: value } : field
      )
    );
  };

  useEffect(() => {


  }, [crudFieldData]);

  const handleValidationChange = (e, fieldId) => {
    const selectedOption = e.target.value;
    // Update selected validation option state
    setSelectedValidationOption((prev) => ({
      ...prev,
      [fieldId]: selectedOption,
    }));

    // Set selected values for validation
    setSelectedValues((prevValues) => ({
      ...prevValues,
      [fieldId]: {
        ...prevValues[fieldId],
        [selectedOption]: prevValues[fieldId]?.[selectedOption] || "",
      },
    }));
  };






  const handleValueInputChange = (e, fieldId, key) => {
    const value = e.target.value;

    setSelectedValues((prevValues) => ({
      ...prevValues, // Spread the entire previous state
      [fieldId]: {
        // Update or insert a new object for the specified fieldId
        ...prevValues[fieldId], // Spread the existing object for this fieldId to preserve other properties
        [key]: value, // Update the specific field (key) with the new value
      },
    }));
  };

  const handleInputCrudChange = (id, e) => {

    const { value } = e.target;
    setCrudFieldData((prevData) =>
      prevData.map((field) =>
        field.id == id ? { ...field, field_label: value } : field
      )
    );
  };

  const [selectedFieldId, setSelectedFieldId] = useState([]);
  const [customOptions, setCustomOptions] = useState({});

  const [customCheckbox, setCustomCheckbox] = useState({});
  const [customSelect, setCustomSelect] = useState({})
  const [customSelectMultiple, setCustomSelectMultiple] = useState({})



  const handleCrudFieldInputChange = (e, fieldId) => {
    const selectedType = e.target.value;

    //set setected input type
    setSelectedInputType(selectedType);

    setSelectedFieldId(fieldId); // Set selected field ID

    // Update the corresponding field in crudFieldData
    // setCrudFieldData((prevData) =>
    //   prevData.map((field) =>
    //     field.id === fieldId ? { ...field, field_type: selectedType } : field
    //   )
    // );


    setCrudFieldData((prevData) =>
      prevData.map((field) => {
        if (field.id === fieldId) {
          // Create a new object with the specified fields preserved
          return {
            ...field,
            field_type: selectedType,  // Keep field_type as it is

            ...Object.keys(field).reduce((acc, key) => {
              // Set all values to empty except for the allowed fields
              if (['crud_id', 'id', 'field_name', 'is_visible', 'status', 'field_label', 'position', 'col_size'].includes(key)) {
                acc[key] = field[key];  // Keep original value
              }
              else if (key == 'field_type') {
                acc[key] = selectedType
              }
              else {
                acc[key] = '';  // Set to empty
              }
              return acc;
            }, {})
          };
        }
        return field;
      })
    )


    setSelectedValues((prevData) => {

      const newData = { ...prevData }
      if (newData.hasOwnProperty(fieldId)) {
        delete newData[fieldId]
      }
      return newData
      // prevData.hasOwnProperty(fieldId)? delte that object :
    })

    // Update validation options for the selected input type
    setValidationOptions((prev) => ({
      ...prev,
      [fieldId]: options[selectedType] || [],
    }));

    // Reset selected validation option for the changed field
    setSelectedValidationOption((prev) => ({
      ...prev,
      [fieldId]: "",
    }));
  };



  // fetch table field for checkbox and select


  const FetchTableFields = async () => {
    const tableFields = await fetchTableFieldsOnly(tableNameForFields);


    // Check if tableFields.data exists and is an array
    if (tableFields.data && Array.isArray(tableFields.data)) {
      const obj = {
        [tableNameForFields]: tableFields.data
      };

      // Append the new tableNameForFields and its data to the existing state
      setTableNameAndFields({ ...tableNameAndFields, ...obj });

      setTableFieldForCheckbox(tableFields.data);
      setTableFieldForChained(tableFields.data);
    } else {
      console.error("Invalid data structure:", tableFields);
      setTableFieldForCheckbox([]); // Set to an empty array on failure
      setTableFieldForChained([]);
    }
  };

  useEffect(() => {
    // Check if tableNameForFields is valid and if it's already a key in tableNameAndFields
    if (!tableNameForFields || tableNameAndFields.hasOwnProperty(tableNameForFields)) return;

    // Fetch table fields if tableNameForFields is not in tableNameAndFields
    FetchTableFields();

  }, [tableNameForFields]);

  useEffect(() => {
    console.log("crudFieldData")
    console.log(crudFieldData)
  }, [crudFieldData])



  return (
    <>
      <Header />
      <Sidebar />
      <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
        <div className="d-flex flex-column flex-column-fluid">
          {/* breacrumb buttons code */}
          <BreadCrumb />

          <div id="kt_app_content" className="app-content flex-column-fluid">
            <div
              id="kt_app_content_container"
              className="app-container container-xxl"
            >
              <div className="card">
                <div className="card-body p-0">
                  <div className="card-px my-10" id="c">
                    <div className="d-flex flex-column flex-lg-row mb-17">
                      <div className="flex-lg-row-fluid me-0 me-lg-20">


                        {/* Header code below */}
                        <form className="form mb-5">
                          <div className="form-group">
                            <label htmlFor="table_name">Table Name</label>
                            <select
                              id="table_name"
                              name="table_name"
                              value={crudTableData.table_name}
                              onChange={handleCrudTable}
                              className="form-select mt-3"
                            >
                              <option value="">Select Table</option>

                              {crudTableName.map((name) => {
                                const displayName = name
                                  .replace(/^tbl_/, "") // Remove the "tbl_" prefix
                                  .replace(/_/g, " ") // Replace underscores with spaces
                                  .replace(/\b\w/g, (char) =>
                                    char.toUpperCase()
                                  ); // Capitalize first letter of each word
                                return (
                                  <option key={name} value={name}>
                                    {displayName}
                                  </option>
                                );
                              })}
                            </select>
                          </div>

                          <div className="form-group mt-5">
                            <label htmlFor="title">
                              Title{" "}
                              <span className="required-indicator">*</span>
                            </label>
                            <input
                              type="text"
                              required
                              name="title"
                              className="form-control mt-3"
                              placeholder="Title"
                              id="title"
                              value={crudTableData.title}
                              onChange={handleCrudTable}
                            />
                          </div>

                          <div className="form-group mb-3 mt-3">

                            <label>Group</label>

                            <select
                              name="group"
                              value={fetchGroupIdByChildId(
                                groups,
                                crudTableData.group_id,
                                selectedSuperParent
                              )}
                              // value={crudTableData.group_id  fetchGroupIdByChildId() }

                              onChange={(e) =>
                                handleGroupChange(
                                  e,
                                  setCrudTableData,
                                  groups,
                                  setPositionData,
                                  setSelectedSuperParent
                                )
                              }
                              className="form-select mt-3"
                            >
                              <option value="">Select Group</option>

                              {groups
                                .filter((group) => group.parent === "0")
                                .map((group) => (
                                  <option key={group.id} value={group.id}>
                                    {group.group_name}
                                  </option>
                                ))}
                            </select>
                          </div>

                          <div className="form-group mb-3">
                            <label>Position</label>
                            <select
                              name="position"
                              value={crudTableData.position}
                              onChange={(e) =>
                                handlePositionInputChange(e, setCrudTableData)
                              }
                              className="form-select mt-3"
                            >
                              <option value="">Select Position</option>

                              {positionData.map((data) => (
                                <option key={data.id} value={data.id}>
                                  {data.group_name}
                                </option>
                              ))}
                            </select>
                          </div>

                          {/*<div className="form-group mb-3">
<label>Group</label>

<select
name="group"
value={crudTableData.group_id}
onChange={(e) =>
handlePositionInputChange(e, setCrudTableData, groups, setPositionData)
}
className="form-select mt-3"
>
<option value="">Select Group</option>
{isGroupsLoading ? (
<option value="">Loading groups...</option>
) : (
// Map groups and render options for those with parent == 0
groups
.filter((group) => group.parent === "0") // Ensure the correct comparison
.map((group) => (
<option key={group.id} value={group.id}>
{group.group_name}
</option>
))
)}


</select>
</div>

<div className="form-group mb-3">
<label>Position</label>

<select
name="position"
value={crudTableData.position}
onChange={(e) =>
handlePositionInputChange(e, setCrudTableData, groups, setPositionData)
}
className="form-select mt-3"
>
<option value="">Select Position</option>
{positionData.map((data) => (
<option key={data.id} value={data.id}>
{data.group_name}
</option>
))
}
</select>
</div> */}

                          <div className="mt-5">
                            <h4>Action</h4>
                            <div className="d-flex justify-content-between  mt-5">
                              <div className="form-check">
                                <div className="checkbox checkbox-success">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    checked={!!crudTableData.add_field} // Convert integer to boolean
                                    id="add_field"
                                    onChange={(e) =>
                                      handleCheckboxChange(e, setCrudTableData)
                                    }
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="add_field"
                                  >
                                    Add
                                  </label>
                                </div>
                              </div>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={!!crudTableData.edit_field} // Convert integer to boolean
                                  id="edit_field"
                                  onChange={(e) =>
                                    handleCheckboxChange(e, setCrudTableData)
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="edit_field"
                                >
                                  Edit
                                </label>
                              </div>

                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={!!crudTableData.delete_field} // Convert integer to boolean
                                  id="delete_field"
                                  onChange={(e) =>
                                    handleCheckboxChange(e, setCrudTableData)
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="delete_field"
                                >
                                  Delete
                                </label>
                              </div>

                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={!!crudTableData.is_public} // Convert integer to boolean
                                  id="is_public"
                                  onChange={(e) =>
                                    handleCheckboxChange(e, setCrudTableData)
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="is_public"
                                >
                                  Public
                                </label>
                              </div>
                            </div>
                          </div>
                        </form>


                        {isTableVisible && (
                          <div className="table-responsive">
                            <table
                              className="table table-rounded table-row-bordered border gy-1 gs-1 mt-5"
                              id="ta"
                            >
                              <thead>
                                <tr
                                  className="fw-bold fs-6 text-gray-800 border-bottom border-gray-200"
                                  id="thead"
                                >
                                  <th>Field</th>
                                  <th>Label</th>
                                  <th>Position</th>
                                  <th>Status</th>
                                  <th>Visible</th>

                                  <th>Input Type</th>
                                  <th>Validation</th>
                                  <th>Col Size</th>
                                </tr>
                              </thead>

                              {/* taking input in crudFeild */}

                              <tbody>
                                {crudFieldData.map((crudField, index) => (
                                  <tr key={index}>
                                    <td className="fw-bold">
                                      {crudField.field_name}
                                    </td>


                                    <td>
                                      <div className="form-group">
                                        <input
                                          type="text"
                                          className="form-control w-80"
                                          value={crudField.field_label || ""}
                                          onChange={(e) =>
                                            handleInputCrudChange(
                                              crudField.id,
                                              e
                                            )
                                          }
                                          style={{
                                            borderColor:
                                              (labelError.includes(crudField.id) || (crudField.status == 1 && crudField.field_label == ""))
                                                ? "red"
                                                : "",
                                          }}
                                        />

                                        {(labelError.includes(crudField.id) || (crudField.status == 1 && crudField.field_label == "")) && (
                                          <div className="text-danger">
                                            Field is required
                                          </div>
                                        )}
                                      </div>
                                    </td>


                                    {/* Position select box */}
                                    <td>
                                      <div className="dropdown">
                                        <select
                                          className="form-select"
                                          value={crudField.position}
                                          onChange={(e) =>
                                            handleCrudFieldPosition(
                                              e,
                                              crudField.id
                                            )
                                          }
                                        >
                                          <option value="">
                                            Select Position
                                          </option>
                                          {[
                                            ...Array(tableFields.length).keys(),
                                          ].map((num) => (
                                            <option
                                              key={num + 1}
                                              value={num + 1}
                                              disabled={isOptionDisabled(
                                                (num + 1).toString()
                                              )}
                                            >
                                              {num + 1}
                                            </option>
                                          ))}
                                        </select>
                                      </div>
                                    </td>

                                    {/* Status checkbox code */}
                                    <td>
                                      <div className="d-flex flex-column">
                                        <div>

                                          <input
                                            type="checkbox"
                                            id={`status-${crudField.id}`}
                                            className="form-check-input font-weight-bold"
                                            onChange={(e) =>
                                              crudFieldCheckbox(
                                                e,
                                                crudField.id,
                                                setCrudFieldData
                                              )
                                            }
                                          />
                                        </div>
                                      </div>
                                    </td>

                                    {/* visibility checkbox */}
                                    <td>
                                      <div className="d-flex flex-column">
                                        <div>

                                          <input
                                            type="checkbox"
                                            id={`visible-${crudField.id}`}
                                            onChange={(e) =>
                                              crudFieldVisible(
                                                e,
                                                crudField.id,
                                                setCrudFieldData
                                              )
                                            }
                                            className="form-check-input font-weight-bold"
                                          />
                                        </div>
                                      </div>
                                    </td>




                                    {/* Input type selection in select option */}
                                    <td>
                                      <div className="dropdown">
                                        <select

                                          value={crudField.field_type}
                                          className="form-select w-75"
                                          onChange={(e) =>
                                            handleCrudFieldInputChange(
                                              e,
                                              crudField.id
                                            )
                                          }
                                        >
                                          <option value="">
                                            Select Input
                                          </option>
                                          {Object.keys(options).map((type) => (
                                            <option key={type} value={type}>
                                              {type.charAt(0).toUpperCase() +
                                                type.slice(1)}
                                            </option>
                                          ))}
                                        </select>
                                      </div>

                                      {/* checkbox from table reference  */}
                                      <div>
                                        {
                                          (crudField.field_type === "dynamic_select" || crudField.field_type === "checkboxes" || crudField.field_type === "select_multiple" || crudField.field_type === 'chained') &&
                                          (
                                            <div>
                                              <div className="form-group">

                                                <select
                                                  id={`table_name_${crudField.id}`}
                                                  name="dynamic_table"
                                                  value={crudField.dynamic_table || ""}
                                                  onChange={(e) =>
                                                    handleCheckboxesDynamicData(
                                                      e,
                                                      crudField.id,
                                                      setCrudFieldData,
                                                      setTableNameForFields
                                                    )
                                                  }
                                                  className="form-select mt-3"
                                                >
                                                  <option value="">
                                                    Select Table
                                                  </option>
                                                  {tableName.map(
                                                    (name, index) => (
                                                      <option
                                                        key={index}
                                                        value={name}
                                                      >
                                                        {name
                                                          .replace(/_/g, " ")
                                                          .replace(
                                                            /\b\w/g,
                                                            (char) =>
                                                              char.toUpperCase()
                                                          )}
                                                      </option>
                                                    )
                                                  )}
                                                </select>
                                              </div>

                                              <div>

                                                <select
                                                  name="dynamic_label"

                                                  value={crudField.dynamic_label || ""}

                                                  onChange={(e) =>
                                                    handleCheckboxesDynamicData(
                                                      e,
                                                      crudField.id,
                                                      setCrudFieldData,
                                                      setTableNameForFields
                                                    )
                                                  }
                                                  className="form-select mt-3"
                                                >
                                                  <option value="">
                                                    Select Label Ref
                                                  </option>
                                                  {tableNameAndFields[crudField.dynamic_table] && tableNameAndFields[crudField.dynamic_table].map(
                                                    (tableField, index) => (
                                                      <option
                                                        key={index}
                                                        value={
                                                          tableField.COLUMN_NAME
                                                        }
                                                      >
                                                        {(tableField.COLUMN_NAME).replace("_", " ")}
                                                      </option>
                                                    )
                                                  )}
                                                </select>
                                              </div>


                                              <div>

                                                <select
                                                  name="dynamic_field"
                                                  value={crudField.dynamic_field || ""}

                                                  onChange={(e) =>
                                                    handleCheckboxesDynamicData(
                                                      e,
                                                      crudField.id,
                                                      setCrudFieldData,
                                                      setTableNameForFields
                                                    )
                                                  }
                                                  className="form-select mt-3"
                                                >
                                                  <option value="">
                                                    Select value Ref
                                                  </option>
                                                  {/* {  } */}

                                                  {tableNameAndFields[crudField.dynamic_table] && tableNameAndFields[crudField.dynamic_table].map(
                                                    (tableField, index) => (
                                                      <option
                                                        key={index}
                                                        value={
                                                          tableField.COLUMN_NAME
                                                        }
                                                      >
                                                        {(tableField.COLUMN_NAME).replace("_", " ")}
                                                      </option>
                                                    )
                                                  )}


                                                </select>
                                              </div>


                                              {/* for where clouse for chained and other */}
                                              {crudField.field_type === 'chained' && (

                                                <>
                                                  <label htmlFor="">Dependency Condition</label>

                                                  <div>

                                                    <select
                                                      id={`${crudField.id}`}
                                                      name="dependency"
                                                      value={
                                                        crudField["dependency"]
                                                      }
                                                      onChange={(e) =>
                                                        handleCheckboxesDynamicData(
                                                          e,
                                                          crudField.id,
                                                          setCrudFieldData,
                                                          setTableNameForFields
                                                        )
                                                      }
                                                      className="form-select mt-3"
                                                    >
                                                      <option value="">
                                                        Select Condition Column
                                                      </option>
                                                      {tableNameAndFields[crudField.dynamic_table] && tableNameAndFields[crudField.dynamic_table].map(
                                                        (tableField, index) => (
                                                          <option
                                                            key={index}
                                                            value={
                                                              tableField.COLUMN_NAME
                                                            }
                                                          >
                                                            {(tableField.COLUMN_NAME).replace("_", " ")}
                                                          </option>
                                                        )
                                                      )}
                                                    </select>
                                                  </div>
                                                  =
                                                  <div>
                                                    <select
                                                      name="dynamic_condition"
                                                      value={
                                                        crudField[
                                                        "dynamic_condition"
                                                        ]
                                                      }
                                                      onChange={(e) =>
                                                        handleCheckboxesDynamicData(
                                                          e,
                                                          crudField.id,
                                                          setCrudFieldData,
                                                          setTableNameForFields
                                                        )
                                                      }
                                                      className="form-select mt-3"
                                                    >
                                                      <option value="">
                                                        Select Condition Column
                                                      </option>
                                                      {crudFieldData.map((crudField, index) => (
                                                        <option
                                                          key={index}
                                                          value={
                                                            crudField.field_name
                                                          }
                                                        >
                                                          {
                                                            (crudField.field_name).replace("_", " ")
                                                          }
                                                        </option>
                                                      )
                                                      )}

                                                    </select>
                                                  </div>

                                                </>
                                              )}
                                            </div>
                                          )}

                                      </div>



                                      {/* range input type */}
                                      <div>
                                        {selectedInputType && selectedInputType === "range" && selectedFieldId === crudField.id && (
                                          <>
                                            <div className="d-flex align-items-center gap-2 mb-2">
                                              <label htmlFor={`min-${crudField.id}`}>Min</label>
                                              <input
                                                type="number"
                                                className="form-control"
                                                // value={selectedValues.find(data => data.id === crudField.id)?.pattern?.min || ""}

                                                value={
                                                  selectedValues[crudField.id]?.min || "" // Check if selectedValues has the id and pattern object
                                                }
                                                // check selected value is empty object or not then apply find method
                                                // Crud.jsx:1227 Uncaught TypeError: selectedValues.find is not a function

                                                // value={crudFieldData.find(data => data.id === crudField.id)?.pattern?.min || ""}
                                                onChange={(e) =>
                                                  handleRangeChangeForCrud(
                                                    crudField.id,
                                                    "min",
                                                    e,
                                                    setCrudFieldData,
                                                    setSelectedValues
                                                  )
                                                }
                                                id={`min-${crudField.id}`}
                                              />
                                            </div>

                                            <div className="d-flex align-items-center gap-2 mb-2">
                                              <label htmlFor={`max-${crudField.id}`}>Max</label>
                                              <input
                                                type="number"
                                                className="form-control"
                                                // value={selectedValues.find(data => data.id === crudField.id)?.pattern?.max || ""}
                                                value={
                                                  selectedValues[crudField.id]?.max || "" // Check if selectedValues has the id and pattern object
                                                }
                                                // value={crudFieldData.find(data => data.id === crudField.id)?.pattern?.max || ""}
                                                onChange={(e) =>
                                                  handleRangeChangeForCrud(
                                                    crudField.id,
                                                    "max",
                                                    e,
                                                    setCrudFieldData,
                                                    setSelectedValues
                                                  )
                                                }
                                                id={`max-${crudField.id}`}
                                              />
                                            </div>
                                          </>
                                        )}
                                      </div>


                                      {/* range input type with double pointer slider */}
                                      <div>
                                        {selectedInputType && selectedInputType === "range_double_slider" && selectedFieldId === crudField.id && (
                                          <>
                                            <div className="d-flex align-items-center gap-2 mb-2">
                                              <label htmlFor={`min-${crudField.id}`}>Min</label>
                                              <input
                                                type="number"
                                                className="form-control"
                                                value={selectedValues.find(data => data.id === crudField.id)?.pattern?.min || ""}
                                                onChange={(e) =>
                                                  handleRangeChangeForCrud(
                                                    crudField.id,
                                                    "min",
                                                    e,
                                                    setCrudFieldData,
                                                    setSelectedValues
                                                  )
                                                }
                                                id={`min-${crudField.id}`}
                                              />
                                            </div>

                                            <div className="d-flex align-items-center gap-2 mb-2">
                                              <label htmlFor={`max-${crudField.id}`}>Max</label>
                                              <input
                                                type="number"
                                                className="form-control"
                                                value={selectedValues.find(data => data.id === crudField.id)?.pattern?.max || ""}

                                                // value={crudFieldData.find(data => data.id === crudField.id)?.pattern?.max || ""}
                                                onChange={(e) =>
                                                  handleRangeChangeForCrud(
                                                    crudField.id,
                                                    "max",
                                                    e,
                                                    setCrudFieldData,
                                                    setSelectedValues
                                                  )
                                                }
                                                id={`max-${crudField.id}`}
                                              />
                                            </div>
                                          </>
                                        )}
                                      </div>

                                      {/* for custom checkbox and custom_option  and custom select*/}
                                      <div>

                                        {(crudField.field_type == "custom_option" || crudField.field_type == "custom_checkbox" || crudField.field_type === "custom_select_multiple" || crudField.field_type === "custom_select") && (
                                          <div className="custom-option-container">
                                            {(
                                              typeof crudField.options === "string" && crudField.options.trim() !== ""
                                                ? JSON.parse(crudField.options)
                                                : Array.isArray(crudField.options)
                                                  ? crudField.options
                                                  : []
                                            ).map((option, optionIndex) => (
                                              <div className="custom-option" key={optionIndex}>
                                                <div className="d-flex align-items-center gap-2 mb-2">


                                                  <label htmlFor={`label-${crudField.id}-${optionIndex}`}>
                                                    Label
                                                  </label>

                                                  <input
                                                    type="text"
                                                    className="form-control"
                                                    value={option.label}
                                                    onChange={(e) => {
                                                      handleCustomOptionChange(
                                                        crudField.id,
                                                        optionIndex,
                                                        "label",
                                                        e,
                                                        setCrudFieldData,
                                                        crudField
                                                      );
                                                    }}


                                                    id={`label-${crudField.id}-${optionIndex}`}
                                                  />
                                                </div>

                                                <div className="d-flex align-items-center gap-2 mb-2">
                                                  <label htmlFor={`value-${crudField.id}-${optionIndex}`}>
                                                    Value
                                                  </label>
                                                  <input
                                                    type="text"
                                                    className="form-control"
                                                    value={option.value}

                                                    onChange={(e) => {
                                                      handleCustomOptionChange(
                                                        crudField.id,
                                                        optionIndex,
                                                        "value",
                                                        e,
                                                        setCrudFieldData,
                                                        crudField
                                                      );
                                                    }}
                                                    id={`value-${crudField.id}-${optionIndex}`}
                                                  />
                                                </div>


                                                <div className="d-flex align-items-center gap-2 mb-2">
                                                  <label htmlFor={`default-${crudField.id}-${optionIndex}`}>
                                                    Default Option
                                                  </label>
                                                  <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    checked={option.defaultValue}

                                                    onChange={(e) => {
                                                      handleCustomOptionChange(
                                                        crudField.id,
                                                        optionIndex,
                                                        "defaultValue",
                                                        e,
                                                        setCrudFieldData,
                                                        crudField
                                                      );
                                                    }}


                                                    id={`default-${crudField.id}-${optionIndex}`}
                                                  />
                                                </div>

                                                {/* {crudField.field_type === "custom_option" && ( */}
                                                <button
                                                  className="btn btn-danger mt-2 px-3 py-2"
                                                  onClick={() =>
                                                    handleDelete(
                                                      crudField.id,
                                                      optionIndex,

                                                      setCrudFieldData
                                                    )

                                                    // fieldId, optionIndex, setCustomOptions, setCustomCheckbox, selectedInputType
                                                  }
                                                >
                                                  Delete
                                                </button>


                                                {/* )} */}

                                              </div>
                                            ))}

                                            {selectedFieldId === crudField.id && (crudField.field_type === "custom_option" || crudField.field_type === "custom_checkbox"
                                              || crudField.field_type === "custom_select" || crudField.field_type === "custom_select_multiple") && (
                                                <button
                                                  className="btn btn-primary mt-2 px-3 py-2"
                                                  onClick={() =>
                                                    handleAddMore(
                                                      crudField.id,

                                                      setCrudFieldData,
                                                      crudField
                                                    )
                                                  }
                                                >
                                                  Add more
                                                </button>
                                              )}
                                          </div>
                                        )}
                                      </div>

                                    </td>


                                    <td>
                                      <select
                                        value={
                                          selectedValidationOption[
                                          crudField.id
                                          ] || ""
                                        }
                                        onChange={(e) =>
                                          handleValidationChange(
                                            e,
                                            crudField.id
                                          )
                                        }
                                        className="form-select w-80"
                                      >
                                        <option value="">
                                          Select Validation
                                        </option>
                                        {validationOptions[crudField.id]?.map(
                                          (option) => (
                                            <option
                                              key={option.value}
                                              value={option.value}
                                            >
                                              {option.label}
                                            </option>
                                          )
                                        )}
                                      </select>

                                      {/* Display Selected Validation with Input Tag in Dropdown */}
                                      {/* ValidationInputError */}
                                      {Object.entries(selectedValues[crudField.id] || {}).length > 0 && (
                                        <div>

                                          <ul className="list-unstyled">
                                            {Object.entries(selectedValues[crudField.id] || {}).map(([key, value]) => {
                                              console.log("key")
                                              console.log(key)

                                              const option = validationOptions[crudField.id]?.find((opt) => opt.value === key);

                                              return (
                                                <div>
                                                  <li
                                                    key={key}
                                                    className="d-flex align-items-center mb-3 mt-5"
                                                  >
                                                    <span className="labe">{option?.label}:</span>

                                                    {/* for password and conform password select tag should be there */}
                                                    {key === "matches" ? (
                                                      <select
                                                        value={value}
                                                        onChange={(e) =>
                                                          handleValueInputChange(
                                                            e,
                                                            crudField.id,
                                                            key
                                                          )
                                                        }
                                                        className="form-control me-3"
                                                      >

                                                        <option value="">
                                                          Select an option
                                                        </option>

                                                        {crudFieldData.map(
                                                          (crudField, index) => (
                                                            <option
                                                              key={index}
                                                              value={
                                                                crudField.field_name
                                                              }
                                                            >
                                                              {
                                                                crudField.field_name
                                                              }
                                                            </option>
                                                          )
                                                        )}
                                                      </select>
                                                    ) : (

                                                      option?.inputIsVisible && (
                                                        <input
                                                          type="text"
                                                          value={value}
                                                          onChange={(e) =>
                                                            handleValueInputChange(
                                                              e,
                                                              crudField.id,
                                                              key
                                                            )
                                                          }
                                                          className="form-control me-1 w-50"
                                                          placeholder="Enter value"

                                                          style={{
                                                            borderColor:
                                                              (validationInputError.includes(crudField.id))
                                                                ? "red"
                                                                : "",
                                                          }}

                                                        />
                                                      )
                                                    )}



                                                    {/* delete validation */}
                                                    <button
                                                      onClick={() =>
                                                        handleDeleteValidation(
                                                          crudField.id,
                                                          key,
                                                          setSelectedValues
                                                        )
                                                      }
                                                      className="btn btn-danger d-flex align-items-center justify-content-center"
                                                      id="butt"
                                                    >
                                                      <i className="bi bi-trash"></i>
                                                    </button>


                                                  </li>
                                                </div>
                                              );
                                            })}
                                          </ul>
                                          {/* ValidationInputError */}

                                          <div>

                                            {(validationInputError.includes(crudField.id)) && (
                                              <p className="text-danger">Input Field Required</p>
                                            )}
                                          </div>

                                        </div>
                                      )}
                                    </td>


                                    <td>
                                      <select
                                        value={crudField.col_size}
                                        onChange={(e) =>
                                          handleCrudFieldColSizeChange(
                                            e,
                                            crudField.id
                                          )
                                        }
                                        className="form-select"
                                      >
                                        <option value="">
                                          Column Size
                                        </option>
                                        <option value="col-lg-1 col-md-1 col-sm-3 col-6">
                                          col-1
                                        </option>
                                        <option value=" col-lg-2 col-md-2 col-sm-3 col-6">
                                          col-2
                                        </option>
                                        <option value=" col-lg-3 col-md-3 col-sm-4 col-6">
                                          col-3
                                        </option>
                                        <option value=" col-lg-4 col-md-4 col-sm-6 col-12">
                                          col-4
                                        </option>
                                        <option value="col-lg-5 col-md-5  col-sm-6 col-12">
                                          col-5
                                        </option>
                                        <option value="col-lg-6 col-md-6 col-sm-6 col-12">
                                          col-6
                                        </option>
                                        <option value="col-lg-7 col-md-7 col-sm-12 col-12">
                                          col-7
                                        </option>
                                        <option value="col-lg-8 col-md-8 col-sm-12 col-12">
                                          col-8
                                        </option>
                                        <option value="col-lg-9 col-md-9 col-sm-12 col-12">
                                          col-9
                                        </option>
                                        <option value="col-lg-10 col-md-10 col-sm-12 col-12">
                                          col-10
                                        </option>
                                        <option value="col-lg-11 col-md-11 col-sm-12 col-12">
                                          col-11
                                        </option>
                                        <option value="col-12">col-12</option>
                                      </select>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>


                          </div>
                        )}


                        <div className="mt-5">
                          <button
                            type="submit"
                            className="btn btn-success "
                            id="success"
                            onClick={handleCrudSubmit}
                          >
                            <span className="indicator-label">Add</span>
                            <span className="indicator-progress">
                              Please wait...{" "}
                              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                          </button>

                          {/* Reset Button */}
                          <button
                            type="button"
                            // className="btn btn-primary mt-3 ms-2"
                            className="btn btn-danger  ms-2 warning"
                            onClick={() => window.location.reload()}
                          >
                            Reset
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CrudForm;



import qs from 'qs'; // Ensure qs is imported here
import { options } from '../../Constant'
import g from 'file-saver';


export const checkCrudGroupError = (crudTableData)=>{

    console.log("crudTableData")
    console.log(crudTableData)

 
    if( typeof crudTableData == 'object' && Object.keys(crudTableData).length ==0){
        return 1;
    }
    return crudTableData.group_id == "" ? 1 :0
    
}

export const validateInputFieldValueForCrud = (crudFieldData, selectedValues) => {

    
    let validationArray = []; // Array to store validation rules
    let errors = []; // Array to store errors

    if (typeof selectedValues == 'object' && Object.entries(selectedValues).length !== 0) {

        Object.entries(selectedValues).forEach(([id, pattern]) => {

            let crudField = crudFieldData.filter((data) => data.id == id)

            crudField = crudField[0]


            const validations = options[crudField.field_type] || []; // Use default empty array if field type isn't found

            

            validations.forEach((validationLabelValue) => {
                if (validationLabelValue.inputIsVisible === 1) {
                    validationArray.push(validationLabelValue.value);
                }
            });

            Object.entries(pattern).forEach(([key, value]) => {
                // Check if validationArray contains the key and value is falsy (empty or invalid)
                

                
                if (validationArray.includes(key) && !value) {
                    errors.push(parseInt(id)); // Add the ID to errors if condition is met
                }
            });


        })
    }


    return errors; // Return the array of errors
};

export const validateInputFieldValueForEditCrud = (crudFieldData) => {

    
    

    let validationArray = []; // Array to store validation rules
    let errors = []; // Array to store errors

    crudFieldData.forEach((crudField) => {
        // Check if pattern is an object and is not empty
        if (typeof crudField.pattern === 'object' && crudField.pattern !== null && Object.keys(crudField.pattern).length !== 0) {
            const validations = options[crudField.field_type] || []; // Use default empty array if field type isn't found

            // Store values where inputIsVisible === 1
            validations.forEach((validationLabelValue) => {
                if (validationLabelValue.inputIsVisible === 1) {
                    validationArray.push(validationLabelValue.value);
                }
            });

            // Iterate over the key-value pairs in crudField.pattern
            Object.entries(crudField.pattern).forEach(([key, value]) => {
                // Check if validationArray contains the key and value is falsy (empty or invalid)
                if (validationArray.includes(key) && !value) {
                    errors.push(crudField.id); // Add the ID to errors if condition is met
                }
            });
        }
    });

    return errors; // Return the array of errors
};



// correct above code to get key value from crudField.pattern and check validationArray contains or not if if yes and then check its value is empty then store its id into error array


export function handleChainedDynamicData(e, fieldId, setCrudFieldData, setTableNameForDynamicSelect) {
    const { name, value } = e.target;

    //set selected table
    setTableNameForDynamicSelect(value)

    setCrudFieldData((prevData) =>
        prevData.map((field) =>
            field.id === fieldId
                ? { ...field, [name]: value } // Update the specific field based on name and value
                : field
        )
    );
}

export function handleCheckboxesDynamicData(e, fieldId, setCrudFieldData, setTableNameForFields) {
    const { name, value } = e.target;
    if (name === 'dynamic_table') {

        setTableNameForFields(value)
    }
    setCrudFieldData((prevData) =>
        prevData.map((field) =>
            field.id === fieldId
                ? { ...field, [name]: value } // Update the specific field based on name and value
                : field
        )
    );
}



export const handleDeleteValidation = (fieldId, key, setSelectedValues) => {

    setSelectedValues((prevValues) => {
        const updatedValues = { ...prevValues };
        delete updatedValues[fieldId][key];
        return updatedValues;
    });
};



export const handleDelete = (fieldId, optionIndex, setCrudFieldData) => {

    setCrudFieldData((prevOptions) => {
        return prevOptions.map((field) => {
            if (field.id === fieldId) {
                // Remove the option at the given optionIndex
                const updatedOptions = field.options.filter((data, idx) => idx !== optionIndex);

                // Return the field with updated options
                return { ...field, options: updatedOptions };
            }
            return field; // Return other fields as is
        });
    });

};



export const fetchCrudTableList = async (setCrudTableName) => {
    try {

        const response = await fetch(
            "https://admissioninnepal.in/api/tables",
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                body: qs.stringify({ key: 12321 }),
            }
        );

        if (!response.ok) {
            throw new Error("Network response was not ok");
        }
        const data = await response.json();

        setCrudTableName(data.data);
    } catch (error) {
        console.error("Fetching group data failed", error);
    }
};

export const fetchtableList = async (setTableName) => {
    try {

        const response = await fetch(
            "https://admissioninnepal.in/api/table-list",
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                body: qs.stringify({ key: 12321 }),
            }
        );

        if (!response.ok) {
            throw new Error("Network response was not ok");
        }
        const data = await response.json();

        setTableName(data.data);
    } catch (error) {
        console.error("Fetching group data failed", error);
    }
};

export const fetchFieldName = async (selectedTableName, setTableFields, setIsTableVisible) => {
    if (!selectedTableName) return; // Avoid API call if selectedTableName is empty

    const tableName = selectedTableName.replace("tbl_", "");
    
    try {
        const response = await fetch(
            `https://admissioninnepal.in/api/get-column/${tableName}`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                body: qs.stringify({ key: 12321 }),
            }
        );

        if (!response.ok) {
            throw new Error("Network response was not ok");
        }
        const data = await response.json();
        
        setTableFields(data);
        setIsTableVisible(true);
    } catch (error) {
        console.error("Fetching table fields failed", error);
    }
};

// fetch tbl_group data
export const fetchGroupList = async (setGroups) => {
    try {
        const response = await fetch(
            "https://admissioninnepal.in/api/group-list",
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                body: qs.stringify({ key: 12321 }),
            }
        );

        if (!response.ok) {
            throw new Error("Network response was not ok:");
        }
        const data = await response.json();
        setGroups(data.data);
    } catch (error) {
        console.error("Fetching group data failed", error);
    }
};

export const filterPositionFromGroup = async (setSelectedData, crudTableData) => {
    try {
        const response = await fetch(
            "https://admissioninnepal.in/api/group-list",
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                body: qs.stringify({
                    key: "12321", // Replace with your actual key
                    parent: crudTableData.parent,
                    position: crudTableData.position,
                }),
            }
        );

        if (!response.ok) {
            throw new Error("Failed to fetch data");
        }

        const data = await response.json();
        if (data.responsecode === 200) {
            setSelectedData(data.data);
        } else {
            console.error("Failed to fetch data:", data.message);
        }
    } catch (error) {
        console.error("Error fetching data:", error);
    }
};



// input type selected then store validation list in a state

export const handleCrudTable = (e, setCrudTableData, setSelectedTableName) => {
    const { name, value } = e.target;
    
    

    setCrudTableData((prevData) => ({
        ...prevData,
        [name]: value,
    }));

    setSelectedTableName(value);
};

// export const handlePositionInputChange = (e, setCrudTableData, groups,setPositionData) => {
//     
//     const { name, value } = e.target;
//     

//     // setCrudTableData((prevData) => ({
//     //     ...prevData,
//     //     [name]: value,
//     // }));

//     if (name === 'group') {
//         setCrudTableData((prevData) => ({
//             ...prevData,
//             group_id: value,
//             position: '', // Reset position when parent changes
//         }));

//         const postionData = []


//         groups.map((data) => {
//             if( data.parent == value){
//                 postionData.push(data)
//             }

//         })

//         setPositionData(postionData)


//     } else if (name === 'position') {
//         
//         setCrudTableData((prevData) => ({
//             ...prevData,
//             group_id: value,
//             position: value,
//         }));
//     }
// };




export const handleGroupChange = (e, setCrudTableData, groups, setPositionData, setSelectedSuperParent) => {

    const { name, value } = e.target;


    const postionData = []

    groups.map((data) => {
        if (data.parent == value) {
            postionData.push(data)
        }
    })

    setPositionData(postionData)

    setSelectedSuperParent(value)

    setCrudTableData((prevData) => ({
        ...prevData,
        group_id: value,
        position: '', // Reset position when parent changes
    })

    );
}


export const handlePositionInputChange = (e, setCrudTableData, groups, setPositionData) => {

    const { name, value } = e.target;

    setCrudTableData((prevData) => ({
        ...prevData,
        group_id: value,
        position: value,
    }));

};


export const fetchGroupIdByChildId = (groups, group_id, selectedSuperParent) => {
    if (!group_id) return;

    // Find the group matching the group_id
    const mydata = groups.find(data => data.id === group_id);

    if (!mydata) {
        return selectedSuperParent;
    }

    // Check if the group is a parent group (parent === "0")
    if (mydata.parent === "0") {
        return mydata.id;
    }

    return mydata.parent || selectedSuperParent;
};



// export const handlePositionInputChange = (e, setCrudTableData, groups, setPositionData) => {
//     const { name, value } = e.target;


//     if (name === 'group') {
//         setCrudTableData((prevData) => ({
//             ...prevData,
//             group_id: value,
//             position: '', // Reset position when parent changes
//         }));

//         const postionData = []


//         groups.map((data) => {
//             if (data.parent == value) {
//                 postionData.push(data)
//             }


//         })

//         setPositionData(postionData)


//     } else if (name === 'position') {
//         
//         setCrudTableData((prevData) => ({
//             ...prevData,
//             group_id: value,
//             position: value,
//         }));
//     }
// };


export const handleCheckboxChange = (e, setCrudTableData) => {
    const { id, checked } = e.target;
    

    setCrudTableData((prevData) => ({
        ...prevData,
        [id]: checked ? 1 : 0, // Store as 1 or 0 for database compatibility
    }));
};

export const crudFieldCheckbox = (e, fieldId, setCrudFieldData) => {
    const { checked } = e.target;

    setCrudFieldData(prevData =>
        prevData.map(field =>
            field.id === fieldId ? { ...field, status: checked ? 1 : 0 } : field
        )
    );
};

// export const crudFieldCheckbox = (e, fieldId, setCrudFieldData) => {
//     const { checked } = e.target;

//     setCrudFieldData(prevData =>
//         prevData.map(field =>
//             field.id === fieldId ? { ...field, isChecked: checked, status: checked ? 1 : 0 } : field
//         )
//     );
// };




import React, { useState, useEffect } from "react";

const Side = () => {
  const [sidebarData, setSidebarData] = useState([]);
  const [superParent, setSuperParent] = useState([]);
  const [child, setChild] = useState([]);

  useEffect(() => {
    const fetchSidebarData = async () => {
      try {
        const response = await fetch("https://admissioninnepal.in/Apis/Dashboard", {
          method: "POST",
        });

        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.statusText}`);
        }

        const data = await response.json();
        setSidebarData(data || []); // Ensure data is always an array

        // Process the data to segregate into superParent and child
        const superParentArray = [];
        const childArray = {};
        const crudArray = {};


        data.forEach((currentElement) => {
          if (currentElement.parent === "0") {
            // Create an object and append it to the superParent state
            superParentArray.push({
              id: currentElement.id,
              group_name: currentElement.group_name,
              position: currentElement.position,
              child: currentElement.child || [],
            });
          }

          if (currentElement.child && currentElement.child.length > 0) {
            // Put currentElement.id as key and its child array as value in childArray
            childArray[currentElement.id] = currentElement.child;
          }

          if (currentElement.crud && currentElement.crud.length > 0) {
            // If currentElement has CRUD operations, append them to the child array
            if (!crudArray[currentElement.id]) {
              crudArray[currentElement.id] = [];
            }
            crudArray[currentElement.id] = crudArray[currentElement.id].concat(currentElement.crud);
          }
        });

        
        
        
        

        setSuperParent(superParentArray);
        setChild(childArray);

      } catch (error) {
        console.error("Fetching sidebar data failed", error);
      }
    };

    fetchSidebarData();
  }, []);

  return (
    <div>
      {/* Render your sidebar using superParent, child, etc. */}
    </div>
  );
};

export default Side;

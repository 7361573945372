import { stringify } from "qs";
import { useEffect } from "react";

export const crudFieldType = (crudfield) => {
  switch (crudfield) {
    case "text":
      return "text";
    case "file":
      return "file";
    case "dynamic_select":
      return "dynamic_select";
    case "FileMultiple":
      return "file"; // Handle multiple files separately
    case "textarea":
      return "textarea";
    case "email":
      return "email";
    case "editor":
      return "editor"; // Special type for editor
    case "password":
      return "password"; // Special type for edito
    case "texteditor":
      return "texteditor"; // Special type for edit
    case "custom_option":
      return "custom_option";

    case "custom_checkbox":
      return "custom_checkbox";

    case "select_multiple":
      return "select_multiple";

    case "checkboxes":
      return "checkboxes";

    case "datetime":
      return "datetime";
    case "date":
      return "date";
    case "number":
      return "number";
    case "time":
      return "time";
    case "year":
      return "year";
    case "filemultiple":
      return "filemultiple";
    case "addressmap":
      return "addressmap";
    case "truefalse":
      return "truefalse";
    case "yesno":
      return "yesno";
    case "custom_select":
      return "custom_select";
    case "custom_select_multiple":
      return "custom_select_multiple";
    case "current_user_id":
      return "current_user_id";
    case "range":
      return "range";
    case "range_double_slider":
      return "range_double_slider";
    case "chained":
      return "chained";

    case "timestamp":
      return "timestamp"

    default:
      return undefined; // or some default value if needed
  }
};

export function checkLabelAndStatus(crudFieldData) {
  let labelErrorData = [];
  let statusError = "At lease one status should be checked";
  let visibilityError = "At lease one Visibility should be checked";
  // let GroupError = "";

  // Iterate over each field and check conditions for empty label and active status also atleast one status or visible should checked
  crudFieldData.forEach((field) => {
    if (field.field_label === "" && field.status == 1) {
      labelErrorData.push(field.id);
    }


    // check visibility and status.
    if (field.status == 1) {
      statusError = ""
    }
    if (field.is_visible == 1) {
      visibilityError = ""
    }

  });


  return {

    labelErrorData,
    statusError,
    visibilityError

  }; // Return array of field IDs with errors
}


// for edit Crud component
export const handleDeleteValidationEdit = (e, fieldId, key, setCrudFieldData) => {
  setCrudFieldData((prevData) =>
    prevData.map((field) => {
      if (field.id == fieldId) {
        const updatedPattern = { ...field.pattern };
        if (key in updatedPattern) {
          delete updatedPattern[key];
        }
        return {
          ...field,
          pattern: updatedPattern,
        };
      }
      return field;
    })
  )

};

// if field. pattern include key then delete that key value from object.

export const handleValidationChange = (
  e,
  fieldId,
  setCrudFieldData
) => {
  const selectedOption = e.target.value;

  setCrudFieldData((prevData) =>
    prevData.map((field) =>
      field.id === fieldId
        ? {
          ...field, // Spread the existing properties
          pattern: {
            ...field.pattern,
            [selectedOption]: field.pattern[selectedOption] !== undefined ? field.pattern[selectedOption] : "",
          }
        }
        : field
    )
  );
};


export const handleValueInputChange = (
  e,
  fieldId,
  key,
  setCrudFieldData) => {


  const value = e.target.value
  setCrudFieldData((prevData) =>
    prevData.map((field) =>
      field.id === fieldId
        ? {
          ...field, // Spread the existing properties
          pattern: {
            ...field.pattern,
            [key]: value
          }
        }
        : field
    )
  );


}



export const crudFieldVisible = (e, fieldId, setCrudFieldData) => {
  const { checked } = e.target;

  setCrudFieldData((prevData) =>
    prevData.map((field) =>
      field.id === fieldId ? { ...field, is_visible: checked ? 1 : 0 } : field
    )
  );
};



export const handleRangeChangeForCrud = (fieldId, type, e, setCrudFieldData, setSelectedValues) => {
  const { value } = e.target;

  console.log("range_change", fieldId, type, value);

  setSelectedValues((prevData) => {
    console.log("Updating selected values for fieldId:", fieldId);

    // Create an updated pattern object for the specific fieldId
    const updatedPatternObject = {
      ...prevData[fieldId],  // Maintain the existing pattern object
      [type]: value,  // Update the 'min' or 'max' field
    };

    return {
      ...prevData,
      [fieldId]: updatedPatternObject

    };
  });


};


export const handleRangeChangeCrudEdit = (fieldId, type, e, setCrudFieldData,) => {
  const { value } = e.target;

  console.log(fieldId, type, value)

  setCrudFieldData((prevData) => {
    // Find the index of the item that matches the fieldId
    const updatedData = prevData.map((data) => {

      if (data.id === fieldId) {
        // Create or update the pattern object
        const updatedPatternObject = {
          ...data.pattern,
          [type]: value,
        };

        return {
          ...data,
          pattern: updatedPatternObject, // Update the pattern in the field data
        };
      }

      return data;
    });

    return updatedData;
  });
};





// export const handleRangeChange=( crudField.id,optionIndex,"min",e,setRangeOptions)=>{

// }


export function insertPatternInCrudFieldEdit(updatedCrudFieldData) {
  // Map through the existing crudFieldData to find matching ids

  const updatedData = updatedCrudFieldData.map((field) => {

    // Convert input type to field type.
    const actualCrudFieldType = crudFieldType(field.field_type);

    // Initialize an updatedField object with the current field values
    let updatedField = {
      ...field,
      field_type: actualCrudFieldType
    };

    if (updatedField.options) {


      updatedField = {
        ...updatedField,
        options: JSON.stringify(updatedField.options),
      };




    }


    // Check if pattern contains a value and stringify it if it does
    if (updatedField.pattern) {

      updatedField = {
        ...updatedField,
        pattern: JSON.stringify(field['pattern']),
      };
    }

    // Return the updated field
    return updatedField;
  });

  // Add a new object with key "12321" to the updated data
  const newObject = { key: '12321' };

  const obj = [...updatedData, newObject];
  return obj; // Return the updated object
}



export function insertPatternInCrudField(updatedCrudFieldData, selectedValues) {
  // Map through the existing crudFieldData to find matching ids

  const updatedData = updatedCrudFieldData.map((field) => {

    // Convert input type to field type.
    const actualCrudFieldType = crudFieldType(field.field_type);

    // Initialize an updatedField object with the current field values
    let updatedField = {
      ...field,
      field_type: actualCrudFieldType
    };


    // change options into string formate
    if (updatedField.options) {
      updatedField = {
        ...updatedField,
        options: JSON.stringify(updatedField.options),
      };
    }

    // Update pattern if the current field's id matches a key in selectedValues
    // if (field.field_type == 'range') {

    //   updatedField = {
    //     ...updatedField,
    //     pattern: JSON.stringify(field['pattern'])
    //   }

    // }


    // update pattern in updated field

    if (selectedValues[field.id]) {
      updatedField = {
        ...updatedField,
        pattern: JSON.stringify(selectedValues[field.id]),
      };

    }


    // if (field.field_type == 'range') {

    //   updatedField = {
    //     ...updatedField,
    //     pattern: JSON.stringify(field['pattern'])
    //   }

    // }

    return updatedField;
  });

  // Add a new object with key "12321" to the updated data
  const newObject = { key: '12321' };

  const obj = [...updatedData, newObject];

  return obj; // Return the updated object
}


export const handleAddMore = (
  fieldId,



  setCrudFieldData,
  crudField

) => {
  const newOption = {
    label: "",
    value: "",
    defaultValue: false,
  };

  setCrudFieldData((prevOptions) => {
    // Return the updated state with the modified options

    const updatedOptions = Array.isArray(crudField.options)
      ? [...crudField.options, newOption] // Append to existing options
      : [newOption]; // Initialize options array


    return prevOptions.map((field) =>
      field.id === fieldId
        ? { ...field, options: updatedOptions }
        : field
    );

  });



  // if (selectedInputType === "custom_option") {


  // }


  // else if (selectedInputType === "custom_checkbox") {
  //   setCustomCheckbox((prevOptions) => ({
  //     ...prevOptions,
  //     [fieldId]: [...(prevOptions[fieldId] || []), newOption],
  //   }));
  // }


  // else if (selectedInputType === "custom_select") {
  //   setCustomSelect((prevOptions) => ({
  //     ...prevOptions,
  //     [fieldId]: [...(prevOptions[fieldId] || []), newOption],
  //   }));
  // }

  // else if (selectedInputType === "custom_select_multiple") {
  //   setCustomSelectMultiple((prevOptions) => ({
  //     ...prevOptions,
  //     [fieldId]: [...(prevOptions[fieldId] || []), newOption],
  //   }));
  // }



};

// export const handleCustomOptionChange = (
//   fieldId,
//   optionIndex,
//   key,
//   e,
//   setCrudFieldData
// ) => {
//   const { value } = e.target;

//   setCrudFieldData((prevOptions) => {
//     // Find the field by fieldId
//     const fieldOptions = prevOptions.find((data) => data.id == fieldId);

//     if (!fieldOptions || !fieldOptions.options[optionIndex]) return prevOptions;

//     // Create a new options array with the updated option
//     const updatedOptions = fieldOptions.options.map((option, idx) => {
//       if (idx === optionIndex) {
//         return { ...option, [key]: value }; // Update the specific key
//       }
//       return option;
//     });

//     // Return the updated state with the modified options
//     return prevOptions.map((field) =>
//       field.id === fieldId
//         ? { ...field, options: updatedOptions }
//         : field
//     );
//   });
// };


// export const handleAddMore = (fieldId, setCustomOptions, setCustomCheckbox, selectedInputType) => {
//   if (selectedInputType === 'custom_option') {
//     setCustomOptions((prevOptions) => ({
//       ...prevOptions,
//       [fieldId]: [
//         ...(prevOptions[fieldId] || []),
//         { label: '', value: '', defaultValue: false },
//       ],
//     }));
//   } else if (selectedInputType === 'custom_checkbox') {
//     
//     
//     setCustomCheckbox((prevOptions) => ({
//       ...prevOptions,
//       [fieldId]: [
//         ...(prevOptions[fieldId] || []),
//         { label: '', value: '', defaultValue: false },
//       ],
//     }));
//   }
// };


// export const handleAddMore = (
//   fieldId,
//   setCustomOptions,
//   setCustomCheckbox,
//   setCustomSelect,
//   setCustomSelectMultiple,
//   selectedInputType
// ) => {
//   const newOption = {
//     label: "",
//     value: "",
//     defaultValue: false,
//   };

//   if (selectedInputType === "custom_option") {
//     setCustomOptions((prevOptions) => ({
//       ...prevOptions,
//       [fieldId]: [...(prevOptions[fieldId] || []), newOption],
//     }));
//   } else if (selectedInputType === "custom_checkbox") {
//     setCustomCheckbox((prevOptions) => ({
//       ...prevOptions,
//       [fieldId]: [...(prevOptions[fieldId] || []), newOption],
//     }));
//   } else if (selectedInputType === "custom_select") {
//     setCustomSelect((prevOptions) => ({
//       ...prevOptions,
//       [fieldId]: [...(prevOptions[fieldId] || []), newOption],
//     }));
//   }

//   else if (selectedInputType === "custom_select_multiple") {
//     setCustomSelectMultiple((prevOptions) => ({
//       ...prevOptions,
//       [fieldId]: [...(prevOptions[fieldId] || []), newOption],
//     }));
//   }
// };




// custom select option and checkbox code

export const handleCustomOptionChange = (
  fieldId,
  optionIndex,
  key,
  e,
  setCrudFieldData,
  crudField
) => {
  const { value, checked } = e.target;

  setCrudFieldData((prevOptions) => {

    // if crudField data or crudField.options[optionIndex] empty return as it is. 
    if (!crudField || !crudField.options[optionIndex]) return prevOptions;

    // Create a new options array with the updated option
    const updatedOptions = crudField.options.map((option, idx) => {
      if (idx === optionIndex) {
        return {
          ...option,
          [key]: key === 'defaultValue' ? checked : value, // Toggle for checkbox, update value for others
        };
      }
      return option;
    });

    // Return the updated state with the modified options
    return prevOptions.map((field) =>
      field.id === fieldId
        ? { ...field, options: updatedOptions }
        : field
    );
  });
};


export const handleCustomCheckboxChange = (
  fieldId,
  optionIndex,
  key,
  e,
  setCrudFieldData,
  crudField
) => {
  const { value, checked } = e.target;

  setCrudFieldData((prevOptions) => {

    if (!crudField || !crudField.options[optionIndex]) return prevOptions;

    // Create a new options array with the updated option
    const updatedOptions = crudField.options.map((option, idx) => {
      if (idx === optionIndex) {
        return {
          ...option,
          [key]: key === 'defaultValue' ? checked : value, // Toggle for checkbox, update value for others
        };
      }
      return option;
    });

    // Return the updated state with the modified options
    return prevOptions.map((field) =>
      field.id === fieldId
        ? { ...field, options: updatedOptions }
        : field
    );
  });

};


export const handleCustomSelectMultipleChange = (
  fieldId,
  optionIndex,
  key,
  e,
  setCrudFieldData,
  crudField

) => {
  const { value, checked } = e.target;


  setCrudFieldData((prevOptions) => {

    // if crudField data or crudField.options[optionIndex] empty return as it is. 
    if (!crudField || !crudField.options[optionIndex]) return prevOptions;


    // const fieldOptions = prevOptions[fieldId] || [];

    const newOptions = crudField.options.map((option, index) =>
      index === optionIndex
        ? {
          ...option,
          [key]: key === 'defaultValue' ? checked : value, // Toggle for checkbox, update value for others
        }
        : option


    );
    return prevOptions.map((field) =>
      field.id === fieldId
        ? { ...field, options: newOptions }
        : field
    );
  });
};

// export const handleCustomSelectMultipleChange = (
//   fieldId,
//   optionIndex,
//   type,
//   e,
//   setCustomSelectMultiple
// ) => {
//   const { value, checked } = e.target;

//   setCustomSelectMultiple((prevOptions) => {

//     const fieldOptions = prevOptions[fieldId] || [];
//     const newOptions = fieldOptions.map((option, index) =>
//       index === optionIndex
//         ? {
//           ...option,
//           [type]: type === 'defaultValue' ? checked : value,
//         }
//         : option
//     );
//     return {
//       ...prevOptions,
//       [fieldId]: newOptions,
//     };
//   });
// };




export const handleCustomSelectChange = (
  fieldId,
  optionIndex,
  key,
  e,
  setCrudFieldData,
  crudField
) => {
  const { value, checked } = e.target;
  setCrudFieldData((prevOptions) => {

    // if crudField data or crudField.options[optionIndex] empty return as it is. 
    if (!crudField || !crudField.options[optionIndex]) return prevOptions;

    const newOptions = crudField.options.map((option, index) =>
      index === optionIndex
        ? {
          ...option,
          [key]: key === 'defaultValue' ? checked : value, // Toggle for checkbox, update value for others
        }
        : option

    );
    return prevOptions.map((field) =>
      field.id === fieldId
        ? { ...field, options: newOptions }
        : field
    );
  });
};





export function insertOption(setCrudFieldData, customOptions) {
  const updatedData = Object.entries(customOptions).forEach(([key, value]) => {
    // Check if the current field's id matches a key in customOptions
    setCrudFieldData((preData) =>
      preData.map((field) =>
        field.id === parseInt(key)
          ? {
            ...field,
            // Update the options with the corresponding value from customOptions
            options: JSON.stringify(value),
          }
          : field
      )
    );
  });



}



// Function to create an HTML input element or editor based on crudFieldType
export const createFieldElement = (fieldType, multiple = false) => {
  let element;
  const type = crudFieldType(fieldType);
  if (type === "editor") {
    // Create a content-editable div or integrate a rich text editor
    element = document.createElement("div");
    element.contentEditable = true; // Basic content-editable div for simple editing
    element.classList.add("editor"); // Add a class for styling
  } else if (type === "checkbox") {
    // Create a checkbox input
    element = document.createElement("input");
    element.type = "checkbox";
  } else {
    // Create standard input fields
    element = document.createElement("input");
    element.type = type;
    if (fieldType === "FileMultiple" && multiple) {
      element.setAttribute("multiple", "multiple");
    }
  }

  return element;
};






import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import { CKEditor } from "ckeditor4-react"; // Ensure CKEditor is correctly imported
import { addRegion } from "../../customhook/AddApi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AddRegionForm = () => {
  const navigate = useNavigate();
  const [fields, setFields] = useState([]);
  const [formData, setFormData] = useState({});


  //fetch regionform
  useEffect(() => {
    const fetchFields = async () => {
      try {
        const response = await axios.get(
          "https://admissioninnepal.in/api/form/region"
        );
        

        if (Array.isArray(response.data)) {
          const fetchedFields = response.data.filter(
            (field) => field.COLUMN_COMMENT !== ""
          );
          setFields(fetchedFields);
          
        } else {
          console.error("Unexpected data structure:", response.data);
        }
      } catch (error) {
        console.error("Error fetching form fields:", error);
      }
    };

    fetchFields();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setFormData({
      ...formData,
      [name]: files[0], // Assuming single file upload
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await addRegion(formData);
      
      navigate("/list-of-region");
      // Show success toast
      toast.success("Region added successfully");

      // Reset form fields
      setFormData({});
      document.getElementById("kt_careers_form").reset();
    } catch (error) {
      console.error("Error submitting form:", error);
      // Show error toast
      toast.error("Error submitting form");
    }
  };

  const sortedFields = fields.sort((a, b) => {
    const aOrder = parseInt(a.COLUMN_COMMENT.split(",")[1] || 100, 10);
    const bOrder = parseInt(b.COLUMN_COMMENT.split(",")[1] || 100, 10);
    return aOrder - bOrder;
  });

  return (
    <>
      <Header />
      <Sidebar />
      <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
        <div className="d-flex flex-column flex-column-fluid">
          <div id="kt_app_toolbar" className="app-toolbar py-3 py-lg-6">
            <div
              id="kt_app_toolbar_container"
              className="app-container container-xxl d-flex flex-stack"
            >
              <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                <h1 className="page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-5 text-center">
                  Add Region Data
                </h1>
                <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                  <li className="breadcrumb-item text-muted">
                    <Link
                      to="/dashboardpage"
                      className="text-muted text-hover-primary"
                    >
                      Home
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <span className="bullet bg-gray-500 w-5px h-2px" />
                  </li>
                  <li className="breadcrumb-item text-muted">Region</li>
                </ul>
              </div>
            </div>
          </div>

          <div id="kt_app_content" className="app-content flex-column-fluid">
            <div
              id="kt_app_content_container"
              className="app-container container-xxl"
            >
              <div className="card">
                <div className="card-body p-0">
                  <div className="card-px my-10" id="c">
                    <div className="d-flex flex-column flex-lg-row mb-17">
                      <div className="flex-lg-row-fluid me-0 me-lg-20">
                        <form
                          className="form mb-5"
                          onSubmit={handleSubmit}
                          id="kt_careers_form"
                        >
                          <div className="row">
                            {sortedFields.map((field, index) => {
                              const [fieldType, fieldOrder, fieldSize] =
                                field.COLUMN_COMMENT.split(",");
                              const colSize = fieldSize === "l" ? 12 : 6;

                              return (
                                <div
                                  key={index}
                                  className={`col-lg-${colSize} mb-5`}
                                >
                                  <label className=" fs-5 fw-semibold mb-2">
                                    {field.COLUMN_NAME.replace(
                                      /_/g,
                                      " "
                                    ).replace(/\b\w/g, (char) =>
                                      char.toUpperCase()
                                    )}
                                  </label>
                                  {fieldType === "file" ? (
                                    <input
                                      type="file"
                                      className="form-control"
                                      name={field.COLUMN_NAME}
                                      onChange={handleFileChange}
                                    />
                                  ) : fieldType === "texteditor" ? (
                                    <CKEditor
                                      initData=""
                                      name={field.COLUMN_NAME}
                                      onChange={handleInputChange}
                                    />
                                  ) : fieldType === "textarea" ? (
                                    <textarea
                                      className="form-control"
                                      name={field.COLUMN_NAME}
                                      onChange={handleInputChange}
                                    />
                                  ) : (
                                    <input
                                      type="text"
                                      className="form-control form-control-solid"
                                      name={field.COLUMN_NAME}
                                      onChange={handleInputChange}
                                    />
                                  )}
                                </div>
                              );
                            })}
                          </div>
                          <button
                            type="submit"
                            className="btn btn-primary"
                            id="kt_careers_submit_button"
                          >
                            <span className="indicator-label">Add</span>
                            <span className="indicator-progress">
                              Please wait...{" "}
                              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                          </button>
                        </form>
                        <ToastContainer />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddRegionForm;

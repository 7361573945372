

import React, { useState, useEffect, useRef } from 'react';
import qs from 'qs';
import ValidateForm from '../../utitls/form/ValidateConditions'
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import { Link, useLocation, useParams } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import { getCrudDataBySlug, getCrudFieldList, getCrudWithCrudFieldDataBySlug, insertFormBuilderData } from '../../customhook/FetchApi';
import { Editor } from 'primereact/editor';
import { MultiSelect } from "react-multi-select-component";
import { getSelectOption, handleChekboxesChange, handleCustomSelectMultiple, handleCustomSelectMultipleOption, handleInputChange, handleMultipleFileChange, handleRadioChange, handleSelectChange, handleSelectMultipleChange, handleSelectMultipleOption, handleTextEditorChange } from '../../utitls/formbuilder/FormBuilderFunctions'



function GlobalForm() {

  const { slug } = useParams()
  

  

  const [text, setText] = useState('')

  const location = useLocation()
  const { table_name } = location.state || {}

  const [tableData, setTableData] = useState({});

  const [crudFieldData, setCrudFieldData] = useState([]);
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const [crudTableData, setCrudTableData] = useState();
  const [selectedValue, setSelectedValue] = useState();
  

  // fetch crud Table data by slug.
  useEffect(() => {

    const fetchCrudTableData =() => {
      getCrudWithCrudFieldDataBySlug(slug, setCrudFieldData, setFormData, setCrudTableData);
    }
    fetchCrudTableData()
  }, [slug])



  useEffect(() => {
    
    

  }, [crudTableData])



  // Fetch crud_field data
  // useEffect(() => {
  //   

  //   if (crudTableData.id) {
  //     
  //     getCrudFieldList(setCrudFieldData, setFormData, crudTableData.id);
  //   }
  // }, []);


  useEffect(() => {
    
    
  }, [crudFieldData])

  useEffect(() => {
    
    
  }, [formData])



  const checkFormValidation = async () => {
    const newErrors = {};

    const promises = crudFieldData.map(async (field) => {
      const { pattern, id } = field;
      const value = formData[field.field_name];

      if (pattern) {
        const { isValid, errors } = await ValidateForm(pattern, value, formData);
        if (!isValid) {
          newErrors[id] = errors;
        }
      }
    });

    await Promise.all(promises);
    
    
    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  // Handle form submission
  const submitForm = async (e) => {
    e.preventDefault();

    // Perform validation check
    const isValid = await checkFormValidation();

    if (isValid) {
      
      insertFormBuilderData(formData, crudTableData.table_name);
    } else {
      
    }
  };



  // Function to safely parse the JSON string
  const parseOptions = (options) => {

    if (!options) {
      return [];
    }
    try {

      // Remove the leading and trailing quotes
      const cleanedOptions = options.trim().replace(/^"|"$/g, '');

      // Parse the cleaned string into a JSON object
      return JSON.parse(cleanedOptions);
    } catch (error) {
      console.error('Failed to parse options:', error);
      return [];
    }
  };


  const fetchTableRecords = async (tableName, fieldId) => {
    let newTableName = tableName.replace('tbl_', '');

    try {
      const response = await fetch(`https://admissioninnepal.in/api/get-data/${newTableName}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: qs.stringify({ key: '12321' }),
      });

      const data = await response.json();

      setTableData((prevData) => ({ ...prevData, [fieldId]: data }));
    } catch (error) {
      console.error('Error fetching table records:', error);
    }
  };

  // Fetch data for dynamic_select fields
  useEffect(() => {
    crudFieldData
      .filter((field) => field.dynamic_table)
      .forEach((field) => {
        fetchTableRecords(field.dynamic_table, field.id);
      });
  }, [crudFieldData]);

  useEffect(() => {
    
    

  }, [tableData])






  useEffect(() => {
    
    
  }, [formData])



  const [selected, setSelected] = useState([]);

  const [countryData, setCountryData] = useState([])


  useEffect(() => {
    
    

  }, [selected])

  const filterTableData = (tableData, id, dependency, formData, dynamic_condition) => {
    if (!tableData[id]) return [];

    const options = tableData[id].filter(item => item[dependency] == formData[dynamic_condition]);
    
    return options;
  };



  // useEffect(() => {
  //    = filterTableData(tableData, id, dependency, formData, dynamic_condition);
  //   setFilteredOptions(options);
  // }, [tableData, id, dependency, formData, dynamic_condition]);

  useEffect(() => {
    
  }, [formData]);

  return (
    <>

      <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
        <div className="d-flex flex-column flex-column-fluid">
          
          <div id="kt_app_content" className="app-content flex-column-fluid">
            <div
              id="kt_app_content_container"
              className="app-container container-xxl"
            >
              <div className="">
                <div className="card-body p-0">
                  <div className="card-px my-10" id="c">
                    <div className="d-flex flex-column flex-lg-row mb-17">
                      <div className="flex-lg-row-fluid me-0 me-lg-20">


                        <form onSubmit={submitForm} encType='multipart/form-data'
                        >
                          <div className='row'>
                            {crudFieldData
                              // .sort((a, b) => a.position - b.position)
                              .filter((field) => field.status === '1')
                              .map((field) => {

                                const { field_type, field_name, col_size, id, options, dynamic_table, dependency, dynamic_label, dynamic_field, dynamic_condition, pattern } = field;

                                const multipleSelectOptions = getSelectOption(id, tableData, dynamic_field, dynamic_label);

                                let optionsArray = [];
                                if (options) {
                                  optionsArray = parseOptions(options);
                                  
                                  
                                }

                                //  allowedExtension

                                let acceptPattern = ""


                                let allowedExtension = parseOptions(pattern).allowedExtensions;
                                if (allowedExtension) {  // Check if pattern is not empty

                                  acceptPattern = allowedExtension.split(',').map(ext => `.${ext}`).join(','); // Convert to '.pdf,.jpg'
                                  
                                  
                                }


                                const defaultSelectedOption = optionsArray.find((opt) => opt.defaultValue)?.value || "";

                                return (
                                  <div key={id} className={`${col_size} mb-5`}>
                                    <label className="fs-5 fw-semibold mb-2">
                                      {field_name.replace(/_/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase())}
                                    </label>


                                    {/* Checkbox and radio inputs */}
                                    {optionsArray.length > 0 ? (
                                      field_type === 'custom_checkbox' ? (
                                        <div>
                                          {optionsArray.map((opt) => (
                                            <div className="form-check" key={opt.id}>
                                              <input
                                                type="checkbox"
                                                name={field_name}
                                                value={opt.value}
                                                checked={formData[field_name]?.includes(opt.value) || false}
                                                className="form-check-input"
                                                onChange={(e) => handleChekboxesChange(e, opt.value, setFormData)}
                                              />
                                              <label htmlFor={`${field_name}_${opt.value}`} className="form-check-label">
                                                {opt.label}
                                              </label>
                                            </div>
                                          ))}
                                        </div>
                                      ) : field_type === 'custom_option' ? (
                                        <div>
                                          {optionsArray.map((opt, i) => (
                                            <div className="form-check" key={i}>
                                              <input
                                                type="radio"
                                                name={field_name}
                                                value={opt.value}
                                                checked={formData[field_name] === opt.value}
                                                className="form-check-input"
                                                onChange={(e) => handleRadioChange(e, setFormData, setSelectedValue)}
                                              />
                                              <label htmlFor={`${field_name}_${opt.value}`} className="form-check-label">
                                                {opt.label}
                                              </label>
                                            </div>
                                          ))}
                                        </div>
                                      ) :

                                        field_type === 'custom_select' ? (
                                          <div>
                                            <div className="form-check">
                                              <select
                                                name={field_name}
                                                value={formData[field_name]}
                                                className="form-select form-select-sm"
                                                onChange={(e) => handleSelectChange(e, setFormData, setSelectedValue)}
                                              >
                                                <option value="">Select option</option>
                                                {optionsArray.map((option, index) => (
                                                  <option key={index} value={option.value}>
                                                    {option.label}
                                                  </option>
                                                ))}
                                              </select>
                                            </div>
                                          </div>
                                        ) :

                                          field_type === 'custom_select_multiple' ? (
                                            <div>
                                              <div className="form-check">
                                                <MultiSelect
                                                  options={optionsArray}
                                                  value={
                                                    formData[field_name] && Array.isArray(formData[field_name])
                                                      ? formData[field_name].map(value => optionsArray.find(option => option.value === value))
                                                      : optionsArray.filter(option => option.defaultValue).map(option => option.value) // Pre-select default values
                                                  }
                                                  onChange={(selected) => {
                                                    const selectedValues = selected.map(option => option.value);
                                                    setFormData({ ...formData, [field_name]: selectedValues });
                                                  }}
                                                  labelledBy="Select"
                                                />
                                              </div>
                                            </div>
                                          ) : null
                                    ) :

                                      field_type === 'select_multiple' ? (
                                        <div className="form-group">
                                          {tableData && (
                                            <>
                                              <label htmlFor={field_name}>{dynamic_label || field_name}</label>

                                              <MultiSelect
                                                // options={getSelectOption(id, tableData, dynamic_field, dynamic_label)}
                                                options={multipleSelectOptions}
                                                value={
                                                  formData[field_name] === '' || formData[field_name] === undefined
                                                    ? []
                                                    : Array.isArray(formData[field_name])
                                                      ? formData[field_name].map(value => multipleSelectOptions.find(option => option.value === value))
                                                      : []
                                                }
                                                onChange={(selected) => {
                                                  handleSelectMultipleOption(selected, formData, setFormData);
                                                }}
                                                labelledBy="Select"
                                              />
                                            </>
                                          )}
                                        </div>
                                      ) :

                                        field_type === 'dynamic_select' ? (
                                          <div className="form-group">
                                            <label htmlFor={field_name}>{dynamic_label || field_name}</label>
                                            <select
                                              name={field_name}
                                              value={formData[field_name] || ''}
                                              className="form-select"
                                              onChange={(e) => handleSelectChange(e, setFormData, setSelectedValue)}
                                            >
                                              <option value="">Select an option</option>
                                              {tableData[id]?.map((item) => (
                                                <option key={item["id"]} value={item[dynamic_field]}>
                                                  {item[dynamic_label]} {/* Accessing the dynamic label */}
                                                </option>
                                              ))}
                                            </select>
                                          </div>
                                        ) :


                                          field_type === 'chained' ? (

                                            // {filterTableData(tableData, id, dependency, formData, dynamic_condition)}
                                            <div className="form-group">
                                              <select
                                                name={field_name}
                                                value={formData[field_name] || ''}
                                                className="form-select"
                                                onChange={(e) => handleSelectChange(e, setFormData, setSelectedValue)}
                                              >
                                                <option value="">Select an option</option>
                                                {filterTableData(tableData, id, dependency, formData, dynamic_condition).map(filteredItem => (
                                                  <option key={filteredItem["id"]} value={filteredItem[dynamic_field]}>
                                                    {filteredItem[dynamic_label]}
                                                  </option>
                                                ))}
                                              </select>
                                            </div>
                                          ) :


                                            field_type === 'select' ? (
                                              <div className="form-group">
                                                <select
                                                  name={field_name}
                                                  value={formData[field_name] || []}
                                                  className="form-select"
                                                  onChange={(e) => handleSelectChange(e, dynamic_table, id)}
                                                >
                                                  <option value="">Select an option</option>

                                                  {tableData[id]?.map((item) => (
                                                    <option key={item["id"]} value={item[dynamic_field]}>
                                                      {item[dynamic_label]} {/* Accessing the dynamic label */}
                                                    </option>
                                                  ))}
                                                </select>

                                              </div>
                                            ) :




                                              field_type === 'checkboxes' ? (
                                                <div className="form-group" >
                                                  {tableData[id]?.map((item) => (
                                                    <div key={item.id}>
                                                      <input
                                                        type="checkbox"
                                                        id={field_name}
                                                        className="form-check-input"
                                                        name={field_name}
                                                        onChange={(e) => handleChekboxesChange(e, item[dynamic_field], setFormData)}
                                                      />

                                                      <label htmlFor="" className="form-check-label">{item[dynamic_label]}</label>
                                                    </div>
                                                  ))}

                                                </div>
                                              ) : field_type === "password" ? (
                                                <input
                                                  id={field_name}
                                                  type="password"
                                                  className="form-control"
                                                  name={field_name}
                                                  onChange={(e) => handleInputChange(e, setFormData)}
                                                />
                                              )

                                                //range input type
                                                : field_type === "range" ? (
                                                  <div>
                                                    
                                                    
                                                    <input
                                                      id={field_name}
                                                      type="range"
                                                      className="form-control"
                                                      name={field_name}
                                                      onChange={(e) => handleInputChange(e, setFormData)}
                                                    />
                                                  </div>
                                                )

                                                  : field_type === "textarea" ? (
                                                    <textarea
                                                      id={field_name}
                                                      className="form-control"
                                                      name={field_name}
                                                      onChange={(e) => handleInputChange(e, setFormData)}
                                                    />
                                                  ) :
                                                    field_type === "text" ? (
                                                      <input
                                                        id={`input-${field.id}`}
                                                        type="text"
                                                        className="form-control"
                                                        name={field_name}
                                                        onChange={(e) => handleInputChange(e, setFormData)}
                                                      />
                                                    ) :

                                                      field_type === "current_user_id" ? (
                                                        <input
                                                          id={`input-${field.id}`}
                                                          value={localStorage.getItem('token')}
                                                          type="text"
                                                          className="form-control"
                                                          name={field_name}
                                                          onChange={(e) => handleInputChange(e, setFormData)}
                                                        />
                                                      ) :

                                                        field_type === "editor" ? (
                                                          <Editor
                                                            value={text}
                                                            name={field_name}

                                                            onTextChange={(e) => handleTextEditorChange(e, field_name, e.htmlValue, setFormData)}

                                                            // onTextChange={(e) => setText(e.htmlValue)}
                                                            style={{ height: '320px' }}
                                                          />
                                                        )
                                                          : field_type === "yesno" ? (
                                                            <div>
                                                              <input
                                                                type="radio"
                                                                name={field_name}
                                                                value="yes"
                                                                className="form-check-input"
                                                                onChange={(e) => handleInputChange(e, setFormData)}
                                                              />
                                                              <label htmlFor={field_name + "_yes"}>Yes</label>

                                                              <input
                                                                type="radio"
                                                                name={field_name}
                                                                value="no"
                                                                className="form-check-input"
                                                                onChange={(e) => handleInputChange(e, setFormData)}
                                                              />
                                                              <label htmlFor={field_name + "_no"}>No</label>
                                                            </div>
                                                          ) : field_type === "filemultiple" ? (
                                                            <input
                                                              type="file"
                                                              multiple
                                                              accept={acceptPattern || "*"}  // Use the dynamically created pattern or "*" if empty
                                                              className="form-control"
                                                              name={field_name}
                                                              onChange={(e) => handleMultipleFileChange(e, setFormData)}
                                                            />
                                                          ) : field_type === "file" ? (
                                                            <div>
                                                             
                                                              <input
                                                                id={field_name}
                                                                type="file"
                                                                accept={acceptPattern || "*"}  // Use the dynamically created pattern or "*" if empty
                                                                className="form-control"
                                                                name={field_name}
                                                                onChange={(e) => handleInputChange(e, setFormData)}
                                                              />
                                                            </div>
                                                          ) : field_type === "addressmap" ? (
                                                            <iframe
                                                              src={`https://maps.google.com/maps?q=${encodeURIComponent(
                                                                field.address
                                                              )}&output=embed`}
                                                              width="600"
                                                              height="450"
                                                              frameBorder="0"
                                                              style={{ border: 0 }}
                                                              allowFullScreen=""
                                                              aria-hidden="false"
                                                              tabIndex="0"
                                                              title="Address Map"
                                                            ></iframe>
                                                          ) : field_type === "truefalse" ? (
                                                            <input
                                                              type="checkbox"
                                                              className="form-check-input"
                                                              name={field_name}
                                                              onChange={(e) => handleInputChange(e, setFormData)}
                                                            />
                                                          )
                                                            : field_type === "datetime" ? (
                                                              <input
                                                                type="datetime-local"
                                                                className="form-control"
                                                                name={field_name}
                                                                onChange={(e) => handleInputChange(e, setFormData)}
                                                              />
                                                            ) : field_type === "date" ? (
                                                              <input
                                                                type="date"
                                                                className="form-control"
                                                                name={field_name}
                                                                onChange={(e) => handleInputChange(e, setFormData)}
                                                              />
                                                            ) : field_type === "year" ? (
                                                              <select
                                                                className="form-control"
                                                                name={field_name}
                                                                onChange={(e) => handleInputChange(e, setFormData)}
                                                              >
                                                                <option value="">Select Year</option>
                                                                {Array.from({ length: 201 }, (_, index) => {
                                                                  const year = 1900 + index;
                                                                  return (
                                                                    <option key={year} value={year}>
                                                                      {year}
                                                                    </option>
                                                                  );
                                                                })}
                                                              </select>
                                                            )
                                                              : field_type === "time" ? (
                                                                <input
                                                                  type="time"
                                                                  className="form-control"
                                                                  name={field_name}
                                                                  onChange={(e) => handleInputChange(e, setFormData)}
                                                                />
                                                              ) :

                                                                null}

                                    <span className="error" style={{ color: 'red' }}>
                                      {Array.isArray(errors[id])
                                        ? errors[id].map((err, idx) => <div key={idx}>{err}</div>)
                                        : errors[id] || ''}
                                    </span>
                                    <br />
                                  </div>
                                );
                              })}


                            <button className='btn btn-primary w-100' type="submit">Submit</button>
                          </div>
                        </form>


                        <ToastContainer />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div >
      </div >
    </>
  );
}

export default GlobalForm;




import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import crudImg from "../Crud.png";

import useStore from '../zustand/ReactStore'
import Icon from "../img/grid-view.png"
import {
  faBuildingCircleArrowRight,
  faChartSimple,
  faList,
  faTable,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const Sidebar = () => {

  // import sidebar update funtion from zustand store
  const sidebarDataFromZustandStore = useStore((state) => state.sidebarDataFromZustandStore); // Access sidebar data from store



  const [isCollapsed, setIsCollapsed] = useState(false);

  const [expandedGroups, setExpandedGroups] = useState({});

  const navigate = useNavigate();
  const [sidebarData, setSidebarData] = useState([]);
  const [isDashboardOpen, setIsDashboardOpen] = useState(false);
  const [isGeneralOpen, setIsGeneralOpen] = useState(false);

  useEffect(() => {
    setSidebarData(sidebarDataFromZustandStore || []);
  }, [sidebarDataFromZustandStore])




  function showTableData(crudTable) {
    console.log("crudTable")
    console.log(crudTable.crud)

    const newTableName = (crudTable.table_name).replace("tbl_", "")

      navigate(`/crudtabledata/${(newTableName)}`)

    // if(Array.isArray(crudTable.crud) && (crudTable.crud).length !=0 ){

    // }
  }

  const toggleGroup = (groupId) => {
    setExpandedGroups((prevState) => ({
      ...prevState,
      [groupId]: !prevState[groupId], // Toggle the current group
    }));
  };

  const renderChildItems = (childItems, parentId) => {
    return (
      expandedGroups[parentId] &&
      childItems.map((childItem) => (
        <div key={childItem.id}>


          <ul>
            {childItem.crud && childItem.crud.length > 0 ? (
              // Child has CRUD, so it acts like an accordion
              <li
                onClick={() => toggleGroup(childItem.id)} // Toggle sub-items for groups with CRUD
                className="menu-heading fw-bold text-uppercase fs-7 mx-2 mt-1"
                id="side-child"
                style={{ color: "white" }}
              >
                {childItem.group_name || childItem.title}
                <span
                  className={`menu-arrow ${expandedGroups[childItem.id] ? "down" : "right"
                    }`}
                />

                {expandedGroups[childItem.id] && (
                  <ul>
                    {childItem.crud.map((crudItem) => (
                      <li
                        key={crudItem.id}
                        onClick={() => showTableData(crudItem)} // Show data for CRUD items
                        className="menu-heading fw-bold text-uppercase fs-7 mx-2 mt-4"
                        id="side-subchild"

                      >
                        {crudItem.title}
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ) : (
              // Handle items with only group_id


              <>
                {/* {expandedGroups[childItem.id] && ( */}

                <li
                  className="menu-heading fw-bold text-uppercase fs-7 mx-2"
                  onClick={() => showTableData(childItem)} // Show data for items with group_id
                  id="side-child"

                >
                  {childItem.group_name || childItem.title}
                </li>


                {/* )} */}
              </>
            )}
          </ul>
        </div>
      ))
    );
  };




  const toggleSidebar = () => {

  };


  const toggleGeneral = () => {
    setIsGeneralOpen(!isGeneralOpen);
  };



  const handleLogout = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You will be logged out.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, logout!",
      cancelButtonText: "No, stay logged in",
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.removeItem("token");
        navigate("/");
        Swal.fire(
          "Logged Out!",
          "You have been logged out successfully.",
          "success"
        );
      }
    });
  };

  return (
    <>
      <div
        className={`page d-flex flex-row flex-column-fluid ${isCollapsed ? "collapsed" : ""
          }`}
      >
        <div
          id="kt_aside"
          className={`aside aside-dark aside-hoverable ${isCollapsed ? "collapsed" : ""
            }`}
          data-kt-drawer="true"
          data-kt-drawer-name="aside"
          data-kt-drawer-activate="{default: true, lg: false}"
          data-kt-drawer-overlay="true"
          data-kt-drawer-width="{default:'200px', '300px': '250px'}"
          data-kt-drawer-direction="start"
          data-kt-drawer-toggle="#kt_aside_mobile_toggle"
        >
          {/*begin::Brand*/}
          <div className="aside-logo flex-column-auto" id="kt_aside_logo">
            <Link to="/dashboardpage">
              <h1>
                {" "}
                <span className="college-text">COLLEGE</span>{" "}
                <span className="directory-text">DIRECTORY</span>
              </h1>

              {/* <img
          alt="Logo"
          src="assets/media/logos/demo13.svg"
          className="h-15px logo"
        /> */}
            </Link>
            {/*begin::Aside toggler*/}
            <div
              id="kt_aside_toggle"
              className="btn btn-icon w-auto px-0 btn-active-color-primary aside-toggle me-n2"
              data-kt-toggle="true"
              data-kt-toggle-state="active"
              data-kt-toggle-target="body"
              data-kt-toggle-name="aside-minimize"
              onClick={toggleSidebar}
            >
              <i
                className={`ki-outline ki-double-left fs-1 ${isCollapsed ? "rotate-180" : ""
                  }`}
              />
            </div>
            {/*end::Aside toggler*/}
          </div>
          <div className="aside-menu flex-column-fluid">
            {/*begin::Aside Menu*/}

            <div className="hover-scroll-overlay-y" id="kt_aside_menu_wrapper">
              <div
                className="menu menu-column menu-title-gray-800 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500"
                id="sidebar-main"
                data-kt-menu="true"
              >
                {/*begin:Menu item*/}
                <div
                  data-kt-menu-trigger="click"
                  className="menu-item menu-accordion"
                >
                  <span className="menu-link">
                    <span className="menu-icon">
                      {/* <i className="ki-outline ki-element-11 fs-2" /> */}
                      {/* <FontAwesomeIcon icon={faGrip} style={{color: "#f2f2f2",}} />
                 */}
                      {/* <img src={Icon} alt="" className="img-fluid mt-1" id="ic" /> */}
                      <FontAwesomeIcon
                        icon={faChartSimple}
                        size="xl"
                        style={{ color: "#ffffff" }}
                      />
                    </span>
                    <Link className="menu-title mx-4" to="/dashboardpage">
                      Dashboards
                    </Link>
                  </span>
                </div>

                <div
                  data-kt-menu-trigger="click"
                  className={`menu-item menu-accordion    ${isGeneralOpen ? "here show" : ""
                    }`}
                  onClick={toggleGeneral}
                >
                  <span className="menu-link">
                    <span className="menu-icon ">
                      {/* <img src={crudImg} alt="" id="crud-img" /> */}
                      <FontAwesomeIcon
                        icon={faList}
                        size="xl"
                        style={{ color: "#ffffff" }}
                      />
                      {/* <i className="ki-outline ki-element-11 fs-2" /> */}
                    </span>

                    <Link
                      className="menu-title mx-4 "
                    // id="master"
                    >
                      CRUD
                    </Link>
                    <span
                      className={`menu-arrow ${isGeneralOpen ? "down" : "right"
                        }`}
                    />
                  </span>

                  <div
                    className={`menu-sub menu-sub-accordion ${isGeneralOpen ? "open" : ""
                      }`}
                  >
                    <div className="menu-link d-block">
                      <div className="menu-item">
                        <Link className="menu-link" to="/crud-group">
                          <span className="menu-bullet">
                            <span className="bullet bullet-dot" />
                          </span>
                          <span className="menu-title">Crud Group</span>
                        </Link>
                      </div>
                      <div className="menu-item">
                        <Link className="menu-link" to="/crud-form">
                          <span className="menu-bullet">
                            <span className="bullet bullet-dot" />
                          </span>
                          <span className="menu-title">Crud </span>
                        </Link>
                      </div>

                      <div className="menu-item">
                        <Link className="menu-link" to="/crud-builder">
                          <span className="menu-bullet">
                            <span className="bullet bullet-dot" />
                          </span>
                          <span className="menu-title">Form Builder</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="menu-content  mx-5  sidebar">
                  {sidebarData.length > 0 ? (
                    sidebarData.map((item) => (
                      <div
                        key={item.id}
                        className={`menu-item menu-accordion ${expandedGroups[item.id] ? "here show" : ""
                          }`}
                      >
                        <span
                          className="menu-link "
                          style={{ padding: "10px" }}
                          onClick={() => toggleGroup(item.id)}
                        >
                          <span className="menu-icon">
                            <FontAwesomeIcon
                              icon={faList}
                              size="xl"
                              style={{ color: "#ffffff" }}
                            />
                            {/* <FontAwesomeIcon
                        icon={faTable}
                        size="xl"
                        style={{ color: "#ffffff" }}
                      /> */}
                            {/* <img src={Icon} alt="" className="img-fluid mt-1" id="ic" /> */}
                          </span>
                          <span className="menu-title mx-4">
                            {item.group_name}
                          </span>
                          <span
                            className={`menu-arrow ${expandedGroups[item.id] ? "down" : "right"
                              }`}
                          />
                        </span>
                        <div
                          className={`menu-sub menu-sub-accordion ${expandedGroups[item.id] ? "open" : ""
                            }`}
                        >
                          {item.child &&
                            item.child.length > 0 &&
                            renderChildItems(item.child, item.id)}
                        </div>
                      </div>
                    ))
                  ) : (
                    <p>Loading...</p>
                  )}
                </div>

                <hr />

                <div id="logout_section" class="menu-content mt-5 mx-5 uni">
                  <button
                    class="menu-heading fw-bold btn btn-light fs-7 mx-2 mt-3"
                    id="master_logout"
                    onClick={handleLogout}
                  >
                    <span class="text-dark">Logout</span>
                  </button>
                </div>

                <hr />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;






import React from 'react';
import { Bar } from 'react-chartjs-2';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

// Register the chart.js components needed
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const MyBarChart = () => {

  const data = {
    labels: "Data",
    datasets: [{
      label: 'My First Dataset',

      data: [65, 59, 80, 81, 56, 55, 40],
      backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(255, 159, 64, 0.2)',
        'rgba(255, 205, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(201, 203, 207, 0.2)'
      ],
      borderColor: [
        'rgb(255, 99, 132)',
        'rgb(255, 159, 64)',
        'rgb(255, 205, 86)',
        'rgb(75, 192, 192)',
        'rgb(54, 162, 235)',
        'rgb(153, 102, 255)',
        'rgb(201, 203, 207)'
      ],
      borderWidth: 1
    }]


  }

 

  return (
    <div className="container">

      <div className="row">

        <div className="col-md-6">
          <Bar data={data}  />

        </div>
      </div>
    </div>


  )
};

export default MyBarChart;





// import React,{useState,useEffect} from 'react'
// import Charts from './Charts';
// import Legend from './Legend';

// const MyBarChart = () => {
//   const [data, setData] = useState([]);
//   const series = ['France', 'Italy', 'England', 'Sweden', 'Germany'];
//   const labels = ['cats', 'dogs', 'horses', 'ducks', 'cows'];
//   const colors = ['#43A19E', '#7B43A1', '#F2317A', '#FF9824', '#58CF6C'];

//   const getRandomInt = (min, max) => {
//     return Math.floor(Math.random() * (max - min)) + min;
//   };

//   const populateArray = () => {
//     const newData = [];
//     const seriesCount = 5; // Keep constant series count
//     const seriesLength = 5; // Keep constant series length

//     for (let i = 0; i < seriesCount; i++) {
//       const tmp = [];
//       for (let j = 0; j < seriesLength; j++) {
//         tmp.push(getRandomInt(0, 20));
//       }
//       newData.push(tmp);
//     }

//     setData(newData);
//   };

//   useEffect(() => {
//     populateArray();
//     const interval = setInterval(populateArray, 2000);
//     return () => clearInterval(interval); // Clean up the interval on component unmount
//   }, []);

//   return (
//     <section>
//       <Charts data={data} labels={series} colors={colors} height={250} />
//       <Charts data={data} labels={series} colors={colors} height={250} opaque={true} grouping={'stacked'} />
//       <Charts data={data} labels={series} colors={colors} height={250} grouping={'layered'} />
//       <Charts data={data} labels={series} colors={colors} horizontal={true} />
//       <Legend labels={labels} colors={colors} />
//     </section>
//   );
// }

// export default MyBarChart



import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

// Create the Zustand store
const useStore = create(


    persist(
        devtools((set) => ({
            // Initialize the sidebarData in the store
            sidebarDataFromZustandStore: [],
            crudDataFromZustandStore : [],


            
            // Function to update crud table Data
            updateCrudfromZustand: (data) => {
                set({ crudDataFromZustandStore: data })

            },

            // Function to update sidebarData
            updateSidebarDatafromZustand: (data) => {

                set({ sidebarDataFromZustandStore: data })

            },

            // Function to fetch and update sidebar data
            fetchSidebarDataZustandStore: async () => {
                try {
                    const response = await fetch("https://admissioninnepal.in/Apis/Dashboard", {
                        method: "POST",
                    });

                    if (!response.ok) {
                        throw new Error(`Network response was not ok: ${response.statusText}`);
                    }

                    const data = await response.json();
            
                    set({ sidebarDataFromZustandStore: data || [] });

                    return true
                } catch (error) {
                    console.error("Fetching sidebar data failed", error);
                }
            },

        })),
        {
            name: 'sidebar-storage', // unique name
        }
    )
);

export default useStore;

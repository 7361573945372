
import React, { useState } from "react"
import Modal from 'react-bootstrap/Modal';

import Form from 'react-bootstrap/Form';


const BootstrapMpdel = () => {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (

        <div className="">

            {/* <Button variant="primary" onClick={handleShow}>
               
            </Button> */}

            <button variant="primary" onClick={handleShow}> Launch demo modal</button>

            <Modal show={show} onHide={handleClose}>

                <Modal.Body>

                    <Form.Select aria-label="Default select example">
                        <option>Open this select menu</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                    </Form.Select>

                </Modal.Body>


            </Modal>


        </div>

    )

}

export default BootstrapMpdel


import React from 'react';
import { Line as ChartLine } from 'react-chartjs-2';



const Line = ({ data }) => {
  return <ChartLine data={data} options={{ responsive: true }} />;
};

export default Line;
import React, { useEffect, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import axios from "axios";
import qs from "qs";
import Swal from "sweetalert2";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import Footer from "../../components/Footer";
import { fetchSidebarData } from "../../customhook/FetchApi";
import useStore from '../../zustand/ReactStore'

// import '../../../public/assets/css/style.bundle.css'

const EditGroup = () => {
  const { id } = useParams(); // Get the group ID from URL
  const navigate = useNavigate();

  const updateSidebarDatafromZustand = useStore((state) => state.updateSidebarDatafromZustand);


  const [formData, setFormData] = useState({
    group_name: "",
    parent: "",
    position: "",
  });
  const [groups, setGroups] = useState([]);

  useEffect(() => {
    const fetchGroupData = async () => {
      try {
        const response = await fetch(
          "https://admissioninnepal.in/api/group-list",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
            body: qs.stringify({ key: 12321 }),
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok.");
        }

        const data = await response.json();
        setGroups(data.data);

        // Find the specific group to edit
        const group = data.data.find((g) => g.id === id); // Compare as string
        

        if (group) {
          setFormData({
            group_name: group.group_name,
            parent: group.parent,
            position: group.position,
          });
        } else {
          // 
        }
      } catch (error) {
        console.error("Error fetching group data:", error);
      }
    };

    fetchGroupData();
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleUpdateGroup = async (e) => {
    e.preventDefault();

    if (formData.group_name == "") {


      Swal.fire(
        {
          icon: "warning",
          title: "Group Required",
          text: "Group is required",
        }
      )
      return;
    }

    
    try {
      const data = qs.stringify({
        ...formData,
        key: 12321,
        id,
      });

      const response = await axios.post(
        "https://admissioninnepal.in/api/add-edit-group",
        data,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );

      // Check if the request was successful
      if (response.status === 200) {
        // Fetch updated sidebar data
        const sidebardata = await fetchSidebarData();
        // Update Zustand store with new sidebar data
        await updateSidebarDatafromZustand(sidebardata);

        // Show success message
        Swal.fire({
          title: "Success",
          text: "Group updated successfully",
          icon: "success",
          confirmButtonText: "OK",
        });

        // Navigate back to the list after update
        navigate("/crud-group");
      } else {
        // Show error message if the response status is not 200
        Swal.fire({
          title: "Error",
          text: "Failed to update group",
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    } catch (error) {
      console.error("Error updating group:", error);

      // Show error message if an exception occurs
      Swal.fire({
        title: "Error",
        text: "Failed to update group",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };


  useEffect( ()=>{
    console.log("formData")
    console.log(formData)

  })

  return (
    <>
      <Header />
      <Sidebar groups={groups} />

      <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
        <div className="d-flex flex-column flex-column-fluid">
          <div id="kt_app_toolbar" className="app-toolbar py-3 py-lg-6">
            <div
              id="kt_app_toolbar_container"
              className="app-container container-xxl d-flex flex-stack"
            >
              <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                <h1 className="page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-5 text-center">
                  Update Crud Group
                </h1>
                <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                  <li className="breadcrumb-item text-muted">
                    <Link
                      to="/dashboardpage"
                      className="text-muted text-hover-primary"
                    >
                      Home
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <span className="bullet bg-gray-500 w-5px h-2px" />
                  </li>
                  <li className="breadcrumb-item text-muted">Crud Group</li>
                </ul>
              </div>
            </div>
          </div>
          <div id="kt_app_content" className="app-content flex-column-fluid">
            <div
              id="kt_app_content_container"
              className="app-container container-xxl"
            >
              <div className="card">
                <div className="card-body p-0">
                  <div className="card-px my-10" id="c">
                    <div className="d-flex flex-column flex-lg-row mb-17">
                      <div className="flex-lg-row-fluid me-0 me-lg-20">
                        <div className="row gx-3">
                          <div className="col-lg-12 ">
                            <div className="gap-lg">
                              <h3>Edit Group</h3>



                              <form onSubmit={handleUpdateGroup}>
                                <div className="form-group mb-3">
                                  <label>Group Name</label>
                                  <input
                                    type="text"
                                    name="group_name"
                                    value={formData.group_name}
                                    onChange={handleInputChange}
                                    className="form-control mt-3"
                                  />
                                </div>
                             
                                <div className="form-group mb-3">
                                  <label>Parent</label>
                                  <select
                                    name="parent"
                                    value={formData.parent}
                                    onChange={handleInputChange}
                                    className="form-select mt-3"
                                  >
                                    <option value="">Select Parent</option>
                                    {groups.map((group) => (
                                      <option key={group.id} value={group.id}>
                                        {group.group_name}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                                <div className="form-group mb-3">
                                  <label>Position</label>
                                  <select
                                    name="position"
                                    value={formData.position}
                                    onChange={handleInputChange}
                                    className="form-select mt-3"
                                  >
                                    <option value="">Select Position</option>
                                    {groups
                                      .filter(
                                        (group) =>
                                          group.parent === formData.parent
                                      )
                                      .map((group) => (
                                        <option key={group.id} value={group.id}>
                                          {group.group_name}
                                        </option>
                                      ))}
                                  </select>
                                </div>
                                <button
                                  type="submit"
                                  className="btn btn-primary mt-5 w-100"
                                >
                                  Update Group
                                </button>
                              </form>


                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <Footer /> */}
      </div>
    </>
  );
};

export default EditGroup;

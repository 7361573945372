import React, { useState, useEffect } from "react";
import qs from "qs";

const YourComponent = () => {
  const [formData, setFormData] = useState({
    parent: "",
    position: "",
  });
  const [groups, setGroups] = useState([]);
  const [selectedData, setSelectedData] = useState([]);

  useEffect(() => {
    fetchGroups();
  }, []);

  const fetchGroups = async () => {
    try {
      const response = await fetch(
        "https://admissioninnepal.in/api/group-list",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: qs.stringify({ key: 12321 }),
        }
      );

      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
      const data = await response.json();
      setGroups(data.data);
      
    } catch (error) {
      console.error("Fetching group data failed", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    // Fetch data based on formData
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://admissioninnepal.in/api/group-list",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
            body: qs.stringify({
              key: "12321", // Replace with your actual key
              parent: formData.parent,
              position: formData.position,
            }),
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const data = await response.json();
        if (data.responsecode === 200) {
          setSelectedData(data.data);
        } else {
          console.error("Failed to fetch data:", data.message);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Fetch data only if both parent and position are selected
    if (formData.parent && formData.position) {
      fetchData();
    }
  }, [formData]);

  return (
    <div>
      <div className="form-group mb-3">
        <label>Parent</label>
        <select
          name="parent"
          value={formData.parent}
          onChange={handleInputChange}
          className="form-control"
        >
          <option value="">Select Parent</option>
          {groups
            .filter((group) => group.parent == 0)
            .map((group) => (
              <option key={group.id} value={group.id}>
                {group.group_name}
              </option>
            ))}
        </select>
      </div>
      <div className="form-group mb-3">
        <label>Position</label>
        <select
          name="position"
          value={formData.position}
          onChange={handleInputChange}
          className="form-control"
        >
          <option value="">Select Position</option>
          {groups
            .filter((group) => group.parent === formData.parent)
            .map((group) => (
              <option key={group.id} value={group.id}>
                {group.group_name}
              </option>
            ))}
        </select>
      </div>
    
    </div>
  );
};

export default YourComponent;



import React, { createContext, useEffect, useState } from "react";
import { Chart, registerables } from 'chart.js'; // Import registerables
import Sidebar from "../components/Sidebar.jsx";
import { PieChart } from '@mui/x-charts';
import { desktopOS, valueFormatter } from '../components/WebUsage.jsx';
import Header from "../components/Header.jsx";
import { Bar } from 'react-chartjs-2';
import Line from "../components/Line.jsx";
import DashboardCard from "./DashboardCard.js";
import { fetchCrudTableData, fetchSidebarData } from "../customhook/FetchApi.jsx";

import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
import { Link } from "react-router-dom";
import useStore from '../zustand/ReactStore'; // Import the Zustand store

// Register all necessary components

Chart.register(...registerables);

const CrudTableData = createContext();

const Saledata = {
  labels: ['Week 1', 'Week 2', 'Week 3', 'Week 4'],
  datasets: [
    {
      label: 'Sales',
      data: [12000, 15000, 13000, 14000],
      backgroundColor: 'rgba(75, 192, 192, 0.6)',
      borderColor: 'rgba(75, 192, 192, 1)',
      borderWidth: 1,
    },
  ],
};

const options = {
  scales: {
    y: {
      beginAtZero: true,
    },
  },
};



function Dashboard() {

  const fetchSidebarDataZustandStore = useStore((state) => state.fetchSidebarDataZustandStore); // Fetch method from store
  // const sidebarDataFromZustandStore = useStore((state) => state.sidebarDataFromZustandStore); // Access sidebar data from store
  const updateSidebarDatafromZustand = useStore((state) => state.updateSidebarDatafromZustand)


  useEffect(() => {
    console.log("hii");
    const fetchData = async () => {
      try {
        const sidebardata = await fetchSidebarData();
        await updateSidebarDatafromZustand(sidebardata)

      } catch (error) {
        console.error("Error fetching sidebar data:", error); // Handle any errors
      }
    };

    fetchData(); // Call the async function
  }, [fetchSidebarDataZustandStore, updateSidebarDatafromZustand]); // Include dependencies if needed


  // Data for the bar graph
  const data = {
    labels: "Data",
    datasets: [{
      label: 'My First Dataset',
      data: [65, 59, 80, 81, 56, 55, 40],
      backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(255, 159, 64, 0.2)',
        'rgba(255, 205, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(201, 203, 207, 0.2)'
      ],
      borderColor: [
        'rgb(255, 99, 132)',
        'rgb(255, 159, 64)',
        'rgb(255, 205, 86)',
        'rgb(75, 192, 192)',
        'rgb(54, 162, 235)',
        'rgb(153, 102, 255)',
        'rgb(201, 203, 207)'
      ],
      borderWidth: 1
    }]
  };

  // Data for the line graph
  const lineData = {
    labels: ['Week 1', 'Week 2', 'Week 3', 'Week 4'],
    datasets: [
      {
        label: 'Weekly User Signups',
        data: [30, 70, 100, 80],
        fill: false,
        backgroundColor: 'rgba(153, 102, 255, 0.6)',
        borderColor: 'rgba(153, 102, 255, 1)',
      },
    ],
  };

  useEffect(() => {
    AOS.init();
  }, []);

  const [crudData, setCrudData] = useState([]);

  useEffect(() => {
    const getCrudTable = async () => {
      const crudtable = await fetchCrudTableData();
      setCrudData(crudtable);
    };
    getCrudTable();
  }, []);




  return (
    <>
      <div className="d-flex flex-column flex-root">
        <div className="page d-flex flex-row flex-column-fluid">
          <Sidebar />
          <div
            className="wrapper d-flex flex-column flex-row-fluid"
            id="kt_wrapper"
          >
            <Header />

            <div
              className="content d-flex flex-column flex-column-fluid"
              id="kt_content cards"
            >
              {/*begin::Toolbar*/}
              <div className="toolbar" id="kt_toolbar">
                {/*begin::Container*/}
                <div
                  id="kt_toolbar_container"
                  className="container-fluid d-flex flex-stack"
                >
                  {/*begin::Page title*/}
                  <div
                    id="content_wrapper"
                    className="page-title d-flex justify-content-between align-items-center flex-wrap lh-1"
                    data-kt-swapper="true"
                    data-kt-swapper-mode="prepend"
                    data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
                  >
                    <div id="multipurpose_section">
                      <h1 className="d-flex align-items-center text-gray-900 fw-bold my-1 fs-3">
                        Admin Dashboard
                      </h1>
                    </div>

                    <div id="breadcrumb_section">
                      <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-1">
                        {/* <li className="breadcrumb-item text-muted"> */}
                        <Link
                          to="index.html"
                          className="bread-crumb-text"
                        >
                          Home
                        </Link>

                        {/* </li> */}

                        <li className="breadcrumb-item">
                          <span className="bullet bg-gray-300 w-5px h-2px" />
                        </li>

                        <li className="">
                          <Link
                            to="/dashboardpage"
                            className="custom-dashboard-link bread-crumb-text"
                          >
                            Dashboards
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>




            <div className="row py-5">
              {/* Dashboard Cards */}
              <DashboardCard imagePath={"college.png"} Title={"College"} counterCount={200} trendIcon={"trend.png"} />
              <DashboardCard imagePath={"college.png"} Title={"Course"} counterCount={150} trendIcon={"decrease.png"} />
              <DashboardCard imagePath={"college.png"} Title={"Exam"} counterCount={250} trendIcon={"trend.png"} />
              <DashboardCard imagePath={"college.png"} Title={"College"} counterCount={200} trendIcon={"trend.png"} />
              <DashboardCard imagePath={"college.png"} Title={"Course"} counterCount={150} trendIcon={"decrease.png"} />
              <DashboardCard imagePath={"college.png"} Title={"Exam"} counterCount={250} trendIcon={"trend.png"} />

              {/* Graphs */}

              <div className="row g-5 g-xl-8 ms-3">
                {/*begin::Col*/}
                <div className="col-xl-4">
                  {/*begin::Mixed Widget 14*/}
                  <div
                    className="card card-xxl-stretch mb-xl-8 theme-dark-bg-body"
                    style={{ backgroundColor: "#F7D9E3" }}
                  >
                    {/*begin::Body*/}
                    <div className="card-body d-flex flex-column">
                      {/*begin::Wrapper*/}
                      <div className="d-flex flex-column mb-7">
                        {/*begin::Title*/}
                        <a href="#" className="text-gray-900 text-hover-primary fw-bold fs-3">
                          Summary
                        </a>
                        {/*end::Title*/}
                      </div>
                      {/*end::Wrapper*/}
                      {/*begin::Row*/}
                      <div className="row g-0">
                        {/*begin::Col*/}
                        <div className="col-6">
                          <div className="d-flex align-items-center mb-9 me-2">
                            {/*begin::Symbol*/}
                            <div className="symbol symbol-40px me-3">
                              <div className="symbol-label bg-light">
                                <i className="ki-duotone ki-abstract-42 fs-1 text-gray-900">
                                  <span className="path1" />
                                  <span className="path2" />
                                </i>
                              </div>
                            </div>
                            {/*end::Symbol*/}
                            {/*begin::Title*/}
                            <div>
                              <div className="fs-5 text-gray-900 fw-bold lh-1">$50K</div>
                              <div className="fs-7 text-gray-600 fw-bold">Sales</div>
                            </div>
                            {/*end::Title*/}
                          </div>
                        </div>
                        {/*end::Col*/}
                        {/*begin::Col*/}
                        <div className="col-6">
                          <div className="d-flex align-items-center mb-9 ms-2">
                            {/*begin::Symbol*/}
                            <div className="symbol symbol-40px me-3">
                              <div className="symbol-label bg-light">
                                <i className="ki-duotone ki-abstract-45 fs-1 text-gray-900">
                                  <span className="path1" />
                                  <span className="path2" />
                                </i>
                              </div>
                            </div>
                            {/*end::Symbol*/}
                            {/*begin::Title*/}
                            <div>
                              <div className="fs-5 text-gray-900 fw-bold lh-1">$4,5K</div>
                              <div className="fs-7 text-gray-600 fw-bold">Revenue</div>
                            </div>
                            {/*end::Title*/}
                          </div>
                        </div>
                        {/*end::Col*/}
                        {/*begin::Col*/}
                        <div className="col-6">
                          <div className="d-flex align-items-center me-2">
                            {/*begin::Symbol*/}
                            <div className="symbol symbol-40px me-3">
                              <div className="symbol-label bg-light">
                                <i className="ki-duotone ki-abstract-21 fs-1 text-gray-900">
                                  <span className="path1" />
                                  <span className="path2" />
                                </i>
                              </div>
                            </div>
                            {/*end::Symbol*/}
                            {/*begin::Title*/}
                            <div>
                              <div className="fs-5 text-gray-900 fw-bold lh-1">40</div>
                              <div className="fs-7 text-gray-600 fw-bold">Tasks</div>
                            </div>
                            {/*end::Title*/}
                          </div>
                        </div>
                        {/*end::Col*/}
                        {/*begin::Col*/}
                        <div className="col-6">
                          <div className="d-flex align-items-center ms-2">
                            {/*begin::Symbol*/}
                            <div className="symbol symbol-40px me-3">
                              <div className="symbol-label bg-light">
                                <i className="ki-duotone ki-abstract-44 fs-1 text-gray-900">
                                  <span className="path1" />
                                  <span className="path2" />
                                </i>
                              </div>
                            </div>
                            {/*end::Symbol*/}
                            {/*begin::Title*/}
                            <div>
                              <div className="fs-5 text-gray-900 fw-bold lh-1">$5.8M</div>
                              <div className="fs-7 text-gray-600 fw-bold">Sales</div>
                            </div>
                            {/*end::Title*/}
                          </div>
                        </div>
                        {/*end::Col*/}
                      </div>
                      {/*end::Row*/}
                    </div>
                  </div>
                  {/*end::Mixed Widget 14*/}
                </div>
                {/*end::Col*/}
                {/*begin::Col*/}
                <div className="col-xl-4">
                  {/*begin::Mixed Widget 14*/}
                  <div
                    className="card card-xxl-stretch mb-xl-8 theme-dark-bg-body"
                    style={{ backgroundColor: "#CBF0F4" }}
                  >
                    {/*begin::Body*/}
                    <div className="card-body d-flex flex-column">
                      {/*begin::Wrapper*/}
                      <div className="d-flex flex-column mb-7">
                        {/*begin::Title*/}
                        <a href="#" className="text-gray-900 text-hover-primary fw-bold fs-3">
                          Summary
                        </a>
                        {/*end::Title*/}
                      </div>
                      {/*end::Wrapper*/}
                      {/*begin::Row*/}
                      <div className="row g-0">
                        {/*begin::Col*/}
                        <div className="col-6">
                          <div className="d-flex align-items-center mb-9 me-2">
                            {/*begin::Symbol*/}
                            <div className="symbol symbol-40px me-3">
                              <div className="symbol-label bg-light">
                                <i className="ki-duotone ki-abstract-42 fs-1 text-gray-900">
                                  <span className="path1" />
                                  <span className="path2" />
                                </i>
                              </div>
                            </div>
                            {/*end::Symbol*/}
                            {/*begin::Title*/}
                            <div>
                              <div className="fs-5 text-gray-900 fw-bold lh-1">$50K</div>
                              <div className="fs-7 text-gray-600 fw-bold">Sales</div>
                            </div>
                            {/*end::Title*/}
                          </div>
                        </div>
                        {/*end::Col*/}
                        {/*begin::Col*/}
                        <div className="col-6">
                          <div className="d-flex align-items-center mb-9 ms-2">
                            {/*begin::Symbol*/}
                            <div className="symbol symbol-40px me-3">
                              <div className="symbol-label bg-light">
                                <i className="ki-duotone ki-abstract-45 fs-1 text-gray-900">
                                  <span className="path1" />
                                  <span className="path2" />
                                </i>
                              </div>
                            </div>
                            {/*end::Symbol*/}
                            {/*begin::Title*/}
                            <div>
                              <div className="fs-5 text-gray-900 fw-bold lh-1">$4,5K</div>
                              <div className="fs-7 text-gray-600 fw-bold">Revenue</div>
                            </div>
                            {/*end::Title*/}
                          </div>
                        </div>
                        {/*end::Col*/}
                        {/*begin::Col*/}
                        <div className="col-6">
                          <div className="d-flex align-items-center me-2">
                            {/*begin::Symbol*/}
                            <div className="symbol symbol-40px me-3">
                              <div className="symbol-label bg-light">
                                <i className="ki-duotone ki-abstract-21 fs-1 text-gray-900">
                                  <span className="path1" />
                                  <span className="path2" />
                                </i>
                              </div>
                            </div>
                            {/*end::Symbol*/}
                            {/*begin::Title*/}
                            <div>
                              <div className="fs-5 text-gray-900 fw-bold lh-1">40</div>
                              <div className="fs-7 text-gray-600 fw-bold">Tasks</div>
                            </div>
                            {/*end::Title*/}
                          </div>
                        </div>
                        {/*end::Col*/}
                        {/*begin::Col*/}
                        <div className="col-6">
                          <div className="d-flex align-items-center ms-2">
                            {/*begin::Symbol*/}
                            <div className="symbol symbol-40px me-3">
                              <div className="symbol-label bg-light">
                                <i className="ki-duotone ki-abstract-44 fs-1 text-gray-900">
                                  <span className="path1" />
                                  <span className="path2" />
                                </i>
                              </div>
                            </div>
                            {/*end::Symbol*/}
                            {/*begin::Title*/}
                            <div>
                              <div className="fs-5 text-gray-900 fw-bold lh-1">$5.8M</div>
                              <div className="fs-7 text-gray-600 fw-bold">Sales</div>
                            </div>
                            {/*end::Title*/}
                          </div>
                        </div>
                        {/*end::Col*/}
                      </div>
                      {/*end::Row*/}
                    </div>
                  </div>
                  {/*end::Mixed Widget 14*/}
                </div>
                {/*end::Col*/}
                {/*begin::Col*/}
                <div className="col-xl-4">
                  {/*begin::Mixed Widget 14*/}
                  <div
                    className="card card-xxl-stretch mb-xl-8 theme-dark-bg-body"
                    style={{ backgroundColor: "#CBD4F4" }}
                  >
                    {/*begin::Body*/}
                    <div className="card-body d-flex flex-column">
                      {/*begin::Wrapper*/}
                      <div className="d-flex flex-column mb-7">
                        {/*begin::Title*/}
                        <a href="#" className="text-gray-900 text-hover-primary fw-bold fs-3">
                          Summary
                        </a>
                        {/*end::Title*/}
                      </div>
                      {/*end::Wrapper*/}
                      {/*begin::Row*/}
                      <div className="row g-0">
                        {/*begin::Col*/}
                        <div className="col-6">
                          <div className="d-flex align-items-center mb-9 me-2">
                            {/*begin::Symbol*/}
                            <div className="symbol symbol-40px me-3">
                              <div className="symbol-label bg-light">
                                <i className="ki-duotone ki-abstract-42 fs-1 text-gray-900">
                                  <span className="path1" />
                                  <span className="path2" />
                                </i>
                              </div>
                            </div>
                            {/*end::Symbol*/}
                            {/*begin::Title*/}
                            <div>
                              <div className="fs-5 text-gray-900 fw-bold lh-1">$50K</div>
                              <div className="fs-7 text-gray-600 fw-bold">Sales</div>
                            </div>
                            {/*end::Title*/}
                          </div>
                        </div>
                        {/*end::Col*/}
                        {/*begin::Col*/}
                        <div className="col-6">
                          <div className="d-flex align-items-center mb-9 ms-2">
                            {/*begin::Symbol*/}
                            <div className="symbol symbol-40px me-3">
                              <div className="symbol-label bg-light">
                                <i className="ki-duotone ki-abstract-45 fs-1 text-gray-900">
                                  <span className="path1" />
                                  <span className="path2" />
                                </i>
                              </div>
                            </div>
                            {/*end::Symbol*/}
                            {/*begin::Title*/}
                            <div>
                              <div className="fs-5 text-gray-900 fw-bold lh-1">$4,5K</div>
                              <div className="fs-7 text-gray-600 fw-bold">Revenue</div>
                            </div>
                            {/*end::Title*/}
                          </div>
                        </div>
                        {/*end::Col*/}
                        {/*begin::Col*/}
                        <div className="col-6">
                          <div className="d-flex align-items-center me-2">
                            {/*begin::Symbol*/}
                            <div className="symbol symbol-40px me-3">
                              <div className="symbol-label bg-light">
                                <i className="ki-duotone ki-abstract-21 fs-1 text-gray-900">
                                  <span className="path1" />
                                  <span className="path2" />
                                </i>
                              </div>
                            </div>
                            {/*end::Symbol*/}
                            {/*begin::Title*/}
                            <div>
                              <div className="fs-5 text-gray-900 fw-bold lh-1">40</div>
                              <div className="fs-7 text-gray-600 fw-bold">Tasks</div>
                            </div>
                            {/*end::Title*/}
                          </div>
                        </div>
                        {/*end::Col*/}
                        {/*begin::Col*/}
                        <div className="col-6">
                          <div className="d-flex align-items-center ms-2">
                            {/*begin::Symbol*/}
                            <div className="symbol symbol-40px me-3">
                              <div className="symbol-label bg-light">
                                <i className="ki-duotone ki-abstract-44 fs-1 text-gray-900">
                                  <span className="path1" />
                                  <span className="path2" />
                                </i>
                              </div>
                            </div>
                            {/*end::Symbol*/}
                            {/*begin::Title*/}
                            <div>
                              <div className="fs-5 text-gray-900 fw-bold lh-1">$5.8M</div>
                              <div className="fs-7 text-gray-600 fw-bold">Sales</div>
                            </div>
                            {/*end::Title*/}
                          </div>
                        </div>
                        {/*end::Col*/}
                      </div>
                      {/*end::Row*/}
                    </div>
                  </div>
                  {/*end::Mixed Widget 14*/}
                </div>
                {/*end::Col*/}
              </div>
              <div className="col-md-6 mt-5 p-4">
                <Bar data={data} />
              </div>
              <div className="col-md-6 mt-5 p-4">
                <Line data={lineData} />
              </div>

              {/* Row for PieChart and Table */}
              <div className="row">
                <div className="col-md-7 mt-5 ms-5">
                  <div className="card-box" id="boc">
                    <div className="card-head" id='head'>
                      <header id="hh">Recent Transactions</header>


                    </div>
                    <div className="card-body p-4">
                      <div className="table-responsive">
                        <table className="table table-hover">
                          <tbody>
                            <tr>
                              <th>Order No</th>
                              <th>Student Name</th>
                              <th>Status</th>
                              <th>Amount</th>
                              <th>Receipt</th>
                            </tr>
                            {/* Sample rows */}
                            <tr>
                              <td>XY56987</td>
                              <td>John Deo</td>
                              <td><i className="fas fa-circle col-green me-2"></i>Confirm</td>
                              <td>$955</td>
                              <td><i className="fas fa-file-pdf col-red"></i></td>
                            </tr>
                            <tr>
                              <td>XY56987</td>
                              <td>John Deo</td>
                              <td><i className="fas fa-circle col-green me-2"></i>Confirm</td>
                              <td>$955</td>
                              <td><i className="fas fa-file-pdf col-red"></i></td>
                            </tr>
                            <tr>
                              <td>XY56987</td>
                              <td>John Deo</td>
                              <td><i className="fas fa-circle col-green me-2"></i>Confirm</td>
                              <td>$955</td>
                              <td><i className="fas fa-file-pdf col-red"></i></td>
                            </tr>
                            <tr>
                              <td>XY56987</td>
                              <td>John Deo</td>
                              <td><i className="fas fa-circle col-green me-2"></i>Confirm</td>
                              <td>$955</td>
                              <td><i className="fas fa-file-pdf col-red"></i></td>
                            </tr>
                            <tr>
                              <td>XY56987</td>
                              <td>John Deo</td>
                              <td><i className="fas fa-circle col-green me-2"></i>Confirm</td>
                              <td>$955</td>
                              <td><i className="fas fa-file-pdf col-red"></i></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>


                {/* PieChart */}

                <div className="col-md-3 mt-1 ms-5 " >
                  <PieChart
                    className="pt-5 "
                    series={[
                      {
                        data: [
                          { id: 0, value: 10, label: 'series A' },
                          { id: 1, value: 15, label: 'series B' },
                          { id: 2, value: 20, label: 'series C' },
                        ],
                      },
                    ]}
                    width={300}
                    height={300} // Adjust the height as necessary
                  />
                </div>

                {/* Recent Transactions Table */}


              </div>
            </div>





            {/* <Footer /> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;

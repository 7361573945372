

import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
// import { inputTypes } from "../../Constant";
import { options } from "../../Constant";
import Swal from 'sweetalert2';
import axios from "axios";

import qs from "qs";

import {
    fetchtableList,
    handlePositionInputChange,
    handleCheckboxChange,
    fetchGroupList,
    crudFieldCheckbox,
    handleDelete,
    handleCheckboxesDynamicData,
    handleChainedDynamicData,
    fetchGroupIdByChildId,
    handleGroupChange,
    validationInputFieldValueCheck,
    validateInputFieldValueForEditCrud,
    checkCrudGroupError


} from "../../utitls/crud/CrudFunctions"; // Adjust the path if needed

import {
    checkLabelAndStatus,
    crudFieldVisible,
    handleAddMore,
    handleCustomOptionChange,

    handleDeleteValidationEdit,
    handleRangeChange,
    handleRangeChangeCrudEdit,
    handleValidationChange,
    handleValueInputChange,
    insertOption,
    insertPatternInCrudFieldEdit,
} from "../../utitls/crudField/crudFieldFunction";


import {
    fetchCrudAndCrudField,
    fetchCrudData,
    fetchCrudTableData,
    fetchFieldsForDynamicSelect,
    fetchSidebarData,
    fetchtabledata,
    fetchTableFields,
    fetchTableFieldsOnly,
    submitCrud,
} from "../../customhook/FetchApi";
import BreadCrumb from "./BreadCrumb";

import useStore from '../../zustand/ReactStore'


const EditCrud = () => {

    const navigate = useNavigate()
    var data = useParams()

    var crud_id = data.id

    const [buttonDisabled, setButtonDisabled] = useState(false);


    const [tableName, setTableName] = useState([]);
    const [selectedTableName, setSelectedTableName] = useState("");
    const [tableFields, setTableFields] = useState();
    const [validationInputError, setValidationInputError] = useState([])

    const [isTableVisible, setIsTableVisible] = useState(false); // New state for table visibility

    const [crudFieldData, setCrudFieldData] = useState([]);

    const [selectedValidationOption, setSelectedValidationOption] = useState("");
    const [validationOptions, setValidationOptions] = useState({});
    const [isGroupsLoading, setIsGroupsLoading] = useState(true); // State to manage loading indicator for groups
    const [selectCrudFieldPosition, setSelectCrudFieldPosition] = useState({});
    const [groups, setGroups] = useState([]);
    const [selectedInputType, setSelectedInputType] = useState("");
    const [tableNameForFields, setTableNameForFields] = useState("");

    const [crudDataVisibility, setCrudDataVisibility] = useState(false);
    const [tableNameAndFields, setTableNameAndFields] = useState({})

    const [labelError, setLabelError] = useState([])
    const [selectedSuperParent, setSelectedSuperParent] = useState("");

    const [positionData, setPositionData] = useState([])

    const [crudTableData, setCrudTableData] = useState({
        table_name: "",
        title: "",
        group_id: "",
        position: "",
        add_field: 0,
        edit_field: 0,
        delete_field: 0,
        status: "",
        slug: "",
        is_public: ""
    });

    const [visible, setVisible] = useState(false)

    const sidebarDataFromZustandStore = useStore((state) => state.sidebarDataFromZustandStore);

    const updateCrudfromZustand = useStore((state) => state.updateCrudfromZustand)


    const updateSidebarDatafromZustand = useStore((state) => state.updateSidebarDatafromZustand);


    // Fetch groups when component mounts
    useEffect(() => {
        const fetchGroupData = async () => {
            setIsGroupsLoading(true);
            await fetchGroupList(setGroups);
            setIsGroupsLoading(false);
        };
        fetchGroupData();
    }, []);



    // fetch crud and crudField  to auto fill crud data and selected table fields from crudField
    useEffect(() => {
        const fetchData = async () => {
            await fetchCrudAndCrudField(crud_id, setCrudTableData, setCrudFieldData, setIsTableVisible, setTableFields, setCrudDataVisibility, setSelectedSuperParent)
        }
        fetchData()
    }, [])

    const handleCrudTable = (e) => {
        const { name, value } = e.target;

        setCrudTableData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
        if (name === "table_name") {
            setSelectedTableName(value);
        }
    };


    // fetch crudTable field
    useEffect(() => {
        if (!selectedTableName) return;

        const fetchData = async () => {
            const { crudFieldData, tableFields, isTableVisible } =
                await fetchTableFields(selectedTableName);

            setCrudFieldData(crudFieldData);
            setTableFields(tableFields);
            setIsTableVisible(isTableVisible);
        };

        fetchData();
    }, [selectedTableName]);

    const [tableNameForDynamicSelect, setTableNameForDynamicSelect] = useState();
    const [dynamicSelectFields, setDynamicSelectFields] = useState([]);

    // fetchFieldsForDynamicSelect
    useEffect(() => {
        if (!tableNameForDynamicSelect) return;
        const fetchData = async () => {
            const { tableFields } = await fetchFieldsForDynamicSelect(
                tableNameForDynamicSelect
            );


            setDynamicSelectFields(tableFields);
            // setTableFields(tableFields);
        };

        fetchData();
    }, [tableNameForDynamicSelect]);


    useEffect(() => {
        fetchtableList(setTableName);
    }, []);

    const isOptionDisabled = (value) => {
        return Object.values(selectCrudFieldPosition).includes(value);
    };


    // insert crud and crudField
    const handleCrudEditSubmit = async (e, id) => {
        e.preventDefault();

        // disable update button once clicked
        setButtonDisabled(true)

        const crudGroupError = checkCrudGroupError(crudTableData)
        console.log("crudGroupError")
        console.log(crudGroupError)


        if (crudGroupError) {

            setButtonDisabled(false)
            Swal.fire({
                icon: "warning",
                title: "Group not Selected",
                text: "Group is required!",
            });
            return;
        }


        // Iterate over each field and check conditions for empty label and active status also atleast one status or visible should checked
        const { labelErrorData, statusError, visibilityError } = checkLabelAndStatus(crudFieldData);


        // If there are label errors, show them and prevent submission
        if (labelErrorData.length !== 0) {
            setButtonDisabled(false)


            setLabelError(labelErrorData); // Update state to show errors

            Swal.fire({
                icon: "warning",
                title: "Label Error",
                text: "Label is required!",
            });

            return; // Stop further execution if there are label errors
        }


        if (statusError != "") {
            setButtonDisabled(false)

            Swal.fire({
                icon: "warning",
                title: "Status Error",
                text: statusError
            });

            return; // Stop further execution if there are label errors
        }

        if (visibilityError != "") {
            setButtonDisabled(false)


            Swal.fire({
                icon: "warning",
                title: "Status Error",
                text: visibilityError
            });

            return; // Stop further execution if there are label errors
        }

        // check Value entered in validation input field.
        const validationInputBoxError = validateInputFieldValueForEditCrud(crudFieldData);

        // if got error set in state and return to not submit form
        if (Array.isArray(validationInputBoxError) && validationInputBoxError != 0) {

            setButtonDisabled(false)



            setValidationInputError(validationInputBoxError)
            Swal.fire({
                icon: "warning",
                title: "Validation Input Field Error",
                text: "Enter value in Validation Input Box"
            })

            return;
        } else {
            setValidationInputError([])
        }

        // code to delete crud and crudField Data
        try {
            const obj = qs.stringify({
                id: id,
                key: 12321,
                table: 'tbl_crud'
            });

            const deleteCrud_CrudFieldResponse = await axios.post(
                "https://admissioninnepal.in/api/delete-group",
                obj,
                {
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                }
            );


            // Check if the response status is 200 (HTTP OK)
            if (deleteCrud_CrudFieldResponse.status === 200) {
                const newObj = {
                    ...crudTableData,
                    key: "12321",
                };

                try {
                    const response = await fetch(
                        "https://admissioninnepal.in/api/add-edit-crud",
                        {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/x-www-form-urlencoded",
                            },
                            body: qs.stringify(newObj),
                        }
                    );

                    if (response.ok) {
                        let data = await response.json();
                        data = data.data;

                        const crudId = data.id;
                        // setCrudFieldData(crudId);

                        const updatedCrudFieldData = crudFieldData.map((crudField) => ({
                            ...crudField,
                            crud_id: crudId,
                            position: crudField.position == 0 ? tableFields.length : crudField.position

                        }));


                        const crudFieldWithPattern = insertPatternInCrudFieldEdit(
                            updatedCrudFieldData,
                        );


                        // setCrudFieldData(crudFieldWithPattern);

                        try {
                            const response1 = await fetch(
                                "https://admissioninnepal.in/api/add-edit-crudfield",
                                {
                                    method: "POST",
                                    headers: {
                                        "Content-Type": "application/x-www-form-urlencoded",
                                    },
                                    body: JSON.stringify(crudFieldWithPattern),
                                }
                            );

                            if (response1.ok) {

                                setButtonDisabled(false)

                                // fetch updated sidebardata and update in zustand store
                                const sidebardata = await fetchSidebarData();

                                updateSidebarDatafromZustand(sidebardata)

                                const crudtable = await fetchCrudTableData();
                                await updateCrudfromZustand(crudtable)

                                Swal.fire({
                                    icon: 'success',
                                    title: 'Success!',
                                    text: `Api -- http://localhost:3000/global-form/${data.slug}`,
                                }).then(() => {
                                    navigate(`/edit-crud/${crudId}`);
                                });
                            } else {
                                Swal.fire({
                                    icon: 'error',
                                    title: 'Oops...',
                                    text: 'Failed to submit data!',
                                });
                            }
                        } catch (error) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: 'An error occurred while submitting the data!',
                            });
                        }
                    }

                    else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Failed to add/edit data!',
                        });
                    }
                }
                catch (error) {
                    console.error(error);
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'An error occurred during the request!',
                    });
                }

            }
        } catch (err) {

        }
    };


    const handleCrudFieldColSizeChange = (e, fieldId) => {
        const value = e.target.value;
        setCrudFieldData((prevData) =>
            prevData.map((field) =>
                field.id === fieldId ? { ...field, col_size: value } : field
            )
        );
    };


    const handleCrudFieldPosition = (e, id) => {
        const value = e.target.value;

        setCrudFieldData((prevData) =>
            prevData.map((field) =>
                field.id === id ? { ...field, position: value } : field
            )
        );
    };


    const handleInputCrudChange = (id, e) => {

        const { value } = e.target;
        setCrudFieldData((prevData) =>
            prevData.map((field) =>
                field.id == id ? { ...field, field_label: value } : field
            )
        );
    };

    const [selectedFieldId, setSelectedFieldId] = useState([]);


    const handleCrudFieldInputChange = (e, fieldId) => {
        const selectedType = e.target.value;

        console.log("selectedType")
        console.log(selectedType)

        //set setected input type
        setSelectedInputType(selectedType);

        setSelectedFieldId(fieldId); // Set selected field ID

        // Update the corresponding field in crudFieldData
        // setCrudFieldData((prevData) =>
        //     prevData.map((field) =>
        //         field
        //         field.id === fieldId ? { ...field, field_type: selectedType } : field
        //     )
        // );


        setCrudFieldData((prevData) =>
            prevData.map((field) => {
                if (field.id === fieldId) {
                    // Create a new object with the specified fields preserved
                    return {
                        ...field,
                        field_type: selectedType,  // Keep field_type as it is

                        ...Object.keys(field).reduce((acc, key) => {
                            // Set all values to empty except for the allowed fields
                            if (['crud_id', 'id', 'field_name', 'is_visible', 'status', 'field_label', 'position', 'col_size'].includes(key)) {
                                acc[key] = field[key];  // Keep original value
                            }
                            else if (key == 'field_type') {
                                acc[key] = selectedType
                            }
                            else {
                                acc[key] = '';  // Set to empty
                            }
                            return acc;
                        }, {})
                    };
                }
                return field;
            })
        )

        // get key value from field and put empty in all value of field expect, field_type,crud_id and id

        // Update validation options for the selected input type
        setValidationOptions((prev) => ({
            ...prev,
            [fieldId]: options[selectedType] || [],
        }));

        // Reset selected validation option for the changed field
        setSelectedValidationOption((prev) => ({
            ...prev,
            [fieldId]: "",
        }));
    };





    const fetchTableFieldsOnly = async (tableName) => {

        let data = []
        try {
            const response = await fetch(
                `https://admissioninnepal.in/api/get-column/${tableName.replace("tbl_", "")}`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                    body: qs.stringify({ key: 12321 }), // Use env variables for sensitive data
                }
            );

            if (!response.ok) {
                throw new Error(`Error ${response.status}: ${response.statusText}`);
            }

            data = await response.json();

        }
        catch (err) {
            console.error("Failed to fetch table fields:", err);
        }
        return { data }
    }

    const FetchTableFields = async (tableNameForFields) => {

        const tableFields = await fetchTableFieldsOnly(tableNameForFields);

        // Check if tableFields.data exists and is an array
        if (tableFields.data && Array.isArray(tableFields.data)) {
            const obj = {
                [tableNameForFields]: tableFields.data
            };

            // Append the new tableNameForFields and its data to the existing state
            setTableNameAndFields((prevState) => ({ ...prevState, ...obj }));

        } else {
            console.error("Invalid data structure:", tableFields);

        }
    };

    // when page render Check if dynamic_table has a value then fetch all columns to set true/false state

    // Effect to fetch columns for all dynamic tables
    useEffect(() => {
        const fetchAllDynamicTables = () => {
            const dynamicTable = [];

            // Collect all dynamic_table values
            crudFieldData.forEach(async (data) => {
                if (data.dynamic_table && !tableNameAndFields.hasOwnProperty(data.dynamic_table)) {
                    await FetchTableFields(data.dynamic_table);

                }
            });
        };

        // Call the asynchronous function
        fetchAllDynamicTables();
    }, [crudDataVisibility]);


    // fetch table field for checkbox and select and chained
    useEffect(() => {
        // Check if tableNameForFields is valid and if it's already a key in tableNameAndFields
        if (!tableNameForFields || tableNameAndFields.hasOwnProperty(tableNameForFields)) return;

        // Fetch table fields if tableNameForFields is not in tableNameAndFields
        FetchTableFields(tableNameForFields);

    }, [tableNameForFields]);


    useEffect(() => {


    }, [tableNameAndFields])


    useEffect(() => {

        crudFieldData.forEach((field) => {
            const selectedType = field.field_type;

            // Initialize validation options for each field
            setValidationOptions((prev) => ({
                ...prev,
                [field.id]: options[selectedType] || [],
            }));

            // Set default selected validation option if it exists
            setSelectedValidationOption((prev) => ({
                ...prev,
                [field.id]: field.selectedValidation || "", // Adjust as needed if there's a default validation value
            }));

        });
    }, [crudDataVisibility]); // Empty dependency array ensures this runs only once on mount



    useEffect(() => {
        let updatedPositionData = []; // Start with the current positionData
        var superParentId = null
        var children = []

        // Iterate over groups to find the super parent and its children
        groups.forEach((data) => {

            if (data.id == crudTableData.group_id) {
                // Check if the group_id is a child then store superParent in superParentId
                if (data.parent != '0') {
                    // Find the super parent by looking for its parent id in the groups array
                    superParentId = data.parent;
                }
                // if superParent contain data then group_id contains child id so find all child via group_id

                // Find all children that share the same parent (super parent)
                if (superParentId !== null) {

                    children = groups.filter((currentElement) => currentElement.parent == superParentId);
                }
                else {
                    children = groups.filter((currentElement) => currentElement.parent == crudTableData.group_id);

                }
                // If children are found, append their IDs to positionData
                if (children.length > 0) {

                    updatedPositionData = [...updatedPositionData, ...children]; // Append new child ids
                    setPositionData(updatedPositionData)

                }

            }
        });

    }, [crudDataVisibility])

    // groups.find((currentElement) => currentElement.parent == superParentId); can return multiple object store them and append then in setPositionData array


    useEffect(() => {
        console.log("crudFieldData")
        console.log(crudFieldData)
    }, [crudFieldData])

    return (
        <>
            <Header />
            <Sidebar />

            <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
                <div className="d-flex flex-column flex-column-fluid">


                    {/* breacrumb buttons code */}
                    <BreadCrumb />

                    <div id="kt_app_content" className="app-content flex-column-fluid">
                        <div
                            id="kt_app_content_container"
                            className="app-container container-xxl"
                        >
                            <div className="card">
                                <div className="card-body p-0">
                                    <div className="card-px my-10" id="c">
                                        <div className="d-flex flex-column flex-lg-row mb-17">
                                            <div className="flex-lg-row-fluid me-0 me-lg-20">


                                                {/* Header code below */}
                                                <form className="form mb-5">
                                                    <div className="form-group">
                                                        <label htmlFor="table_name">Table Name</label>
                                                        <select
                                                            id="table_name"
                                                            name="table_name"
                                                            value={crudTableData.table_name}
                                                            onChange={(e) => handleCrudTable(e)}
                                                            className="form-select mt-3"
                                                        >
                                                            <option value="">Select Table</option>


                                                            {tableName.map((name) => {
                                                                const displayName = name
                                                                    .replace(/^tbl_/, "") // Remove the "tbl_" prefix
                                                                    .replace(/_/g, " ") // Replace underscores with spaces
                                                                    .replace(/\b\w/g, (char) =>
                                                                        char.toUpperCase()
                                                                    ); // Capitalize first letter of each word
                                                                return (
                                                                    <option key={name} value={name}>
                                                                        {displayName}
                                                                    </option>
                                                                );
                                                            })}
                                                        </select>
                                                    </div>

                                                    <div className="form-group mt-5">

                                                        <label htmlFor="title">
                                                            Title{" "}
                                                            <span className="required-indicator">*</span>
                                                        </label>

                                                        <input
                                                            type="text"
                                                            required
                                                            name="title"
                                                            className="form-control mt-3"
                                                            placeholder="Title"
                                                            id="title"
                                                            value={crudTableData.title}
                                                            onChange={(e) => handleCrudTable(e)}
                                                        />
                                                    </div>

                                                    <div className="form-group mb-3">

                                                        <label>Group</label>
                                                        <select
                                                            name="group"
                                                            value={fetchGroupIdByChildId(groups, crudTableData.group_id, selectedSuperParent)}

                                                            // value={crudTableData.group_id  fetchGroupIdByChildId() }

                                                            onChange={(e) =>
                                                                handleGroupChange(e, setCrudTableData, groups, setPositionData, setSelectedSuperParent)
                                                            }
                                                            className="form-select mt-3"
                                                        >
                                                            <option value="">Select Group</option>

                                                            {groups
                                                                .filter((group) => group.parent === "0")
                                                                .map((group) => (
                                                                    <option key={group.id} value={group.id}>
                                                                        {group.group_name}
                                                                    </option>
                                                                ))
                                                            }

                                                        </select>
                                                    </div>

                                                    <div className="form-group mb-3">
                                                        <label>Position</label>


                                                        <select
                                                            name="position"
                                                            value={crudTableData.group_id}


                                                            onChange={(e) => handlePositionInputChange(e, setCrudTableData)}
                                                            className="form-select mt-3"
                                                        >
                                                            <option value="">Select Position</option>
                                                            {Array.isArray(positionData) &&
                                                                positionData.map((data) => (
                                                                    <option key={data.id} value={data.id}>
                                                                        {data.group_name}
                                                                    </option>
                                                                ))
                                                            }
                                                        </select>
                                                    </div>




                                                    <div className="mt-5">
                                                        <h4>Action</h4>
                                                        <div className="d-flex justify-content-between  mt-5">
                                                            <div className="form-check">
                                                                <div className="checkbox checkbox-success">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="checkbox"
                                                                        checked={crudTableData.add_field == 1 ? 1 : 0} // Convert integer to boolean
                                                                        id="add_field"
                                                                        onChange={(e) =>
                                                                            handleCheckboxChange(e, setCrudTableData)
                                                                        }
                                                                    />
                                                                    <label
                                                                        className="form-check-label"
                                                                        htmlFor="add_field"
                                                                    >
                                                                        Add
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className="form-check">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    checked={crudTableData.edit_field == 1 ? 1 : 0} // Convert integer to boolean
                                                                    id="edit_field"
                                                                    onChange={(e) =>
                                                                        handleCheckboxChange(e, setCrudTableData)
                                                                    }
                                                                />
                                                                <label
                                                                    className="form-check-label"
                                                                    htmlFor="edit_field"
                                                                >
                                                                    Edit
                                                                </label>
                                                            </div>


                                                            <div className="form-check">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    checked={crudTableData.delete_field == 1 ? 1 : 0} // Convert integer to boolean
                                                                    id="delete_field"
                                                                    onChange={(e) =>
                                                                        handleCheckboxChange(e, setCrudTableData)
                                                                    }
                                                                />
                                                                <label
                                                                    className="form-check-label"
                                                                    htmlFor="delete_field"
                                                                >
                                                                    Delete
                                                                </label>
                                                            </div>


                                                            <div className="form-check">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    checked={crudTableData.is_public == 1 ? 1 : 0} // Convert integer to boolean
                                                                    id="is_public"
                                                                    onChange={(e) =>
                                                                        handleCheckboxChange(e, setCrudTableData)
                                                                    }
                                                                />
                                                                <label
                                                                    className="form-check-label"
                                                                    htmlFor="is_public"
                                                                >
                                                                    Public
                                                                </label>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </form>


                                                {/* crudField data below */}

                                                {isTableVisible && (
                                                    <div className="table-responsive">
                                                        <table
                                                            className="table table-rounded table-row-bordered border gy-7 gs-7 mt-5"
                                                            id="ta"
                                                        >
                                                            <thead>
                                                                <tr
                                                                    className="fw-bold fs-6 text-gray-800 border-bottom border-gray-200"
                                                                    id="thead"
                                                                >
                                                                    <th>Field</th>
                                                                    <th>Label</th>
                                                                    <th>Position</th>
                                                                    <th>Status</th>
                                                                    <th>Visible</th>

                                                                    <th>Input Type</th>
                                                                    <th>Validation</th>
                                                                    <th>Col Size</th>
                                                                </tr>
                                                            </thead>

                                                            {/* taking input in crudFeild */}

                                                            <tbody>
                                                                {crudFieldData.map((crudField, index) => (
                                                                    <tr key={index}>
                                                                        <td className="fw-bold">
                                                                            {crudField.field_name}
                                                                        </td>

                                                                        <td>
                                                                            <div className="form-group">
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control w-50"
                                                                                    value={crudField.field_label || ""}
                                                                                    onChange={(e) =>
                                                                                        handleInputCrudChange(
                                                                                            crudField.id,
                                                                                            e
                                                                                        )
                                                                                    }
                                                                                    style={{
                                                                                        borderColor:
                                                                                            (labelError.includes(crudField.id) || (crudField.status == 1 && crudField.field_label == ""))
                                                                                                ? "red"
                                                                                                : "",
                                                                                    }}
                                                                                />

                                                                                {(labelError.includes(crudField.id) || (crudField.status == 1 && crudField.field_label == "")) && (
                                                                                    <div className="text-danger">
                                                                                        Field is required
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        </td>

                                                                        {/* Position select box */}
                                                                        <td>
                                                                            <div className="dropdown">
                                                                                <select
                                                                                    className="form-select"
                                                                                    value={crudField.position}
                                                                                    onChange={(e) =>
                                                                                        handleCrudFieldPosition(
                                                                                            e,
                                                                                            crudField.id
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    <option value="">
                                                                                        Select Position
                                                                                    </option>
                                                                                    {[
                                                                                        ...Array(tableFields.length).keys(),
                                                                                    ].map((num) => (
                                                                                        <option
                                                                                            key={num + 1}
                                                                                            value={num + 1}
                                                                                            disabled={isOptionDisabled(
                                                                                                (num + 1).toString()
                                                                                            )}
                                                                                        >
                                                                                            {num + 1}
                                                                                        </option>
                                                                                    ))}
                                                                                </select>
                                                                            </div>
                                                                        </td>

                                                                        {/* Status checkbox code */}
                                                                        <td>
                                                                            <div className="d-flex flex-column">
                                                                                <div>
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        id={`status-${crudField.id}`}
                                                                                        checked={crudField.status == 1 ? 1 : 0}

                                                                                        onChange={(e) =>
                                                                                            crudFieldCheckbox(
                                                                                                e,
                                                                                                crudField.id,
                                                                                                setCrudFieldData
                                                                                            )
                                                                                        }
                                                                                        className="form-check-input"
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </td>

                                                                        <td>
                                                                            <div className="d-flex flex-column">
                                                                                <div>

                                                                                    <input
                                                                                        type="checkbox"
                                                                                        id={`visible-${crudField.id}`}
                                                                                        checked={crudField.is_visible == 1 ? 1 : 0}

                                                                                        onChange={(e) =>
                                                                                            crudFieldVisible(
                                                                                                e,
                                                                                                crudField.id,
                                                                                                setCrudFieldData
                                                                                            )
                                                                                        }
                                                                                        className="form-check-input"
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </td>

                                                                        {/* Input type selection in select option */}
                                                                        <td>
                                                                            <div className="dropdown">
                                                                                <select
                                                                                    value={crudField.field_type || ""}
                                                                                    className="form-select"
                                                                                    onChange={(e) =>
                                                                                        handleCrudFieldInputChange(
                                                                                            e,
                                                                                            crudField.id
                                                                                        )
                                                                                    }

                                                                                >
                                                                                    <option value="">
                                                                                        Select Input
                                                                                    </option>
                                                                                    {Object.keys(options).map((type) => (
                                                                                        <option key={type} value={type}>
                                                                                            {type.charAt(0).toUpperCase() +
                                                                                                type.slice(1)}
                                                                                        </option>
                                                                                    ))}

                                                                                </select>
                                                                            </div>

                                                                            {/* dynamic table  from table reference  */}
                                                                            <div>
                                                                                {
                                                                                    (crudField.field_type === "dynamic_select" || crudField.field_type === "checkboxes" || crudField.field_type === "select_multiple" || crudField.field_type === 'chained') &&
                                                                                    (
                                                                                        <div>

                                                                                            <div className="form-group">

                                                                                                <select
                                                                                                    id={`table_name_${crudField.id}`}
                                                                                                    name="dynamic_table"
                                                                                                    value={crudField.dynamic_table || ""}
                                                                                                    onChange={(e) =>
                                                                                                        handleCheckboxesDynamicData(
                                                                                                            e,
                                                                                                            crudField.id,
                                                                                                            setCrudFieldData,
                                                                                                            setTableNameForFields
                                                                                                        )
                                                                                                    }
                                                                                                    className="form-select mt-3"
                                                                                                >
                                                                                                    <option value="">
                                                                                                        Select Table
                                                                                                    </option>
                                                                                                    {tableName.map(
                                                                                                        (name, index) => (
                                                                                                            <option
                                                                                                                key={index}
                                                                                                                value={name}
                                                                                                            >
                                                                                                                {name
                                                                                                                    .replace(/_/g, " ")
                                                                                                                    .replace(
                                                                                                                        /\b\w/g,
                                                                                                                        (char) =>
                                                                                                                            char.toUpperCase()
                                                                                                                    )}
                                                                                                            </option>
                                                                                                        )
                                                                                                    )}
                                                                                                </select>
                                                                                            </div>

                                                                                            <div>

                                                                                                <select
                                                                                                    name="dynamic_label"

                                                                                                    value={crudField.dynamic_label || ""}

                                                                                                    onChange={(e) =>
                                                                                                        handleCheckboxesDynamicData(
                                                                                                            e,
                                                                                                            crudField.id,
                                                                                                            setCrudFieldData,
                                                                                                            setTableNameForFields
                                                                                                        )
                                                                                                    }
                                                                                                    className="form-select mt-3"
                                                                                                >
                                                                                                    <option value="">
                                                                                                        Select Label Ref
                                                                                                    </option>
                                                                                                    {tableNameAndFields[crudField.dynamic_table] && tableNameAndFields[crudField.dynamic_table].map(
                                                                                                        (tableField, index) => (
                                                                                                            <option
                                                                                                                key={index}
                                                                                                                value={
                                                                                                                    tableField.COLUMN_NAME
                                                                                                                }
                                                                                                            >

                                                                                                                {(tableField.COLUMN_NAME).replace("_", " ")}

                                                                                                            </option>
                                                                                                        )
                                                                                                    )}
                                                                                                </select>
                                                                                            </div>


                                                                                            <div>

                                                                                                <select
                                                                                                    name="dynamic_field"
                                                                                                    value={crudField.dynamic_field || ""}

                                                                                                    onChange={(e) =>
                                                                                                        handleCheckboxesDynamicData(
                                                                                                            e,
                                                                                                            crudField.id,
                                                                                                            setCrudFieldData,
                                                                                                            setTableNameForFields
                                                                                                        )
                                                                                                    }
                                                                                                    className="form-select mt-3"
                                                                                                >
                                                                                                    <option value="">
                                                                                                        Select value Ref
                                                                                                    </option>
                                                                                                    {/* {  } */}

                                                                                                    {tableNameAndFields[crudField.dynamic_table] && tableNameAndFields[crudField.dynamic_table].map(
                                                                                                        (tableField, index) => (
                                                                                                            <option
                                                                                                                key={index}
                                                                                                                value={
                                                                                                                    tableField.COLUMN_NAME
                                                                                                                }
                                                                                                            >
                                                                                                                {(tableField.COLUMN_NAME).replace("_", " ")}
                                                                                                            </option>
                                                                                                        )
                                                                                                    )}


                                                                                                </select>
                                                                                            </div>


                                                                                            {/* for where clouse for chained and other */}
                                                                                            {crudField.field_type === 'chained' && (

                                                                                                <>

                                                                                                    <label htmlFor="">Dependency Condition</label>

                                                                                                    <div>

                                                                                                        <select
                                                                                                            id={`${crudField.id}`}
                                                                                                            name="dependency"
                                                                                                            value={
                                                                                                                crudField["dependency"]
                                                                                                            }
                                                                                                            onChange={(e) =>
                                                                                                                handleCheckboxesDynamicData(
                                                                                                                    e,
                                                                                                                    crudField.id,
                                                                                                                    setCrudFieldData,
                                                                                                                    setTableNameForFields
                                                                                                                )
                                                                                                            }
                                                                                                            className="form-select mt-3"
                                                                                                        >
                                                                                                            <option value="">
                                                                                                                Select Condition Column
                                                                                                            </option>
                                                                                                            {tableNameAndFields[crudField.dynamic_table] && tableNameAndFields[crudField.dynamic_table].map(
                                                                                                                (tableField, index) => (
                                                                                                                    <option
                                                                                                                        key={index}
                                                                                                                        value={
                                                                                                                            tableField.COLUMN_NAME
                                                                                                                        }
                                                                                                                    >
                                                                                                                        {(tableField.COLUMN_NAME).replace("_", " ")}
                                                                                                                    </option>
                                                                                                                )
                                                                                                            )}
                                                                                                        </select>
                                                                                                    </div>
                                                                                                    =
                                                                                                    <div>
                                                                                                        <select
                                                                                                            name="dynamic_condition"
                                                                                                            value={
                                                                                                                crudField[
                                                                                                                "dynamic_condition"
                                                                                                                ]
                                                                                                            }
                                                                                                            onChange={(e) =>
                                                                                                                handleCheckboxesDynamicData(
                                                                                                                    e,
                                                                                                                    crudField.id,
                                                                                                                    setCrudFieldData,
                                                                                                                    setTableNameForFields
                                                                                                                )
                                                                                                            }
                                                                                                            className="form-select mt-3"
                                                                                                        >
                                                                                                            <option value="">
                                                                                                                Select Condition Column
                                                                                                            </option>
                                                                                                            {crudFieldData.map((crudField, index) => (
                                                                                                                <option
                                                                                                                    key={index}
                                                                                                                    value={
                                                                                                                        crudField.field_name
                                                                                                                    }
                                                                                                                >
                                                                                                                    {
                                                                                                                        (crudField.field_name).replace("_", " ")
                                                                                                                    }
                                                                                                                </option>
                                                                                                            )
                                                                                                            )}

                                                                                                        </select>
                                                                                                    </div>

                                                                                                </>
                                                                                            )}
                                                                                        </div>
                                                                                    )}

                                                                            </div>



                                                                            <div>
                                                                                {crudField && crudField.field_type === "range" && (
                                                                                    <>
                                                                                        <div className="d-flex align-items-center gap-2 mb-2">
                                                                                            <label htmlFor={`min-${crudField.id}`}>Min</label>
                                                                                            <input
                                                                                                type="number"
                                                                                                className="form-control"
                                                                                                value={crudFieldData.find(data => data.id === crudField.id)?.pattern?.min || ""}
                                                                                                onChange={(e) =>
                                                                                                    handleRangeChangeCrudEdit(
                                                                                                        crudField.id,
                                                                                                        "min",
                                                                                                        e,
                                                                                                        setCrudFieldData
                                                                                                    )
                                                                                                }
                                                                                                id={`min-${crudField.id}`}
                                                                                            />
                                                                                        </div>

                                                                                        <div className="d-flex align-items-center gap-2 mb-2">
                                                                                            <label htmlFor={`max-${crudField.id}`}>Max</label>
                                                                                            <input
                                                                                                type="number"
                                                                                                className="form-control"
                                                                                                value={crudFieldData.find(data => data.id === crudField.id)?.pattern?.max || ""}
                                                                                                onChange={(e) =>
                                                                                                    handleRangeChangeCrudEdit(
                                                                                                        crudField.id,
                                                                                                        "max",
                                                                                                        e,
                                                                                                        setCrudFieldData
                                                                                                    )
                                                                                                }
                                                                                                id={`max-${crudField.id}`}
                                                                                            />
                                                                                        </div>
                                                                                    </>
                                                                                )}
                                                                            </div>


                                                                            {/* for custom checkbox and custom_option  and custom select*/}
                                                                            <div>

                                                                                {(crudField.field_type == "custom_option" || crudField.field_type == "custom_checkbox" || crudField.field_type === "custom_select_multiple" || crudField.field_type === "custom_select") && (
                                                                                    <div className="custom-option-container">
                                                                                        {(
                                                                                            typeof crudField.options === "string" && crudField.options.trim() !== ""
                                                                                                ? JSON.parse(crudField.options)
                                                                                                : Array.isArray(crudField.options)
                                                                                                    ? crudField.options
                                                                                                    : []
                                                                                        ).map((option, optionIndex) => (
                                                                                            <div className="custom-option" key={optionIndex}>
                                                                                                <div className="d-flex align-items-center gap-2 mb-2">


                                                                                                    <label htmlFor={`label-${crudField.id}-${optionIndex}`}>
                                                                                                        Label
                                                                                                    </label>

                                                                                                    <input
                                                                                                        type="text"
                                                                                                        className="form-control"
                                                                                                        value={option.label}
                                                                                                        onChange={(e) => {
                                                                                                            handleCustomOptionChange(
                                                                                                                crudField.id,
                                                                                                                optionIndex,
                                                                                                                "label",
                                                                                                                e,
                                                                                                                setCrudFieldData,
                                                                                                                crudField
                                                                                                            );
                                                                                                        }}


                                                                                                        id={`label-${crudField.id}-${optionIndex}`}
                                                                                                    />
                                                                                                </div>

                                                                                                <div className="d-flex align-items-center gap-2 mb-2">
                                                                                                    <label htmlFor={`value-${crudField.id}-${optionIndex}`}>
                                                                                                        Value
                                                                                                    </label>
                                                                                                    <input
                                                                                                        type="text"
                                                                                                        className="form-control"
                                                                                                        value={option.value}

                                                                                                        onChange={(e) => {
                                                                                                            handleCustomOptionChange(
                                                                                                                crudField.id,
                                                                                                                optionIndex,
                                                                                                                "value",
                                                                                                                e,
                                                                                                                setCrudFieldData,
                                                                                                                crudField
                                                                                                            );
                                                                                                        }}


                                                                                                        id={`value-${crudField.id}-${optionIndex}`}
                                                                                                    />
                                                                                                </div>


                                                                                                <div className="d-flex align-items-center gap-2 mb-2">
                                                                                                    <label htmlFor={`default-${crudField.id}-${optionIndex}`}>
                                                                                                        Default Option
                                                                                                    </label>
                                                                                                    <input
                                                                                                        type="checkbox"
                                                                                                        className="form-check-input"
                                                                                                        checked={option.defaultValue}

                                                                                                        onChange={(e) => {
                                                                                                            handleCustomOptionChange(
                                                                                                                crudField.id,
                                                                                                                optionIndex,
                                                                                                                "defaultValue",
                                                                                                                e,
                                                                                                                setCrudFieldData,
                                                                                                                crudField
                                                                                                            );
                                                                                                        }}


                                                                                                        id={`default-${crudField.id}-${optionIndex}`}
                                                                                                    />
                                                                                                </div>


                                                                                                {(crudField.field_type === "custom_option" || crudField.field_type === "custom_checkbox"
                                                                                                    || crudField.field_type === "custom_select" || crudField.field_type === "custom_select_multiple") && (
                                                                                                        <button
                                                                                                            className="btn btn-danger mt-2 px-3 py-2"
                                                                                                            onClick={() =>
                                                                                                                handleDelete(
                                                                                                                    crudField.id,
                                                                                                                    optionIndex,

                                                                                                                    setCrudFieldData
                                                                                                                )
                                                                                                                // fieldId, optionIndex, setCustomOptions, setCustomCheckbox, selectedInputType
                                                                                                            }
                                                                                                        >
                                                                                                            Delete
                                                                                                        </button>
                                                                                                    )}

                                                                                            </div>
                                                                                        ))}

                                                                                        {(crudField.field_type === "custom_option" || crudField.field_type === "custom_checkbox"
                                                                                            || crudField.field_type === "custom_select" || crudField.field_type === "custom_select_multiple") && (
                                                                                                <button
                                                                                                    className="btn btn-primary mt-2 px-3 py-2"
                                                                                                    onClick={() =>
                                                                                                        handleAddMore(
                                                                                                            crudField.id,

                                                                                                            setCrudFieldData,
                                                                                                            crudField
                                                                                                        )
                                                                                                    }
                                                                                                >
                                                                                                    Add more
                                                                                                </button>
                                                                                            )}
                                                                                    </div>
                                                                                )}
                                                                            </div>

                                                                        </td>


                                                                        {/* select types of validation code below */}
                                                                        <td>
                                                                            <select
                                                                                value={
                                                                                    selectedValidationOption[crudField.id] || ""
                                                                                }
                                                                                onChange={(e) =>
                                                                                    handleValidationChange(
                                                                                        e,
                                                                                        crudField.id,

                                                                                        setCrudFieldData
                                                                                    )
                                                                                }
                                                                                className="form-select"
                                                                            >
                                                                                <option value="">
                                                                                    Select Validation
                                                                                </option>
                                                                                {validationOptions[crudField.id]?.map(
                                                                                    (option) => (
                                                                                        <option
                                                                                            key={option.value}
                                                                                            value={option.value}
                                                                                        >
                                                                                            {option.label}
                                                                                        </option>
                                                                                    )
                                                                                )}
                                                                            </select>


                                                                            {/* Display Selected Validation with Input Tag in Dropdown */}
                                                                            {Object.entries(crudField.pattern || {}).length > 0 && (


                                                                                <div>
                                                                                    <ul className="list-unstyled">
                                                                                        {Object.entries(crudField.pattern || {}).map(([key, value]) => {

                                                                                            console.log("key")
                                                                                            console.log(key)

                                                                                            const option = validationOptions[crudField.id]?.find((opt) => opt.value === key);

                                                                                            return (
                                                                                                <div key={key}>

                                                                                                    <li className="d-flex align-items-center mb-3 mt-5">
                                                                                                        <span>{option?.label}:</span>

                                                                                                        {/* for input type matches for password */}
                                                                                                        {key === "matches" ? (
                                                                                                            <select
                                                                                                                value={value}
                                                                                                                onChange={(e) =>
                                                                                                                    handleValueInputChange(e, crudField.id, key, setCrudFieldData)
                                                                                                                }
                                                                                                                className="form-control me-3"
                                                                                                            >
                                                                                                                <option value="">Select an option</option>
                                                                                                                {crudFieldData.map((crudField, index) => (
                                                                                                                    <option key={index} value={crudField.field_name}>
                                                                                                                        {crudField.field_name}
                                                                                                                    </option>
                                                                                                                ))}
                                                                                                            </select>
                                                                                                        ) : (
                                                                                                            // input tag to enter validation value
                                                                                                            option?.inputIsVisible && (
                                                                                                                <div className="d-flex">
                                                                                                                    <input
                                                                                                                        type="text"
                                                                                                                        value={value}
                                                                                                                        onChange={(e) =>
                                                                                                                            handleValueInputChange(e, crudField.id, key, setCrudFieldData)
                                                                                                                        }
                                                                                                                        className="form-control me-3"
                                                                                                                        placeholder="Enter value"


                                                                                                                        style={{
                                                                                                                            borderColor:
                                                                                                                                (validationInputError.includes(crudField.id))
                                                                                                                                    ? "red"
                                                                                                                                    : "",
                                                                                                                        }}

                                                                                                                    />

                                                                                                                </div>
                                                                                                            )
                                                                                                        )}

                                                                                                        {/* delete button */}


                                                                                                        <button
                                                                                                            onClick={(e) =>
                                                                                                                handleDeleteValidationEdit(e, crudField.id, key, setCrudFieldData)
                                                                                                            }
                                                                                                            className="btn btn-danger d-flex align-items-center justify-content-center"
                                                                                                            id="butt"
                                                                                                        >
                                                                                                            <i className="bi bi-trash"></i>
                                                                                                        </button>

                                                                                                    </li>



                                                                                                </div>
                                                                                            );
                                                                                        })}
                                                                                    </ul>


                                                                                    {(validationInputError.includes(crudField.id)) && (
                                                                                        <p className="text-danger">Input Field Required</p>
                                                                                    )}
                                                                                </div>
                                                                            )}
                                                                        </td>


                                                                        <td>
                                                                            <select
                                                                                value={crudField.col_size}
                                                                                onChange={(e) =>
                                                                                    handleCrudFieldColSizeChange(
                                                                                        e,
                                                                                        crudField.id
                                                                                    )
                                                                                }
                                                                                className="form-select"
                                                                            >
                                                                                <option value="">
                                                                                    Select Column Size
                                                                                </option>
                                                                                <option value="col-lg-1 col-md-1 col-sm-3 col-6">
                                                                                    col-1
                                                                                </option>
                                                                                <option value=" col-lg-2 col-md-2 col-sm-3 col-6">
                                                                                    col-2
                                                                                </option>
                                                                                <option value=" col-lg-3 col-md-3 col-sm-4 col-6">
                                                                                    col-3
                                                                                </option>
                                                                                <option value=" col-lg-4 col-md-4 col-sm-6 col-12">
                                                                                    col-4
                                                                                </option>
                                                                                <option value="col-lg-5 col-md-5  col-sm-6 col-12">
                                                                                    col-5
                                                                                </option>
                                                                                <option value="col-lg-6 col-md-6 col-sm-6 col-12">
                                                                                    col-6
                                                                                </option>
                                                                                <option value="col-lg-7 col-md-7 col-sm-12 col-12">
                                                                                    col-7
                                                                                </option>
                                                                                <option value="col-lg-8 col-md-8 col-sm-12 col-12">
                                                                                    col-8
                                                                                </option>
                                                                                <option value="col-lg-9 col-md-9 col-sm-12 col-12">
                                                                                    col-9
                                                                                </option>
                                                                                <option value="col-lg-10 col-md-10 col-sm-12 col-12">
                                                                                    col-10
                                                                                </option>
                                                                                <option value="col-lg-11 col-md-11 col-sm-12 col-12">
                                                                                    col-11
                                                                                </option>
                                                                                <option value="col-12">col-12</option>
                                                                            </select>
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>


                                                    </div>
                                                )}

                                                <div className="mt-5">
                                                    <button
                                                        type="submit"
                                                        className="btn btn-success mt-3"
                                                        id="success"
                                                        onClick={(e) => handleCrudEditSubmit(e, crud_id)}
                                                        disabled={buttonDisabled}
                                                    >
                                                        <span className="indicator-label">Update</span>
                                                        <span className="indicator-progress">
                                                            Please wait...{" "}
                                                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                        </span>
                                                    </button>

                                                    {/* Reset Button */}
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary mt-3 ms-2"
                                                        onClick={() => window.location.reload()}
                                                    >
                                                        Reset
                                                    </button>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )

}

export default EditCrud
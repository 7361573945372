
import React from 'react'

const InlineBlock = () => {
  return (
    <div>

      <div className="container">
        <div className="row">
          <div className="col">
       

            <div className=" border border-primary inline-block-example1 ">
              Inline Block 1
            </div>


            <div className="border ml-2 border-success inline-block-example2" >
              Inline Block 2
            </div>
           


          </div>
        </div>
      </div>
    </div>
  )
}

export default InlineBlock
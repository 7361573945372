import React, { createContext, useEffect, useState } from "react";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";
import { fetchCrudTableData, fetchRegionData, handleDeleteGroup } from "../../customhook/FetchApi";
import useDeleteRegion from "../../customhook/DeleteApi";

import ReactPaginate from "react-paginate";
import { exportData } from "../../utitls/export/export";
import useStore from '../../zustand/ReactStore'; // Import the Zustand store
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";

const CrudBuilder = () => {

  const navigate = useNavigate()
  const [CrudData, setCrudData] = useState([]);
  //   const { deleteR, loading, error } = useDeleteRegion();
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 5; // Number of items per page
  const [selectedRole, setSelectedRole] = useState("");
  const [selectedFormat, setSelectedFormat] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  const SomeContext = createContext("")

  const crudDataFromZustandStore = useStore((state) => state.crudDataFromZustandStore)
  const updateCrudfromZustand = useStore((state) => state.updateCrudfromZustand)



  useEffect(() => {

    const getCrudTable = async () => {
      const crudtable = await fetchCrudTableData();

      updateCrudfromZustand(crudtable)

      setCrudData(crudtable);

      // setCrudData(crudtable);
    }

    getCrudTable()
  }, []);



  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };

  const filteredData = CrudData.filter(
    (item) =>
      item.table_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleFormSubmit = (event) => {
    event.preventDefault();


    if (!selectedFormat) {
      Swal.fire({
        title: "Error",
        text: "Please select both role and format",
        icon: "error",
        confirmButtonText: "OK",
      });
      return;
    }

    // Log filtered data for debugging


    // Call export function with filtered data
    exportData(filteredData, selectedRole, selectedFormat);

    Swal.fire({
      title: "Success",
      text: `Exporting data for role ${selectedRole} in ${selectedFormat} format.`,
      icon: "success",
      confirmButtonText: "OK",
    });
  };



  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(0); // Reset to first page on search
  };


  function showTableData(crudTable) {

    // dont show exact table_name in url for security purpose. remove "tbl_" from table name.
    const newTableName = (crudTable.table_name).replace("tbl_", "")

    navigate(`/crudtabledata/${(newTableName)}`)
  }

  const indexOfLastItem = (currentPage + 1) * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;


  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  function editCrud(crudTable) {
    const url = `/edit-crud/${crudTable.id}`;
    navigate(url)
  }

  return (
    <>
      <Header />
      <Sidebar />

      <div className="app-main flex-column flex-row-fluid " id="kt_app_main">
        {/*begin::Content wrapper*/}
        <div className="d-flex flex-column flex-column-fluid">
          {/*begin::Toolbar*/}
          <div id="kt_app_toolbar" className="app-toolbar py-3 py-lg-6">
            {/*begin::Toolbar container*/}
            <div
              id="kt_app_toolbar_container"
              className="app-container container-xxl d-flex flex-stack"
            >
              {/*begin::Page title*/}
              <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3 mt-5">
                {/*begin::Title*/}
                {/* <h1 className="page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0">
            List of Data
          </h1> */}
                {/*end::Title*/}
                {/*begin::Breadcrumb*/}
                <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                  {/*begin::Item*/}
                  <li className="breadcrumb-item text-muted">
                    <Link
                      to="/dashboardpage"
                      className="text-muted text-hover-primary"
                    >
                      Home
                    </Link>
                  </li>
                  {/*end::Item*/}
                  {/*begin::Item*/}

                  <li className="breadcrumb-item">
                    <span className="bullet bg-gray-500 w-5px h-2px" />
                  </li>
                  {/*end::Item*/}
                  {/*begin::Item*/}
                  <li className="breadcrumb-item text-muted">List of Crud Table</li>
                  {/*end::Item*/}
                </ul>
              </div>
            </div>
            {/*end::Toolbar container*/}
          </div>
          {/*end::Toolbar*/}
          {/*begin::Content*/}
          <div id="kt_app_content" className="app-content flex-column-fluid">
            {/*begin::Content container*/}
            <div
              id="kt_app_content_container"
              className="app-container container-xxl"
            >
              {/*begin::Card*/}
              <div className="card">
                {/*begin::Card header*/}
                <div className="card-header border-0 pt-6">
                  {/*begin::Card title*/}
                  <div className="card-title">
                    {/*begin::Search*/}


                    {/* <div className="d-flex align-items-center position-relative my-1">
                      <i className="ki-duotone ki-magnifier fs-3 position-absolute ms-5">
                        <span className="path1" />
                        <span className="path2" />
                      </i>
                      <input
                        type="text"
                        // data-kt-user-table-filter="search"
                        className="form-control form-control-solid w-250px ps-13"
                        placeholder="Search Crud Table"
                        value={searchTerm}
                        onChange={handleSearchChange}
                      />
                    </div> */}


                    <div className="d-flex align-items-center position-relative my-1">
                      <FontAwesomeIcon icon={faMagnifyingGlass} style={{ color: "#adbad2", }} className="ki-duotone ki-magnifier fs-3 position-absolute ms-5 " />

                      <input
                        type="text"
                        data-kt-user-table-filter="search"
                        className="form-control form-control-solid w-250px ps-13"
                        placeholder="Search here.."
                        value={searchTerm}
                        onChange={handleSearchChange}
                      />
                    </div>


                    {/*end::Search*/}
                  </div>
                  {/*begin::Card title*/}
                  {/*begin::Card toolbar*/}
                  <div className="card-toolbar">
                    {/*begin::Toolbar*/}
                    <div
                      className="d-flex justify-content-end"
                      data-kt-user-table-toolbar="base"
                    >
                      <Link
                        to="/crud-form"
                        type="button"
                        className="btn btn-primary"
                      >
                        <i className="ki-duotone ki-plus fs-2" />
                        Add New Crud
                      </Link>

                      <button
                        type="button"
                        className="btn btn-light-primary mx-5"
                        data-bs-toggle="modal"
                        data-bs-target="#kt_modal_export_users"
                      >
                        <i className="ki-duotone ki-exit-up fs-2">
                          <span className="path1" />
                          <span className="path2" />
                        </i>
                        Export
                      </button>

                      {/*end::Add user*/}
                    </div>

                    <div
                      className="d-flex justify-content-end align-items-center d-none"
                      data-kt-user-table-toolbar="selected"
                    >
                      <div className="fw-bold me-5">
                        <span
                          className="me-2"
                          data-kt-user-table-select="selected_count"
                        />
                        Selected
                      </div>
                      <button
                        type="button"
                        className="btn btn-danger"
                        data-kt-user-table-select="delete_selected"
                      >
                        Delete Selected
                      </button>
                    </div>



                    <div
                      className="modal fade"
                      id="kt_modal_export_users"
                      tabIndex={-1}
                      aria-hidden="true"
                    >
                      {/*begin::Modal dialog*/}
                      <div className="modal-dialog modal-dialog-centered mw-650px">
                        {/*begin::Modal content*/}
                        <div className="modal-content">
                          {/*begin::Modal header*/}
                          <div className="modal-header">
                            <h2 className="fw-bold">Export Users</h2>
                            <div className="btn btn-icon btn-sm btn-active-icon-primary" data-kt-users-modal-action="close" data-bs-dismiss="modal">



                              <i className="ki-duotone ki-cross fs-1">
                                <span className="path1" />
                                <span className="path2" />
                              </i>
                            </div>
                          </div>
                          {/*end::Modal header*/}
                          {/*begin::Modal body*/}
                          <div className="modal-body scroll-y mx-5 mx-xl-15 my-7">
                            {/*begin::Form*/}
                            <form
                              id="kt_modal_export_users_form"
                              className="form"
                              onSubmit={handleFormSubmit}
                            >




                              <div className="fv-row mb-10">
                                <label className="required fs-6 fw-semibold form-label mb-2">
                                  Select Export Format:
                                </label>
                                <select
                                  name="format"
                                  data-control="select2"
                                  data-placeholder="Select a format"
                                  data-hide-search="true"
                                  className="form-select form-select-solid fw-bold"
                                  value={selectedFormat}
                                  onChange={(e) =>
                                    setSelectedFormat(e.target.value)
                                  }
                                >
                                  <option value="">Select a format</option>
                                  <option value="excel">Excel</option>
                                  <option value="pdf">PDF</option>
                                  <option value="csv">CSV</option>
                                  <option value="zip">ZIP</option>
                                </select>
                              </div>
                              {/*end::Input group*/}

                              {/*begin::Actions*/}
                              <div className="text-center">
                                <button
                                  type="reset"
                                  className="btn btn-light me-3"
                                  data-kt-users-modal-action="cancel"
                                  data-bs-dismiss="modal"
                                >
                                  Discard
                                </button>
                                <button
                                  type="submit"
                                  className="btn btn-primary"
                                  data-kt-users-modal-action="submit"
                                >
                                  <span className="indicator-label">
                                    Submit
                                  </span>
                                  <span className="indicator-progress">
                                    Please wait...
                                    <span className="spinner-border spinner-border-sm align-middle ms-2" />
                                  </span>
                                </button>
                              </div>
                              {/*end::Actions*/}
                            </form>
                            {/*end::Form*/}
                          </div>
                          {/*end::Modal body*/}
                        </div>
                        {/*end::Modal content*/}
                      </div>
                      {/*end::Modal dialog*/}
                    </div>


                    <div
                      className="modal fade"
                      id="kt_modal_add_user"
                      tabIndex={-1}
                      aria-hidden="true"
                    ></div>


                    {/*end::Modal - Add task*/}
                  </div>
                  {/*end::Card toolbar*/}
                </div>
                {/*end::Card header*/}
                {/*begin::Card body*/}
                <div className="card-body py-4">
                  {/*begin::Table*/}
                  <table
                    className="table align-middle table-row-dashed fs-6 gy-5"
                    id="kt_table_users"
                  >


                    <thead>
                      <tr>
                        <th scope="col">Table Name</th>

                        <th scope="col" className="float-end me-5">Actions</th>

                      </tr>
                    </thead>

                    <tbody className="text-gray-600 fw-semibold">
                      {currentItems.map((crudTable, index) => (
                        <tr key={index}>

                          <td>{crudTable.title}</td>

                          <td className="float-end">
                            <div>

                              <button className="btn btn-icon btn-info mx-lg-3">
                                <i
                                  className="fas fa-eye"
                                  onClick={() => showTableData(crudTable)}
                                  title="See"
                                ></i>
                              </button>

                              <button
                                className="btn btn-icon btn-success"
                              >
                                <i className="fas fa-pencil-alt"
                                  onClick={() => editCrud(crudTable)}

                                ></i>
                              </button>

                              <button className="btn btn-icon btn-danger mx-lg-3 mt-3 mt-lg-0" onClick={() => handleDeleteGroup(crudTable.id, 'tbl_crud')}>
                                <i className="fas fa-trash"></i>
                              </button>

                            </div>
                          </td>


                        </tr>
                      ))}
                    </tbody>
                  </table>

                  {/* pagination code below */}
                  <div className="d-flex flex-stack flex-wrap pt-10">
                    <div className="fs-6 fw-semibold text-gray-700">
                      Showing {indexOfFirstItem + 1} to{" "}
                      {Math.min(indexOfLastItem, filteredData.length)} of{" "}
                      {filteredData.length} entries
                    </div>
                    <ReactPaginate
                      pageCount={Math.ceil(filteredData.length / itemsPerPage)}
                      pageRangeDisplayed={5}
                      marginPagesDisplayed={2}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item previous"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item next"}
                      nextLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CrudBuilder;

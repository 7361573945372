
import React, { useState } from 'react';

const ChainedSelect = () => {
  // Regions data
  const regions = [
    { region_id: 1, name: 'Asia' },
    { region_id: 2, name: 'Europe' },
    { region_id: 3, name: 'North America' },
  ];

  // Countries data
  const countries = [
    { region_id: 1, country_id: 1, name: 'India' },
    { region_id: 1, country_id: 2, name: 'China' },
    { region_id: 2, country_id: 3, name: 'Germany' },
    { region_id: 2, country_id: 4, name: 'France' },
    { region_id: 3, country_id: 5, name: 'USA' },
    { region_id: 3, country_id: 6, name: 'Canada' },
  ];

  // State for selected region and filtered countries
  const [selectedRegion, setSelectedRegion] = useState('');
  const [filteredCountries, setFilteredCountries] = useState([]);

  // Handle region selection
  const handleRegionChange = (e) => {
    const regionId = parseInt(e.target.value, 10);
    setSelectedRegion(regionId);


    // Filter countries based on selected region
    const filtered = countries.filter(country => country.region_id === regionId);
    
    
    setFilteredCountries(filtered);
  };

  return (
    <div>
      <h1>Select Region and Country</h1>

      {/* Region Select */}
      <label htmlFor="region">Select Region:</label>
      <select id="region" value={selectedRegion} onChange={handleRegionChange}>
        <option value="">-- Select Region --</option>
        {regions.map(region => (
          <option key={region.region_id} value={region.region_id}>
            {region.name}
          </option>
        ))}
      </select>

      {/* Country Select */}
      {filteredCountries.length > 0 && (
        <>
          <label htmlFor="country">Select Country:</label>

          <select id="country">
            <option value="">-- Select Country --</option>

            {filteredCountries.map(country => (
              <option key={country.country_id} value={country.country_id}>
                {country.name}
              </option>
            ))}
          </select>
        </>
      )}
    </div>
  );
};

export default ChainedSelect;

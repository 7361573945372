import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import { saveAs } from "file-saver";
import JSZip from "jszip";

// Export Excel
const exportExcel = (data, role) => {
  const ws = XLSX.utils.json_to_sheet(data);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const blob = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
  saveAs(blob, `data_export_${role}.xlsx`);
};

// Export PDF
const exportPDF = (data, role) => {
  const doc = new jsPDF();
  const text = JSON.stringify(data, null, 2); // Format JSON data as readable text
  doc.text(text, 10, 10);
  doc.save(`data_export_${role}.pdf`);
};

// Export CSV
const exportCSV = (data, role) => {
  const csvContent = data.map(item => Object.values(item).join(",")).join("\n");
  const csvBlob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  saveAs(csvBlob, `data_export_${role}.csv`);
};

// Export ZIP
const exportZIP = (data, role) => {
  const zip = new JSZip();
  zip.file("data.json", JSON.stringify(data));
  zip.generateAsync({ type: "blob" }).then(content => {
    saveAs(content, `data_export_${role}.zip`);
  });
};

// Main export function
export const exportData = (data, role, format) => {
  switch (format) {
    case "excel":
      exportExcel(data, role);
      break;
    case "pdf":
      exportPDF(data, role);
      break;
    case "csv":
      exportCSV(data, role);
      break;
    case "zip":
      exportZIP(data, role);
      break;
    default:
      console.error("Unsupported format:", format);
  }
};

import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Avatar from "../../src/img/profile.png";

const Header = () => {
  const navigate = useNavigate();

  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    
    setMenuOpen(!menuOpen);
  };
  const handleLogout = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You will be logged out.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, logout!",
      cancelButtonText: "No, stay logged in",
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.removeItem("token");
        navigate("/");
        Swal.fire(
          "Logged Out!",
          "You have been logged out successfully.",
          "success"
        );
      }
    });
  };

  return (
    <>
      <div id="kt_header" style={{}} className="header align-items-stretch">
        {/*begin::Container*/}
        <div className="container-fluid d-flex align-items-stretch justify-content-between">
          {/*begin::Aside mobile toggle*/}
          <div
            className="d-flex align-items-center d-lg-none ms-n4 me-1"
            title="Show aside menu"
          >
            <div
              className="btn btn-icon btn-active-color-white"
              id="kt_aside_mobile_toggle"
            >
              <i className="ki-outline ki-burger-menu fs-1" />
            </div>
          </div>
          {/*end::Aside mobile toggle*/}
          {/*begin::Mobile logo*/}
          <div className="d-flex align-items-center flex-grow-1 flex-lg-grow-0">
            <a href="index.html" className="d-lg-none">
              <img
                alt="Logo"
                src="assets/media/logos/demo13-small.svg"
                className="h-25px"
              />
            </a>
          </div>
          {/*end::Mobile logo*/}
          <div className="d-flex align-items-stretch justify-content-between flex-lg-grow-1">
            {/*begin::Navbar*/}
            <div className="d-flex align-items-stretch" id="kt_header_nav">
              {/*begin::Menu wrapper*/}
              <div
                className="header-menu align-items-stretch"
                data-kt-drawer="true"
                data-kt-drawer-name="header-menu"
                data-kt-drawer-activate="{default: true, lg: false}"
                data-kt-drawer-overlay="true"
                data-kt-drawer-width="{default:'200px', '300px': '250px'}"
                data-kt-drawer-direction="end"
                data-kt-drawer-toggle="#kt_header_menu_mobile_toggle"
                data-kt-swapper="true"
                data-kt-swapper-mode="prepend"
                data-kt-swapper-parent="{default: '#kt_body', lg: '#kt_header_nav'}"
              >
                <div
                  className="menu menu-rounded menu-column menu-lg-row menu-root-here-bg-desktop menu-active-bg menu-state-primary menu-title-gray-800 menu-arrow-gray-500 align-items-stretch my-5 my-lg-0 px-2 px-lg-0 fw-semibold fs-6"
                  id="#kt_header_menu"
                  data-kt-menu="true"
                >
                  {/*begin:Menu item*/}
                  <div
                    data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                    data-kt-menu-placement="bottom-start"
                    className="menu-item here show menu-here-bg menu-lg-down-accordion me-0 me-lg-2"
                  >
                    {/*begin:Menu link*/}
                    <span className="menu-link py-3">
                      <Link to="/dashboardpage" className="menu-title">
                        Dashboard
                      </Link>
                      <span className="menu-arrow d-lg-none" />
                    </span>
                  </div>

                  {/*end:Menu item*/}
                </div>
              </div>
            </div>

            <div className="topbar d-flex align-items-stretch flex-shrink-0">
              {/*begin::Search*/}
              <div className="d-flex align-items-stretch">
                {/*begin::Search*/}

                <div className="user-menu-container">
                  {/* Avatar trigger */}
                  <div
                    id="avatar_trigger"
                    className="topbar-item cursor-pointer symbol px-3 px-lg-5 me-n3 me-lg-n5 symbol-30px symbol-md-35px"
                    onClick={toggleMenu} // Call toggleMenu when avatar is clicked
                  >
                    <img src={Avatar} alt="user-avatar" />
                  </div>

                  {/* User account menu */}
                  {menuOpen && (
                    <div className="user-account-menu">
                      <div className="menu-item">
                        <div className="menu-content d-flex align-items-center">
                          {/* Avatar in the menu */}
                          <div className="menu-avatar">
                            <img alt="Avatar" src={Avatar} />
                          </div>

                          <div className="menu-user-info">
                            <div className="user-name">
                              Max Smith
                              <span className="user-status">Pro</span>
                            </div>
                            <a href="/" className="user-email">
                              max@kt.com
                            </a>
                          </div>
                        </div>
                      </div>

                      {/* Other menu items */}
                      <div className="menu-separator" />
                      <div className="menu-item">
                        <Link to="/profile" className="menu-link">
                          My Profile
                        </Link>
                      </div>
                      <div className="menu-item">
                        <a className="menu-link" onClick={handleLogout}>
                          Sign Out
                        </a>
                      </div>
                    </div>
                  )}
                </div>

                <div
                  className="d-flex align-items-stretch d-lg-none px-3 me-n3"
                  title="Show header menu"
                >
                  <div
                    className="topbar-item"
                    id="kt_header_menu_mobile_toggle"
                  >
                    <i className="ki-outline ki-burger-menu-2 fs-1" />
                  </div>
                </div>
                {/*end::Heaeder menu toggle*/}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;

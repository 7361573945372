import React, { useState, useEffect } from 'react';

const DoubleSlider = () => {


  const [sliderOneValue, setSliderOneValue] = useState(30);
  const [sliderTwoValue, setSliderTwoValue] = useState(70);
  const [minGap, setMinGap] = useState(0);
  const sliderMaxValue = 100;

  useEffect(() => {
    fillColor();
  }, [sliderOneValue, sliderTwoValue]);

  const slideOne = (e) => {
    if (parseInt(sliderTwoValue) - parseInt(e.target.value) <= minGap) {
      setSliderOneValue(sliderTwoValue - minGap);
    } else {
      setSliderOneValue(e.target.value);
    }
  };

  const slideTwo = (e) => {
    if (parseInt(e.target.value) - parseInt(sliderOneValue) <= minGap) {
      setSliderTwoValue(parseInt(sliderOneValue) + minGap);
    } else {
      setSliderTwoValue(e.target.value);
    }
  };

  const fillColor = () => {
    const percent1 = (sliderOneValue / sliderMaxValue) * 100;
    const percent2 = (sliderTwoValue / sliderMaxValue) * 100;
    const sliderTrack = document.querySelector('.slider-track');
    if (sliderTrack) {
      sliderTrack.style.background = `linear-gradient(to right, #dadae5 ${percent1}% , #3264fe ${percent1}% , #3264fe ${percent2}%, #dadae5 ${percent2}%)`;
    }
  };

  return (
    <div className="wrapper">
      <div className="input-values">


        <input
          type="number"
          id="range1"
          value={sliderOneValue}
          min="0"
          max="100"
          onChange={(e) => setSliderOneValue(parseInt(e.target.value))}
        />
        <span> &dash; </span>
        <input
          type="number"
          id="range2"
          value={sliderTwoValue}
          min="0"
          max="100"
      
          onChange={(e) => setSliderTwoValue(parseInt(e.target.value))}
        />
      </div>



      <div className="container">
        <div className="slider-track"></div>
        <input
          type="range"
          min="0"
          max="100"
          value={sliderOneValue}
          id="slider-1"
          onInput={slideOne}
             className='rangeDoublePointer'
        />

        <input
          type="range"
          min="0"
          max="100"
          value={sliderTwoValue}
          id="slider-2"
          onInput={slideTwo}
              className='rangeDoublePointer'
        />
      </div>
    </div>
  );
};

export default DoubleSlider;


// export default DoubleSlider;


import { faBuildingColumns } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import CountUp from 'react-countup';


const DashboardCard = (props) => {

    const { imagePath, Title, counterCount } = props
    return (
        <>

            <div className="col-md-4 admin-card">

                <div className="card  rounded-4 mx-5 mt-5" data-aos="zoom-in">

                    <div className="card-body">
                        <div className="row">

                            <div className="col-3">
                                {/* <p className="card-icon"><FontAwesomeIcon icon={faBuildingColumns} /></p> */}

                                <img src={`./img/${imagePath}`} alt="" className="card-image image-fluid" />


                            </div>

                            {/* <FontAwesomeIcon icon={faBuildingColumns} /> */}
                            <div className="col-9 card-text mt-3 ">

                                <div className="div  card-content float-end ">
                                    <h3 className="text-lg ">
                                        Total {Title}
                                    </h3>

                                    <h1 class="">
                                        <CountUp className="fs-1" end={counterCount} />
                                    </h1>

                                </div>
                            </div>

                        </div>

                        <div className="card-bottom-text">


                            {/* <img src="img\trend.png" alt="" className="image-fluid" /> */}

                            {/* <span className="ms-3">10% higher than last week</span> */}
                        </div>
                    </div>


                </div>
            </div>


        </>
    )
}

export default DashboardCard

import React, { useEffect } from "react"
import qs from "qs"

const FetchMultipleApi = () => {

    async function fetchData() {

        try {

            const [response1, response2] = await Promise.all([

                fetch('https://admissioninnepal.in/Apis/Crud_field/crudnfield', {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                    },

                    body: qs.stringify({ key: 12321,id:4 })
                }),

                fetch('https://admissioninnepal.in/api/region-list', {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                    },

                    body: qs.stringify({ key: 12321 })
                })


            ])

            const data1 = await response1.json()

            const data2 = await response2.json()

            

            

        } catch (err) {
            
        }
    }


    useEffect(() => {
        fetchData()
    }, [])

    return (
        <>
            <h1>Shubham</h1>

        </>

    )

}

export default FetchMultipleApi


// correct abvoe code to call multiple api when page load


import React from 'react'

const BeforeClass = () => {
    return (
        <div className="container">
            <div className="row">

                <div className="col ">


                    <div className="input-container py-5">
                        <input
                            className="input-with-icon form-control"
                            type="file"
                        />
                    </div>

                </div>


            </div>
        </div>
    )
}

export default BeforeClass
import "./App.css";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import { BrowserRouter as Router, Route, Routes, Form } from "react-router-dom";
// import { HashRouter as Router, Route, Routes } from 'react-router-dom';

import Region from "./pages/Location/Region";
import RegionData from "./pages/Location/RegionData";

import SpecializationData from "./pages/Course/Specialization";
// import Test2 from "./pages/Test2";
import ProtectedRoute from "./ProtectedRoute/ProtectedRoute";
import CrudGroup from "./pages/Crud/CrudGroup";
import EditGroup from "./pages/Crud/EditGroup";
import CrudFiled from "./pages/Crud/CrudFiled";
import Crud from "./pages/Crud/Crud";

import CrudForm from "./pages/Crud/Crud";
import YourComponent from "./pages/Test";
import SelectTableGetFeild from "./pages/Test2";
import FormBuilder from "./pages/Form/FormBuilder";
import CrudBuilder from "./pages/Crud/CrudBuilder";
import DisplayCrudTableData from "./pages/Crud/DisplayCrudTableData";
import DynamicCheckbox from "./pages/Crud/DynamicCheckbox";
import ChainedSelect from "./pages/Crud/ChainedSelect";

// import './main.css'

import './assets/css/style.bundle.css'

import Test2 from "./pages/Test2";
import GlobalForm from "./pages/Crud/GlobalForm";
import EditCrud from "./pages/Crud/EditCrud";
import EditForm from "./utitls/form/EditForm";
import ManipalForm from "./pages/Crud/ManipalForm";
import ErrorPage from "./ErrorPage";
import FetchMultipleApi from "./pages/Crud/FetchMultipleApi";
import MyBarChart from "./components/MyBarChart";
import InlineBlock from "./test/InlineBlock";
import BeforeClass from "./test/BeforeClass";
import BootstrapMpdel from "./test/BootstrapMpdel";


import ContextApiProvider from "./test/ContextApiProvider";
import Child from "./test/Child";
import Child2 from "./test/Child2";

import ContextProviderCrudTableData from "./pages/ContextProviderCrudTableData";
import { fetchCrudTableData, fetchSidebarData } from "./customhook/FetchApi";

import React, { createContext, useState, useEffect } from 'react';

// import CrudCheckboxRadio from "./pages/Crud/CrudCheckboxRadio";

import useStore from './zustand/ReactStore'; // Import the Zustand store
import DoubleSlider from "./test/DoubleSlider";
import RangeDouble from "./pages/Crud/RangeDouble";
import Profile from "./components/Profile";

export const CrudTableData = createContext();



function App() {


  const fetchSidebarDataZustandStore = useStore((state) => state.fetchSidebarDataZustandStore); // Fetch method from store
  // const sidebarDataFromZustandStore = useStore((state) => state.sidebarDataFromZustandStore); // Access sidebar data from store
  const updateSidebarDatafromZustand = useStore((state) => state.updateSidebarDatafromZustand)
  const updateCrudfromZustand = useStore((state) => state.updateCrudfromZustand)

  const sidebarDataFromZustandStore = useStore((state) => state.sidebarDataFromZustandStore)


  useEffect(() => {
    console.log("hii");
    const fetchData = async () => {
      try {
        const sidebardata = await fetchSidebarData();
        await updateSidebarDatafromZustand(sidebardata)

      } catch (error) {
        console.error("Error fetching sidebar data:", error); // Handle any errors
      }
    };

    fetchData(); // Call the async function
  }, [fetchSidebarDataZustandStore, updateSidebarDatafromZustand]); // Include dependencies if needed







  //fetch crud table and store in zustand store
  useEffect(() => {
    const getCrudTable = async () => {
      const crudtable = await fetchCrudTableData();

      updateCrudfromZustand(crudtable)
      // setCrudData(crudtable);
    }

    getCrudTable()
  }, []);








  return (
    <Router>
      {/* <CrudTableData.Provider value={{ crudData }}> */}

      {/* <ContextProviderCrudTableData > */}

      <Routes>

        <Route path="/" element={<Login />} />
        <Route path="/dashboardpage" element={<ProtectedRoute element={Dashboard} />} />

        <Route path="/test" element={<ProtectedRoute element={YourComponent} />} />
        <Route path="/test2" element={<Test2 />} />
        <Route path="/crud-group" element={<ProtectedRoute element={CrudGroup} />} />
        <Route path="/crud-field" element={<ProtectedRoute element={CrudFiled} />} />
        <Route path="/crud-form" element={<ProtectedRoute element={CrudForm} />} />


        <Route path="/edit-group/:id" element={<ProtectedRoute element={EditGroup} />} />


        <Route path="/form/:crud_id/:table_name/:table_data_id" element={<ProtectedRoute element={FormBuilder} />} />


        <Route path="/crud-builder" element={<ProtectedRoute element={CrudBuilder} />} />
        <Route path="/crudtabledata/:table_name" element={<ProtectedRoute element={DisplayCrudTableData} />} />

        <Route path="/dynamic-checkbox" element={<ProtectedRoute element={DynamicCheckbox} />} />

        <Route path="/chained-select" element={<ProtectedRoute element={ChainedSelect} />} />

        <Route path="/edit-group/:id" element={<ProtectedRoute element={EditGroup} />} />

        <Route path="/edit-crud/:id" element={<ProtectedRoute element={EditCrud} />} />

        <Route path="/global-form/:slug" element={<GlobalForm />} />

        <Route path="/manipal" element={<ManipalForm />} />

        <Route path="/error_page" element={<ErrorPage />} />

        <Route path="/multiple-api" element={<FetchMultipleApi />} />


        <Route path="/edit-form/:id"
          element={<ProtectedRoute element={<EditForm />} />}
        />


        <Route path="/barchart"
          element={<MyBarChart />}
        />



        <Route path="/inline-block"
          element={<InlineBlock />}
        />

        <Route path="/before"
          element={<BeforeClass />}
        />

        <Route path="/model"
          element={
            <BootstrapMpdel />}
        />

        <Route path="/double"
          element={
            <DoubleSlider />}
        />


        <Route path="/double1"
          element={
            <RangeDouble />}
        />


        <Route path="/profile" element={<ProtectedRoute element={Profile} />} />


        {/* 
        <Route path="/child" element={
          <ContextApiProvider>
            <Child />
          </ContextApiProvider>
        }
        />


        <Route path="/child2" element={

          <ContextApiProvider>
            <Child2 />

          </ContextApiProvider>
        } /> */}


      </Routes>

      {/* </ContextProviderCrudTableData > */}

      {/* </CrudTableData.Provider> */}


    </Router>
  );
}

export default App;





